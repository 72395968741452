import React from "react";
import i18n from 'i18next';

class SwitchAndCheckboxes extends React.Component {
    render(){
        return (
            <div className="grid grid-cols-2 lg:grid-cols-3">
              <div className="form-element inlined switch">
                <label>{i18n.t('large')}</label>
                <div className="flex">
                  <label htmlFor="toggleLG">
                    <div className="toggle-container lg">
                      <input defaultChecked type="checkbox" id="toggleLG" className="sr-only" />
                      <div className="switch-bg" />
                      <div className="dot" />
                    </div>
                  </label>
                </div>
              </div>
              <div className="form-element inlined switch mt-4">
                <label>{i18n.t('medium')}</label>
                <div className="flex">
                  <label htmlFor="toggleMD">
                    <div className="toggle-container md">
                      <input defaultChecked type="checkbox" id="toggleMD" className="sr-only" />
                      <div className="switch-bg" />
                      <div className="dot" />
                    </div>
                  </label>
                </div>
              </div>
              <div className="form-element inlined switch mt-4">
                <label>{i18n.t('small')}</label>
                <div className="flex">
                  <label htmlFor="toggleSM">
                    <div className="toggle-container sm">
                      <input defaultChecked type="checkbox" id="toggleSM" className="sr-only" />
                      <div className="switch-bg" />
                      <div className="dot" />
                    </div>
                  </label>
                </div>
              </div>
              <div className="form-element inlined switch disabled mt-4">
                <label>{i18n.t('disabled')}</label>
                <div className="flex">
                  <label htmlFor="toggleDisabled">
                    <div className="toggle-container md">
                      <input type="checkbox" disabled id="toggleDisabled" className="sr-only" />
                      <div className="switch-bg" />
                      <div className="dot" />
                    </div>
                  </label>
                </div>
              </div>
              <div className="form-element inlined switch disabled mt-4">
                <label>{i18n.t('disabled')}</label>
                <div className="flex">
                  <label htmlFor="toggleDisabled">
                    <div className="toggle-container md">
                      <input defaultChecked type="checkbox" disabled id="toggleDisabled" className="sr-only" />
                      <div className="switch-bg" />
                      <div className="dot" />
                    </div>
                  </label>
                </div>
              </div>
              <div className="form-element inlined switch mt-4">
                <label>{i18n.t('red')}</label>
                <div className="flex">
                  <label htmlFor="toggle-1">
                    <div className="toggle-container md">
                      <input defaultChecked type="checkbox" id="toggle-1" className="sr-only" />
                      <div className="switch-bg" />
                      <div className="dot red" />
                    </div>
                  </label>
                </div>
              </div>
              <div className="form-element inlined switch mt-4">
                <label>{i18n.t('purple')}</label>
                <div className="flex">
                  <label htmlFor="toggle-2">
                    <div className="toggle-container md">
                      <input defaultChecked type="checkbox" id="toggle-2" className="sr-only" />
                      <div className="switch-bg" />
                      <div className="dot purple" />
                    </div>
                  </label>
                </div>
              </div>
              <div className="form-element inlined switch mt-4">
                <label>{i18n.t('gray')}</label>
                <div className="flex">
                  <label htmlFor="toggle-3">
                    <div className="toggle-container md">
                      <input defaultChecked type="checkbox" id="toggle-3" className="sr-only" />
                      <div className="switch-bg" />
                      <div className="dot gray" />
                    </div>
                  </label>
                </div>
              </div>
              <div className="form-element inlined switch mt-4">
                <label>{i18n.t('orange')}</label>
                <div className="flex">
                  <label htmlFor="toggle-4">
                    <div className="toggle-container md">
                      <input defaultChecked type="checkbox" id="toggle-4" className="sr-only" />
                      <div className="switch-bg" />
                      <div className="dot orange" />
                    </div>
                  </label>
                </div>
              </div>
              <div className="form-element inlined switch mt-4">
                <label>{i18n.t('pink')}</label>
                <div className="flex">
                  <label htmlFor="toggle-5">
                    <div className="toggle-container md">
                      <input defaultChecked type="checkbox" id="toggle-5" className="sr-only" />
                      <div className="switch-bg" />
                      <div className="dot pink" />
                    </div>
                  </label>
                </div>
              </div>
            </div>
        )
    }
}

export default SwitchAndCheckboxes;