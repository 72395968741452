import React from "react";
import { Helmet } from "react-helmet";
import RegisterCard from "../components/auth/RegisterCard";
import AuthLayout from "../layouts/Auth";
import i18n from 'i18next';

class RegisterPage extends React.Component {
    render (){
        return (
            <AuthLayout>
                <Helmet>
                    <title>{i18n.t('register')}</title>
                </Helmet>
                <RegisterCard />
            </AuthLayout>
        )
    }
}

export default RegisterPage;