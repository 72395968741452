import React from "react";
import LanguageAlert from "../components/alerts/LanguageAlert";
import AdminImg from "../components/cards/AdminImg";
import DashboardCards from "../components/cards/DashboardCards";
import TasksList from "../components/applications/TasksList";
import TableRowLikeNotification from "../components/notifications/TableRowLikeNotification";
import i18n from 'i18next';
import RadialBarChart from "../components/charts/RadialBarChart";
import MixedTwoChart from '../components/charts/MixedTwoChart';
import renderCharts from "../../assets/js/Apexcharts.init";
import MainLayout from "../layouts/Main";
import { Helmet } from "react-helmet";

class IndexPage extends React.Component {
  componentDidMount(){ renderCharts() }
  render(){
      return (
        <MainLayout>
          <Helmet>
            <title>{i18n.t('dashboard')}</title>
          </Helmet>

          <div>
            {/* preview in english / arabic alert */}
            <LanguageAlert />

            <div className="grid-of-4">
              <div className="lg:col-span-1">
                <AdminImg />
              </div>
              {/* dashbpoard cards ////////// */}
              <DashboardCards />

              {/* radial bar chart /////////////// */}
              <RadialBarChart />

              {/* stacked bar chart //////////// */}
              <MixedTwoChart />

              {/* recent activty ///////// */}
              <div className="lvl-1-container p-4 lg:col-span-2">
                <div className="my-4 flex">
                  <h6 className="font-lg">{i18n.t('latest activities')}</h6>
                </div>
                {/* activities list ///////// */}
                <div className="table-container notifications">
                  {/* activities list ///////// */}
                  <table>
                    <tbody>
                      <TableRowLikeNotification />
                      <TableRowLikeNotification />
                      <TableRowLikeNotification />
                      <TableRowLikeNotification />
                      <TableRowLikeNotification />
                      <TableRowLikeNotification />
                    </tbody>
                  </table>
                </div>
                {/* end of activities list ///////////// */}
              </div>    
              {/* to do list /////////////// */}
              <div className="lg:col-span-2">
                <TasksList />
              </div>
              <div className="lg:col-span-2 lvl-1-container">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5372.943346614773!2d2.9196290689994338!3d36.800035128704!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x128fbbc47dfda6bf%3A0x9730ae51544ee5b5!2sLaboratoire%20d'Analyses%20M%C3%A9dicales%20Dr%20N.%20Feddag!5e0!3m2!1sen!2sdz!4v1675000087808!5m2!1sen!2sdz" width="100%" height="100%" style={{border: 0}} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
              </div>
            </div>
          </div>
        </MainLayout>
      )
  }
}

export default IndexPage;