import React from "react";
import i18n from 'i18next';
import StandardModal from "../components/modals/StandardModal";
import SmallModal from "../components/modals/SmallModal";
import MediumBlurModal from "../components/modals/MediumBlurModal";
import LargeModal from "../components/modals/LargeModal";
import LargeBlurModal from "../components/modals/LargeBlurModal";
import MainLayout from "../layouts/Main";
import { Helmet } from "react-helmet";

class ModalsPage extends React.Component {
    render(){
        return (
          <div>
            <Helmet>
              <title>{i18n.t('modals')}</title>
            </Helmet>
            <MainLayout>
             <div className="grid-of-1 my-5">
                 <div className="lvl-1-container p-4">
                   <div className="my-4 flex">
                     <h6 className="font-lg">{i18n.t('standard')}</h6>
                   </div>
                   <hr />
                   <div className="flex flex-wrap gap-1">
                     <button name="button" onClick={() => window.Lawha.toggleItem('#standard-modal')} className="button-3d red">{i18n.t('standard')}</button>
                   </div>
                 </div>
                 <div className="lvl-1-container p-4">
                   <div className="my-4 flex">
                     <h6 className="font-lg">{i18n.t('blur')}</h6>
                   </div>
                   <hr />
                   <div className="flex flex-wrap gap-1">
                     <button name="button" onClick={() => window.Lawha.toggleItem('#standard-modal')} className="button-3d green">{i18n.t('small')}</button>
                     <button name="button" onClick={() => window.Lawha.toggleItem('#blur-md-modal')} className="button-3d gray">{i18n.t('medium')}</button>
                     <button name="button" onClick={() => window.Lawha.toggleItem('#blur-lg-modal')} className="button-3d orange">{i18n.t('large')}</button>
                   </div>
                 </div>
                 <div className="lvl-1-container p-4">
                   <div className="my-4 flex">
                     <h6 className="font-lg">{i18n.t('sizes')}</h6>
                   </div>
                   <hr />
                   <div className="flex flex-wrap gap-1">
                     <button name="button" onClick={() => window.Lawha.toggleItem('#sm-modal')} className="button-3d green">{i18n.t('small')}</button>
                     <button name="button" onClick={() => window.Lawha.toggleItem('#standard-modal')} className="button-3d red">{i18n.t('medium')}</button>
                     <button name="button" onClick={() => window.Lawha.toggleItem('#lg-modal')} className="button-3d purple">{i18n.t('large')}</button>
                   </div>
                 </div>
              </div>
            </MainLayout>     
           
           {/*modals must be outside .page-content so they can be propperly rendered*/}
           <div>
               {/* standard //////////// */}
               <StandardModal />
    
               {/* small //////////// */}
               <SmallModal />
    
               {/* large //////////// */}
               <LargeModal />
    
               {/* blur md //////////// */}
               <MediumBlurModal />
    
               {/* blur lg //////////// */}
               <LargeBlurModal />
           </div>
          </div>
        )
    }
}

export default ModalsPage;