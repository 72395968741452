import React from "react";
import i18n from 'i18next';

class SimpleInputs extends React.Component {
  render(){
    return (
      <div>
        <div className="form-element simple">
          <label>{i18n.t('email address')}</label>
          <input type="text" data-attr-t placeholder={i18n.t('email address')} />
        </div>
        <div className="form-element simple">
          <label>{i18n.t('date')}</label>
          <input type="date" data-attr-t placeholder={i18n.t('date')} />
        </div>
        <div className="form-element simple">
          <label>{i18n.t('time')}</label>
          <input type="time" data-attr-t placeholder={i18n.t('time')} />
        </div>
        <div className="form-element simple">
          <label>{i18n.t('password')}</label>
          <input type="password" data-attr-t placeholder={i18n.t('password')} />
        </div>
        <div className="form-element simple">
          <label>{i18n.t('options')}</label>
          <select>
            <option>{i18n.t('option')}</option>
            <option>{i18n.t('option')}</option>
            <option>{i18n.t('option')}</option>
          </select>
        </div>
      </div>
    )
  }
}

export default SimpleInputs;