/**
 * @see https://github.com/uiwjs/react-md-editor
*/
import React from "react";
import MDEditor from '@uiw/react-md-editor';

export default function MarkdownEditor() {
  const [value, setValue] = React.useState("**Hello lawha!!!**");
  return (
    <div className="container" dir="ltr">
      <MDEditor
        value={value}
        onChange={setValue}
      />
      <MDEditor.Markdown source={value} style={{ whiteSpace: 'pre-wrap' }} />
    </div>
  );
}