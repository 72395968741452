import React from "react";
import i18n from 'i18next';

class HistoryBar extends React.Component {
    render(){
        return (
            <div className="history-bar">
                <a href="/" className="item"> 
                    <svg className="h-4 w-4"  width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <polyline points="5 12 3 12 12 3 21 12 19 12" />  <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7" />  <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6" /></svg>
                    <span>{i18n.t('dashboard')}</span>
                </a> /
                <a href="#" className="item">
                    <svg className="h-4 w-4"  width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <polyline points="12 8 12 12 14 14" />  <path d="M3.05 11a9 9 0 1 1 .5 4m-.5 5v-5h5" /></svg>
                    <span>{i18n.t('previous page')}</span>
                </a> / 
                <a href="#" className="active item"><span>{i18n.t('current page')}</span></a> 
            </div>
        )
    }
}

export default HistoryBar;