import React from "react";
import i18n from "i18next";

class Traditional extends React.Component {
    render(){
        return (
            <div className="table-container traditional">
                <table>
                   <tbody>
                    <tr>
                       <td><input type="checkbox" /></td>
                       <td>ID</td>
                       <td>{i18n.t('title')}</td>
                       <td>{i18n.t('thumbnail')}</td>
                       <td>{i18n.t('status')}</td>
                       <td>{i18n.t('price')}</td>
                    </tr>

                    {(() => {
                        let rows = []
                        for (let index = 0; index < 4; index++) {
                            rows.push(
                            <tr key={index}>
                                <td> <input type="checkbox" /> </td>
                                <td className="font-bold"> #1 </td>
                                <td className="font-bold">{i18n.t('product name example')}</td>
                                <td> <img className="w-20 rounded-lg lazy" data-src="https://via.placeholder.com/50" alt="product" /></td>
                                <td> <span className="badge red">{i18n.t('product status')}</span></td>
                                <td className="font-bold"> 1555$</td>
                            </tr>
                            )
                        }
                        return rows;
                    })()}
                    </tbody>
                </table>
            </div>
        )
    }
}

export default Traditional;