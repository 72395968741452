import React from "react";

class BasePagination extends React.Component {
    render(){
        return (
            <div className="pagination base default">
              <a href="#">‹</a>
              <a href="#">1</a>
              <a href="#">2</a>
              <a href="#">3</a>
              <a href="#">4</a>
              <a href="#" className="disabled">›</a>
            </div>
        )
    }
}

export default BasePagination;