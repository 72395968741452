import React from "react";

class StandardPreloadersGroup extends React.Component {
    render(){
        return (
            <div className="flex flex-wrap gap-7 my-2">
                <div className="preloader spinner sm default"></div>
                <div className="preloader spinner sm amber"></div>
                <div className="preloader spinner sm blue"></div>
                <div className="preloader spinner sm cyan"></div>
                <div className="preloader spinner sm emerald"></div>
                <div className="preloader spinner sm gray"></div>
                <div className="preloader spinner sm green"></div>
                <div className="preloader spinner sm lime"></div>
                <div className="preloader spinner sm orange"></div>
                <div className="preloader spinner sm pink"></div>
                <div className="preloader spinner sm purple"></div>
                <div className="preloader spinner sm red"></div>
                <div className="preloader spinner sm sky"></div>
                <div className="preloader spinner sm teal"></div>
                <div className="preloader spinner sm yellow"></div>
            </div>
        )
    }
}

export default StandardPreloadersGroup;