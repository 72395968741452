import React from "react";
import i18n from 'i18next';

class ButtonsPreloaders extends React.Component {
    render(){
        return (
            <div className="flex flex-wrap gap-2">
                <button name="button" class="outlined-button red iconed">
                    <div class="preloader spinner xxs red"></div>
                    <span>{i18n.t('click me')}</span>
                </button>
                <button name="button" class="outlined-button green iconed">
                    <div class="preloader spinner xxs green"></div>
                    <span>{i18n.t('click me')}</span>
                </button>
                <button name="button" class="outlined-button gray iconed">
                    <div class="preloader spinner xxs gray"></div>
                    <span>{i18n.t('click me')}</span>
                </button>
                <button name="button" class="outlined-button purple iconed">
                    <div class="preloader spinner xxs purple"></div>
                    <span>{i18n.t('click me')}</span>
                </button>
                <button name="button" class="outlined-button orange iconed">
                    <div class="preloader spinner xxs orange"></div>
                    <span>{i18n.t('click me')}</span>
                </button>
                
                <button name="button" class="button-3d green iconed">
                    <div class="preloader spinner xxs gray"></div>
                    <span>{i18n.t('click me')}</span>
                </button>
                
                <button name="button" class="button-3d orange rounded-full iconed">
                    <div class="preloader spinner xxs gray"></div>
                    <span>{i18n.t('click me')}</span>
                </button>
            </div>
        )
    }
}

export default ButtonsPreloaders;