import React from "react";

class PreloaderSizes extends React.Component {
    render(){
        return (
            <div className="flex flex-wrap gap-4">
                <div className="preloader spinner xxs gray"></div>
                <div className="preloader spinner xs orange"></div>
                <div className="preloader spinner sm purple"></div>
                <div className="preloader spinner md green"></div>
                <div className="preloader spinner lg gray"></div>
                <div className="preloader spinner xs gray dotted"></div>
                <div className="preloader spinner lg red double"></div>
                <div className="preloader spinner md dashed purple"></div>
            </div>
        )
    }
}

export default PreloaderSizes;