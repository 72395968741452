import React from "react";
import i18n from 'i18next';
import StandardPreloadersGroup from "../components/preloaders/StandardPreloadersGroup";
import DashedPreloadersGroup from "../components/preloaders/DashedPreloadersGroup";
import DotterPreloadersGroup from "../components/preloaders/DottedPreloadersGroup";
import DoublePreloadersGroup from "../components/preloaders/DoublePreloadersGroup";
import PreloaderSizes from "../components/preloaders/PreloaderSizes";
import ButtonsPreloaders from "../components/preloaders/ButtonsPreloaders";
import MainLayout from "../layouts/Main";
import { Helmet } from "react-helmet";

class PreloaderPage extends React.Component {
    render(){
        return (
            <MainLayout>
              <Helmet>
                <title>{i18n.t('preloaders')}</title>
              </Helmet>
              <div>
              <div className="grid-of-1 py-4 mt-4">
                <div className="lvl-1-container p-4">
                  <div className="my-4 flex flex-1">
                    <h6 className="font-lg">{i18n.t('colors and types')}</h6>
                  </div>
                  {/* standard group //////////// */}
                  <StandardPreloadersGroup />

                  {/* dashed ////////////////////// */}
                  <DashedPreloadersGroup />
                  
                  {/* dotted /////////////////////// */}
                  <DotterPreloadersGroup />

                  {/* double ////////////////////// */}
                  <DoublePreloadersGroup />
                </div>
              </div>
              <div className="grid-of-2 py-2">
                <div className="lvl-1-container p-4">
                  <div className="my-4 flex">
                    <h6 className="font-lg">{i18n.t('sizes')}</h6>
                  </div>
                  {/* sizes */}
                  <PreloaderSizes />
                </div>
                <div className="lvl-1-container p-4">
                  <div className="my-4 flex">
                    <h6 className="font-lg">{i18n.t('buttons')}</h6>
                  </div>
                  {/* buttons */}
                  <ButtonsPreloaders />
                </div>
              </div>
            </div>
            </MainLayout>
        )
    }
}

export default PreloaderPage;