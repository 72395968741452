import React from "react";
import i18n from "i18next";

class HorizontalCard extends React.Component {
  render(){
    return (
      <div className="horizontal-card">
        <div className="card-img" style={{backgroundImage: 'url("https://via.placeholder.com/192")'}} title="Woman holding a mug">
        </div>
        <div className="card-content">
          <div className="card-head">
            <p className="text-sm flex items-center">
              <svg className="fill-current text-gray-500 w-3 h-3 mx-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M4 8V6a6 6 0 1 1 12 0v2h1a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-8c0-1.1.9-2 2-2h1zm5 6.73V17h2v-2.27a2 2 0 1 0-2 0zM7 6v2h6V6a3 3 0 0 0-6 0z" />
              </svg>
              <span>{i18n.t('card sub title example')}</span>
            </p>
            <div className="font-bold text-xl mb-2">{i18n.t('card title example')}</div>
            <p className="text-base">{i18n.t('card content example')}</p>
          </div>
          <div className="flex items-center">
            <img className="w-10 h-10 rounded-full mx-4 lazy" data-src="https://via.placeholder.com/300x120" alt="card title" />
            <div className="text-sm">
              <p className="leading-none">{i18n.t('card author example')}</p>
              <p>{i18n.t('date example')}</p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default HorizontalCard;