import React from "react";

class GridOf4 extends React.Component {
    render(){
        return (
            <div className="grid-of-4 my-4">
                <div className="grid-cell"> <strong className="text-lg">1</strong> </div>
                <div className="grid-cell"> <strong className="text-lg">2</strong> </div>
                <div className="grid-cell"> <strong className="text-lg">3</strong> </div>
                <div className="grid-cell"> <strong className="text-lg">4</strong> </div>
            </div>            
        )
    }
}

export default GridOf4;