import React from "react";

class GridOf1 extends React.Component {
    render(){
        return (
            <div className="grid-of-1 my-4">
                <div className="grid-cell"> <strong className="text-lg">1</strong> </div>
            </div>
        )
    }
}

export default GridOf1;