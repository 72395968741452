import React from "react";
import i18n from 'i18next';

class CalendarApp extends React.Component {
    render (){
        return (
            <div>
              <div className="calendar">
                {/* calendar header ///////// */}
                <div className="calendar-header">
                  {/* calendar title /////////// */}
                  <span className="calendar-title">{i18n.t('full date example')}</span>
                  {/* navigation buttons /////// */}
                  <div className="buttons" dir="ltr">
                    <button className="p-1">
                      <svg fill="gray" className="svg-sm" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path fillRule="evenodd" d="M8.354 11.354a.5.5 0 0 0 0-.708L5.707 8l2.647-2.646a.5.5 0 1 0-.708-.708l-3 3a.5.5 0 0 0 0 .708l3 3a.5.5 0 0 0 .708 0z" />
                        <path fillRule="evenodd" d="M11.5 8a.5.5 0 0 0-.5-.5H6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 .5-.5z" />
                      </svg>
                    </button>
                    <button className="p-1">
                      <svg fill="gray" className="svg-sm" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path fillRule="evenodd" d="M7.646 11.354a.5.5 0 0 1 0-.708L10.293 8 7.646 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0z" />
                        <path fillRule="evenodd" d="M4.5 8a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5z" />
                      </svg>
                    </button>
                  </div>
                </div>
                {/* calendar body /////////// */}
                <table className="calendar-table">
                  {/* calendar head ////// */}
                  <thead className="calendar-table-head">
                    <tr>
                      <th>
                        <span className="hidden lg:block">{i18n.t('Sunday')}</span>
                        <span className="block lg:hidden">{i18n.t('Sun')}</span>
                      </th>
                      <th>
                        <span className="hidden lg:block">{i18n.t('Monday')}</span>
                        <span className="block lg:hidden">{i18n.t('Mon')}</span>
                      </th>
                      <th>
                        <span className="hidden lg:block">{i18n.t('Tuesday')}</span>
                        <span className="block lg:hidden">{i18n.t('Tue')}</span>
                      </th>
                      <th>
                        <span className="hidden lg:block">{i18n.t('Wednesday')}</span>
                        <span className="block lg:hidden">{i18n.t('Wed')}</span>
                      </th>
                      <th>
                        <span className="hidden lg:block">{i18n.t('Thursday')}</span>
                        <span className="block lg:hidden">{i18n.t('Thu')}</span>
                      </th>
                      <th>
                        <span className="hidden lg:block">{i18n.t('Friday')}</span>
                        <span className="block lg:hidden">{i18n.t('Fri')}</span>
                      </th>
                      <th>
                        <span className="hidden lg:block">{i18n.t('Saturday')}</span>
                        <span className="block lg:hidden">{i18n.t('Sat')}</span>
                      </th>
                    </tr>
                  </thead>
                  {/* end of calendar head //////////// */}
                  {/* calendar body ///////////// */}
                  <tbody className="calendar-table-body">
                    <tr>
                      <td>
                        <div className="calendar-cell-container">
                          <div className="calendar-num">
                            <span>1</span>
                          </div>
                          <div className="events-container">
                            <div className="event base-purple-bg">
                              <span className="event-name">{i18n.t('event name example')}</span>
                              <span className="time">12:00~14:00</span>
                            </div>
                            <div className="event base-sky-bg">
                              <span className="event-name">{i18n.t('event name example')}</span>
                              <span className="time">18:00~20:00</span>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="calendar-cell-container">
                          <div className="calendar-num">
                            <span>2</span>
                          </div>
                          <div className="events-container" />
                        </div>
                      </td>
                      <td>
                        <div className="calendar-cell-container">
                          <div className="calendar-num">
                            <span>3</span>
                          </div>
                          <div className="events-container" />
                        </div>
                      </td>
                      <td>
                        <div className="calendar-cell-container">
                          <div className="calendar-num">
                            <span>4</span>
                          </div>
                          <div className="events-container" />
                        </div>
                      </td>
                      <td>
                        <div className="calendar-cell-container">
                          <div className="calendar-num">
                            <span>6</span>
                          </div>
                          <div className="events-container" />
                        </div>
                      </td>
                      <td>
                        <div className="calendar-cell-container">
                          <div className="calendar-num">
                            <span>7</span>
                          </div>
                          <div className="events-container">
                            <div className="event base-lime-bg">
                              <span className="event-name">{i18n.t('event name example')}</span>
                              <span className="time">12:00~14:00</span>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="calendar-cell-container">
                          <div className="calendar-num">
                            <span>8</span>
                          </div>
                          <div className="events-container" />
                        </div>
                      </td>
                    </tr>
                    {/*         line 1 */}
                    <tr>
                      <td>
                        <div className="calendar-cell-container">
                          <div className="calendar-num">
                            <span>9</span>
                          </div>
                          <div className="events-container" />
                        </div>
                      </td>
                      <td>
                        <div className="calendar-cell-container">
                          <div className="calendar-num">
                            <span>10</span>
                          </div>
                          <div className="events-container" />
                        </div>
                      </td>
                      <td>
                        <div className="calendar-cell-container">
                          <div className="calendar-num">
                            <span>12</span>
                          </div>
                          <div className="events-container" />
                        </div>
                      </td>
                      <td>
                        <div className="calendar-cell-container">
                          <div className="calendar-num">
                            <span>13</span>
                          </div>
                          <div className="events-container" />
                        </div>
                      </td>
                      <td>
                        <div className="calendar-cell-container">
                          <div className="calendar-num">
                            <span>14</span>
                          </div>
                          <div className="events-container" />
                        </div>
                      </td>
                      <td>
                        <div className="calendar-cell-container">
                          <div className="calendar-num">
                            <span>15</span>
                          </div>
                          <div className="events-container">
                            <div className="event base-yellow-bg">
                              <span className="event-name">{i18n.t('event name example')}</span>
                              <span className="time">12:00~14:00</span>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="calendar-cell-container">
                          <div className="calendar-num">
                            <span>16</span>
                          </div>
                          <div className="events-container" />
                        </div>
                      </td>
                    </tr>
                    {/*         line 1 */}
                    {/*         line 2 */}
                    <tr>
                      <td>
                        <div className="calendar-cell-container">
                          <div className="calendar-num">
                            <span>16</span>
                          </div>
                          <div className="events-container" />
                        </div>
                      </td>
                      <td className="current">
                        <div className="calendar-cell-container">
                          <div className="calendar-num">
                            <span>17</span>
                          </div>
                          <div className="events-container" />
                        </div>
                      </td>
                      <td>
                        <div className="calendar-cell-container">
                          <div className="calendar-num">
                            <span>18</span>
                          </div>
                          <div className="events-container" />
                        </div>
                      </td>
                      <td>
                        <div className="calendar-cell-container">
                          <div className="calendar-num">
                            <span>19</span>
                          </div>
                          <div className="events-container">
                            <div className="event base-red-bg">
                              <span className="event-name">{i18n.t('event name example')}</span>
                              <span className="time">12:00~14:00</span>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="calendar-cell-container">
                          <div className="calendar-num">
                            <span>20</span>
                          </div>
                          <div className="events-container" />
                        </div>
                      </td>
                      <td>
                        <div className="calendar-cell-container">
                          <div className="calendar-num">
                            <span>21</span>
                          </div>
                          <div className="events-container" />
                        </div>
                      </td>
                      <td>
                        <div className="calendar-cell-container">
                          <div className="calendar-num">
                            <span>22</span>
                          </div>
                          <div className="events-container" />
                        </div>
                      </td>
                    </tr>
                    {/*         line 2 */}
                    {/*         line 3 */}
                    <tr>
                      <td>
                        <div className="calendar-cell-container">
                          <div className="calendar-num">
                            <span>23</span>
                          </div>
                          <div className="events-container" />
                        </div>
                      </td>
                      <td>
                        <div className="calendar-cell-container">
                          <div className="calendar-num">
                            <span>24</span>
                          </div>
                          <div className="events-container" />
                        </div>
                      </td>
                      <td>
                        <div className="calendar-cell-container">
                          <div className="calendar-num">
                            <span>25</span>
                          </div>
                          <div className="events-container" />
                        </div>
                      </td>
                      <td>
                        <div className="calendar-cell-container">
                          <div className="calendar-num">
                            <span>26</span>
                          </div>
                          <div className="events-container" />
                        </div>
                      </td>
                      <td>
                        <div className="calendar-cell-container">
                          <div className="calendar-num">
                            <span>27</span>
                          </div>
                          <div className="events-container" />
                        </div>
                      </td>
                      <td>
                        <div className="calendar-cell-container">
                          <div className="calendar-num">
                            <span>28</span>
                          </div>
                          <div className="events-container" />
                        </div>
                      </td>
                      <td>
                        <div className="calendar-cell-container">
                          <div className="calendar-num">
                            <span>29</span>
                          </div>
                          <div className="events-container" />
                        </div>
                      </td>
                    </tr>
                    {/*         line 3 */}
                    {/*         line 4 */}
                    <tr>
                      <td>
                        <div className="calendar-cell-container">
                          <div className="calendar-num">
                            <span>30</span>
                          </div>
                          <div className="events-container" />
                        </div>
                      </td>
                      <td>
                        <div className="calendar-cell-container">
                          <div className="calendar-num">
                            <span>31</span>
                          </div>
                          <div className="events-container" />
                        </div>
                      </td>
                      <td className="disabled">
                        <div className="calendar-cell-container">
                          <div className="calendar-num">
                            <span>1</span>
                          </div>
                          <div className="events-container" />
                        </div>
                      </td>
                      <td className="disabled">
                        <div className="calendar-cell-container">
                          <div className="calendar-num">
                            <span>2</span>
                          </div>
                          <div className="events-container" />
                        </div>
                      </td>
                      <td className="disabled">
                        <div className="calendar-cell-container">
                          <div className="calendar-num">
                            <span>3</span>
                          </div>
                          <div className="events-container" />
                        </div>
                      </td>
                      <td className="disabled">
                        <div className="calendar-cell-container">
                          <div className="calendar-num">
                            <span>4</span>
                          </div>
                          <div className="events-container" />
                        </div>
                      </td>
                      <td className="disabled">
                        <div className="calendar-cell-container">
                          <div className="calendar-num">
                            <span>5</span>
                          </div>
                          <div className="events-container" />
                        </div>
                      </td>
                    </tr>
                    {/*         line 4 */}
                  </tbody>
                  {/* end of calendar body ////////////////// */}
                </table>
              </div>
            </div>
        )
    }
}

export default CalendarApp;