import React from "react";
import { Helmet } from "react-helmet";
import i18n from 'i18next';
import MainLayout from "../layouts/Main";

class BoxModalPage extends React.Component {
    render(){
        return (
          <MainLayout>
            <Helmet>
              <title>{i18n.t('box modal')}</title>
            </Helmet>
            <div className="lvl-1-container">
              {/* paddings ////////////////////// */}
              <div>padding</div>
              <div className="grid-of-3">
                {/* p-1 */}
                <div>
                  <div className="box-modal-item-container p-1">
                    <div className="primary-bg-color text-white">&nbsp;</div>
                  </div>
                </div>
                {/* p-2 */}
                <div>
                  <div className="box-modal-item-container p-2">
                    <div className="primary-bg-color text-white">&nbsp;</div>   
                  </div>
                </div>
                {/* p-3 */}
                <div>
                  <div className="box-modal-item-container p-3">
                    <div className="primary-bg-color text-white">&nbsp;</div>   
                  </div>
                </div>
                {/* p-4 */}
                <div>
                  <div className="box-modal-item-container p-4">
                    <div className="primary-bg-color text-white">&nbsp;</div>   
                  </div>
                </div>
                {/* p-5 */}
                <div>
                  <div className="box-modal-item-container p-5">
                    <div className="primary-bg-color text-white">&nbsp;</div>   
                  </div>
                </div>
                {/* p-6 */}
                <div>
                  <div className="box-modal-item-container p-6">
                    <div className="primary-bg-color text-white">&nbsp;</div>   
                  </div>
                </div>
                {/* p-7 */}
                <div>
                  <div className="box-modal-item-container p-7">
                    <div className="primary-bg-color text-white">&nbsp;</div>   
                  </div>
                </div>
                {/* p-8 */}
                <div>
                  <div className="box-modal-item-container p-8">
                    <div className="primary-bg-color text-white">&nbsp;</div>   
                  </div>
                </div>
                {/* p-9 */}
                <div>
                  <div className="box-modal-item-container p-9">
                    <div className="primary-bg-color text-white">&nbsp;</div>   
                  </div>
                </div>
                {/* p-10 */}
                <div>
                  <div className="box-modal-item-container p-10">
                    <div className="primary-bg-color text-white">&nbsp;</div>   
                  </div>
                </div>
              </div>
              {/* paddings right ////////////////////// */}
              <hr />
              <div>padding-right</div>
              <div className="grid-of-3">
                {/* pr-1 */}
                <div>
                  <div className="box-modal-item-container pr-1">
                    <div className="primary-bg-color text-white">&nbsp;</div>
                  </div>
                </div>
                {/* pr-2 */}
                <div>
                  <div className="box-modal-item-container pr-2">
                    <div className="primary-bg-color text-white">&nbsp;</div>   
                  </div>
                </div>
                {/* pr-3 */}
                <div>
                  <div className="box-modal-item-container pr-3">
                    <div className="primary-bg-color text-white">&nbsp;</div>   
                  </div>
                </div>
                {/* pr-4 */}
                <div>
                  <div className="box-modal-item-container pr-4">
                    <div className="primary-bg-color text-white">&nbsp;</div>   
                  </div>
                </div>
                {/* pr-5 */}
                <div>
                  <div className="box-modal-item-container pr-5">
                    <div className="primary-bg-color text-white">&nbsp;</div>   
                  </div>
                </div>
                {/* pr-6 */}
                <div>
                  <div className="box-modal-item-container pr-6">
                    <div className="primary-bg-color text-white">&nbsp;</div>   
                  </div>
                </div>
                {/* pr-7 */}
                <div>
                  <div className="box-modal-item-container pr-7">
                    <div className="primary-bg-color text-white">&nbsp;</div>   
                  </div>
                </div>
                {/* pr-8 */}
                <div>
                  <div className="box-modal-item-container pr-8">
                    <div className="primary-bg-color text-white">&nbsp;</div>   
                  </div>
                </div>
                {/* pr-9 */}
                <div>
                  <div className="box-modal-item-container pr-9">
                    <div className="primary-bg-color text-white">&nbsp;</div>   
                  </div>
                </div>
                {/* pr-10 */}
                <div>
                  <div className="box-modal-item-container pr-10">
                    <div className="primary-bg-color text-white">&nbsp;</div>   
                  </div>
                </div>
              </div>
              {/* paddings left ////////////////////// */}
              <hr />
              <div>
                padding-left
              </div>
              <div className="grid-of-3">
                {/* pl-1 */}
                <div>
                  <div className="box-modal-item-container pl-1">
                    <div className="primary-bg-color text-white">&nbsp;</div>
                  </div>
                </div>
                {/* pl-2 */}
                <div>
                  <div className="box-modal-item-container pl-2">
                    <div className="primary-bg-color text-white">&nbsp;</div>   
                  </div>
                </div>
                {/* pl-3 */}
                <div>
                  <div className="box-modal-item-container pl-3">
                    <div className="primary-bg-color text-white">&nbsp;</div>   
                  </div>
                </div>
                {/* pl-4 */}
                <div>
                  <div className="box-modal-item-container pl-4">
                    <div className="primary-bg-color text-white">&nbsp;</div>   
                  </div>
                </div>
                {/* pl-5 */}
                <div>
                  <div className="box-modal-item-container pl-5">
                    <div className="primary-bg-color text-white">&nbsp;</div>   
                  </div>
                </div>
                {/* pl-6 */}
                <div>
                  <div className="box-modal-item-container pl-6">
                    <div className="primary-bg-color text-white">&nbsp;</div>   
                  </div>
                </div>
                {/* pl-7 */}
                <div>
                  <div className="box-modal-item-container pl-7">
                    <div className="primary-bg-color text-white">&nbsp;</div>   
                  </div>
                </div>
                {/* pl-8 */}
                <div>
                  <div className="box-modal-item-container pl-8">
                    <div className="primary-bg-color text-white">&nbsp;</div>   
                  </div>
                </div>
                {/* pl-9 */}
                <div>
                  <div className="box-modal-item-container pl-9">
                    <div className="primary-bg-color text-white">&nbsp;</div>   
                  </div>
                </div>
                {/* pl-10 */}
                <div>
                  <div className="box-modal-item-container pl-10">
                    <div className="primary-bg-color text-white">&nbsp;</div>   
                  </div>
                </div>
              </div>
              {/* paddings top ////////////////////// */}
              <hr />
              <div>padding-top</div>
              <div className="grid-of-3">
                {/* pt-1 */}
                <div>
                  <div className="box-modal-item-container pt-1">
                    <div className="primary-bg-color text-white">&nbsp;</div>
                  </div>
                </div>
                {/* pt-2 */}
                <div>
                  <div className="box-modal-item-container pt-2">
                    <div className="primary-bg-color text-white">&nbsp;</div>   
                  </div>
                </div>
                {/* pt-3 */}
                <div>
                  <div className="box-modal-item-container pt-3">
                    <div className="primary-bg-color text-white">&nbsp;</div>   
                  </div>
                </div>
                {/* pt-4 */}
                <div>
                  <div className="box-modal-item-container pt-4">
                    <div className="primary-bg-color text-white">&nbsp;</div>   
                  </div>
                </div>
                {/* pt-5 */}
                <div>
                  <div className="box-modal-item-container pt-5">
                    <div className="primary-bg-color text-white">&nbsp;</div>   
                  </div>
                </div>
                {/* pt-6 */}
                <div>
                  <div className="box-modal-item-container pt-6">
                    <div className="primary-bg-color text-white">&nbsp;</div>   
                  </div>
                </div>
                {/* pt-7 */}
                <div>
                  <div className="box-modal-item-container pt-7">
                    <div className="primary-bg-color text-white">&nbsp;</div>   
                  </div>
                </div>
                {/* pt-8 */}
                <div>
                  <div className="box-modal-item-container pt-8">
                    <div className="primary-bg-color text-white">&nbsp;</div>   
                  </div>
                </div>
                {/* pt-9 */}
                <div>
                  <div className="box-modal-item-container pt-9">
                    <div className="primary-bg-color text-white">&nbsp;</div>   
                  </div>
                </div>
                {/* pt-10 */}
                <div>
                  <div className="box-modal-item-container pt-10">
                    <div className="primary-bg-color text-white">&nbsp;</div>   
                  </div>
                </div>
              </div>
              {/* paddings bottom ////////////////////// */}
              <hr />
              <div>padding-bottom</div>
              <div className="grid-of-3">
                {/* pb-1 */}
                <div>
                  <div className="box-modal-item-container pb-1">
                    <div className="primary-bg-color text-white">&nbsp;</div>
                  </div>
                </div>
                {/* pb-2 */}
                <div>
                  <div className="box-modal-item-container pb-2">
                    <div className="primary-bg-color text-white">&nbsp;</div>   
                  </div>
                </div>
                {/* pb-3 */}
                <div>
                  <div className="box-modal-item-container pb-3">
                    <div className="primary-bg-color text-white">&nbsp;</div>   
                  </div>
                </div>
                {/* pb-4 */}
                <div>
                  <div className="box-modal-item-container pb-4">
                    <div className="primary-bg-color text-white">&nbsp;</div>   
                  </div>
                </div>
                {/* pb-5 */}
                <div>
                  <div className="box-modal-item-container pb-5">
                    <div className="primary-bg-color text-white">&nbsp;</div>   
                  </div>
                </div>
                {/* pb-6 */}
                <div>
                  <div className="box-modal-item-container pb-6">
                    <div className="primary-bg-color text-white">&nbsp;</div>   
                  </div>
                </div>
                {/* pb-7 */}
                <div>
                  <div className="box-modal-item-container pb-7">
                    <div className="primary-bg-color text-white">&nbsp;</div>   
                  </div>
                </div>
                {/* pb-8 */}
                <div>
                  <div className="box-modal-item-container pb-8">
                    <div className="primary-bg-color text-white">&nbsp;</div>   
                  </div>
                </div>
                {/* pb-9 */}
                <div>
                  <div className="box-modal-item-container pb-9">
                    <div className="primary-bg-color text-white">&nbsp;</div>   
                  </div>
                </div>
                {/* pb-10 */}
                <div>
                  <div className="box-modal-item-container pb-10">
                    <div className="primary-bg-color text-white">&nbsp;</div>   
                  </div>
                </div>
              </div>
              {/* margin ////////////////////// */}
              <hr />
              <div>margin</div>
              <div className="grid-of-3">
                {/* m-1 */}
                <div>
                  <div className="box-modal-item-container">
                    <div className="primary-bg-color text-white m-1">&nbsp;</div>
                  </div>
                </div>
                {/* m-2 */}
                <div>
                  <div className="box-modal-item-container">
                    <div className="primary-bg-color text-white m-2">&nbsp;</div>   
                  </div>
                </div>
                {/* m-3 */}
                <div>
                  <div className="box-modal-item-container">
                    <div className="primary-bg-color text-white m-3">&nbsp;</div>   
                  </div>
                </div>
                {/* m-4 */}
                <div>
                  <div className="box-modal-item-container">
                    <div className="primary-bg-color text-white m-4">&nbsp;</div>   
                  </div>
                </div>
                {/* m-5 */}
                <div>
                  <div className="box-modal-item-container">
                    <div className="primary-bg-color text-white m-5">&nbsp;</div>   
                  </div>
                </div>
                {/* m-6 */}
                <div>
                  <div className="box-modal-item-container">
                    <div className="primary-bg-color text-white m-6">&nbsp;</div>   
                  </div>
                </div>
                {/* m-7 */}
                <div>
                  <div className="box-modal-item-container">
                    <div className="primary-bg-color text-white m-7">&nbsp;</div>   
                  </div>
                </div>
                {/* m-8 */}
                <div>
                  <div className="box-modal-item-container">
                    <div className="primary-bg-color text-white m-8">&nbsp;</div>   
                  </div>
                </div>
                {/* m-9 */}
                <div>
                  <div className="box-modal-item-container">
                    <div className="primary-bg-color text-white m-9">&nbsp;</div>   
                  </div>
                </div>
                {/* m-10 */}
                <div>
                  <div className="box-modal-item-container">
                    <div className="primary-bg-color text-white m-10">&nbsp;</div>   
                  </div>
                </div>
              </div>
              {/* margin  right////////////////////// */}
              <hr />
              <div>margin right</div>
              <div className="grid-of-3">
                {/* mr-1 */}
                <div>
                  <div className="box-modal-item-container">
                    <div className="primary-bg-color text-white mr-1">&nbsp;</div>
                  </div>
                </div>
                {/* mr-2 */}
                <div>
                  <div className="box-modal-item-container">
                    <div className="primary-bg-color text-white mr-2">&nbsp;</div>   
                  </div>
                </div>
                {/* mr-3 */}
                <div>
                  <div className="box-modal-item-container">
                    <div className="primary-bg-color text-white mr-3">&nbsp;</div>   
                  </div>
                </div>
                {/* mr-4 */}
                <div>
                  <div className="box-modal-item-container">
                    <div className="primary-bg-color text-white mr-4">&nbsp;</div>   
                  </div>
                </div>
                {/* mr-5 */}
                <div>
                  <div className="box-modal-item-container">
                    <div className="primary-bg-color text-white mr-5">&nbsp;</div>   
                  </div>
                </div>
                {/* mr-6 */}
                <div>
                  <div className="box-modal-item-container">
                    <div className="primary-bg-color text-white mr-6">&nbsp;</div>   
                  </div>
                </div>
                {/* mr-7 */}
                <div>
                  <div className="box-modal-item-container">
                    <div className="primary-bg-color text-white mr-7">&nbsp;</div>   
                  </div>
                </div>
                {/* mr-8 */}
                <div>
                  <div className="box-modal-item-container">
                    <div className="primary-bg-color text-white mr-8">&nbsp;</div>   
                  </div>
                </div>
                {/* mr-9 */}
                <div>
                  <div className="box-modal-item-container">
                    <div className="primary-bg-color text-white mr-9">&nbsp;</div>   
                  </div>
                </div>
                {/* mr-10 */}
                <div>
                  <div className="box-modal-item-container">
                    <div className="primary-bg-color text-white mr-10">&nbsp;</div>   
                  </div>
                </div>
              </div>
              {/* margin left //////////// */}
              <hr />
              <div>margin left</div>
              <div className="grid-of-3">
                {/* ml-1 */}
                <div>
                  <div className="box-modal-item-container">
                    <div className="primary-bg-color text-white ml-1">&nbsp;</div>
                  </div>
                </div>
                {/* ml-2 */}
                <div>
                  <div className="box-modal-item-container">
                    <div className="primary-bg-color text-white ml-2">&nbsp;</div>   
                  </div>
                </div>
                {/* ml-3 */}
                <div>
                  <div className="box-modal-item-container">
                    <div className="primary-bg-color text-white ml-3">&nbsp;</div>   
                  </div>
                </div>
                {/* ml-4 */}
                <div>
                  <div className="box-modal-item-container">
                    <div className="primary-bg-color text-white ml-4">&nbsp;</div>   
                  </div>
                </div>
                {/* ml-5 */}
                <div>
                  <div className="box-modal-item-container">
                    <div className="primary-bg-color text-white ml-5">&nbsp;</div>   
                  </div>
                </div>
                {/* ml-6 */}
                <div>
                  <div className="box-modal-item-container">
                    <div className="primary-bg-color text-white ml-6">&nbsp;</div>   
                  </div>
                </div>
                {/* ml-7 */}
                <div>
                  <div className="box-modal-item-container">
                    <div className="primary-bg-color text-white ml-7">&nbsp;</div>   
                  </div>
                </div>
                {/* ml-8 */}
                <div>
                  <div className="box-modal-item-container">
                    <div className="primary-bg-color text-white ml-8">&nbsp;</div>   
                  </div>
                </div>
                {/* ml-9 */}
                <div>
                  <div className="box-modal-item-container">
                    <div className="primary-bg-color text-white ml-9">&nbsp;</div>   
                  </div>
                </div>
                {/* ml-10 */}
                <div>
                  <div className="box-modal-item-container">
                    <div className="primary-bg-color text-white ml-10">&nbsp;</div>   
                  </div>
                </div>
              </div>
              {/* margin top //////////// */}
              <hr />
              <div>margin top</div>
              <div className="grid-of-3">
                {/* mt-1 */}
                <div>
                  <div className="box-modal-item-container flex">
                    <div className="primary-bg-color text-white mt-1 w-full">&nbsp;</div>
                  </div>
                </div>
                {/* mt-2 */}
                <div>
                  <div className="box-modal-item-container flex">
                    <div className="primary-bg-color text-white mt-2 w-full">&nbsp;</div>   
                  </div>
                </div>
                {/* mt-3 */}
                <div>
                  <div className="box-modal-item-container flex">
                    <div className="primary-bg-color text-white mt-3 w-full">&nbsp;</div>   
                  </div>
                </div>
                {/* mt-4 */}
                <div>
                  <div className="box-modal-item-container flex">
                    <div className="primary-bg-color text-white mt-4 w-full">&nbsp;</div>   
                  </div>
                </div>
                {/* mt-5 */}
                <div>
                  <div className="box-modal-item-container flex">
                    <div className="primary-bg-color text-white mt-5 w-full">&nbsp;</div>   
                  </div>
                </div>
                {/* mt-6 */}
                <div>
                  <div className="box-modal-item-container flex">
                    <div className="primary-bg-color text-white mt-6 w-full">&nbsp;</div>   
                  </div>
                </div>
                {/* mt-7 */}
                <div>
                  <div className="box-modal-item-container flex">
                    <div className="primary-bg-color text-white mt-7 w-full">&nbsp;</div>   
                  </div>
                </div>
                {/* mt-8 */}
                <div>
                  <div className="box-modal-item-container flex">
                    <div className="primary-bg-color text-white mt-8 w-full">&nbsp;</div>   
                  </div>
                </div>
                {/* mt-9 */}
                <div>
                  <div className="box-modal-item-container flex">
                    <div className="primary-bg-color text-white mt-9 w-full">&nbsp;</div>   
                  </div>
                </div>
                {/* mt-10 */}
                <div>
                  <div className="box-modal-item-container flex">
                    <div className="primary-bg-color text-white mt-10 w-full">&nbsp;</div>   
                  </div>
                </div>
              </div>
              {/* margin bottom //////////// */}
              <hr />
              <div>margin bottom</div>
              <div className="grid-of-3">
                {/* mb-1 */}
                <div>
                  <div className="box-modal-item-container flex">
                    <div className="primary-bg-color text-white mb-1 w-full">&nbsp;</div>
                  </div>
                </div>
                {/* mb-2 */}
                <div>
                  <div className="box-modal-item-container flex">
                    <div className="primary-bg-color text-white mb-2 w-full">&nbsp;</div>   
                  </div>
                </div>
                {/* mb-3 */}
                <div>
                  <div className="box-modal-item-container flex">
                    <div className="primary-bg-color text-white mb-3 w-full">&nbsp;</div>   
                  </div>
                </div>
                {/* mb-4 */}
                <div>
                  <div className="box-modal-item-container flex">
                    <div className="primary-bg-color text-white mb-4 w-full">&nbsp;</div>   
                  </div>
                </div>
                {/* mb-5 */}
                <div>
                  <div className="box-modal-item-container flex">
                    <div className="primary-bg-color text-white mb-5 w-full">&nbsp;</div>   
                  </div>
                </div>
                {/* mb-6 */}
                <div>
                  <div className="box-modal-item-container flex">
                    <div className="primary-bg-color text-white mb-6 w-full">&nbsp;</div>   
                  </div>
                </div>
                {/* mb-7 */}
                <div>
                  <div className="box-modal-item-container flex">
                    <div className="primary-bg-color text-white mb-7 w-full">&nbsp;</div>   
                  </div>
                </div>
                {/* mb-8 */}
                <div>
                  <div className="box-modal-item-container flex">
                    <div className="primary-bg-color text-white mb-8 w-full">&nbsp;</div>   
                  </div>
                </div>
                {/* mb-9 */}
                <div>
                  <div className="box-modal-item-container flex">
                    <div className="primary-bg-color text-white mb-9 w-full">&nbsp;</div>   
                  </div>
                </div>
                {/* mb-10 */}
                <div>
                  <div className="box-modal-item-container flex">
                    <div className="primary-bg-color text-white mb-10 w-full">&nbsp;</div>   
                  </div>
                </div>
              </div>
            </div>
          </MainLayout>
        )
    }
}

export default BoxModalPage;