import React from "react";

class DoublePreloadersGroup extends React.Component {
    render(){
        return (
            <div className="flex flex-wrap gap-7 my-2">
                <div className="preloader spinner sm default double"></div>
                <div className="preloader spinner sm amber double"></div>
                <div className="preloader spinner sm blue double"></div>
                <div className="preloader spinner sm cyan double"></div>
                <div className="preloader spinner sm emerald double"></div>
                <div className="preloader spinner sm gray double"></div>
                <div className="preloader spinner sm green double"></div>
                <div className="preloader spinner sm lime double"></div>
                <div className="preloader spinner sm orange double"></div>
                <div className="preloader spinner sm pink double"></div>
                <div className="preloader spinner sm purple double"></div>
                <div className="preloader spinner sm red double"></div>
                <div className="preloader spinner sm sky double"></div>
                <div className="preloader spinner sm teal double"></div>
                <div className="preloader spinner sm yellow double"></div>
            </div>
        )
    }
}

export default DoublePreloadersGroup;