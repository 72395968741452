import React from "react";
import i18n from 'i18next';
import BasePagination from "../components/pagination/BasePagination";
import TableRowLikeNotification from "../components/notifications/TableRowLikeNotification";
import MainLayout from "../layouts/Main";
import { Helmet } from "react-helmet";

class NotificationsPage extends React.Component {
    render(){
        return (
           <MainLayout>
             <Helmet>
              <title>{i18n.t('notifications')}</title>
             </Helmet>
             <div className="lvl-1-container p-4 my-5">
              <div className="my-4 flex">
                <h6 className="font-lg flex-1">{i18n.t('notifications')}</h6>
                <a href="#" className="simple-button sm gray">
                  <span>{i18n.t('set seen')}</span>
                  <svg className="h-4 w-4 inline" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <polyline points="3 9 12 15 21 9 12 3 3 9" />  <path d="M21 9v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10" />  <line x1={3} y1={19} x2={9} y2={13} />  <line x1={15} y1={13} x2={21} y2={19} /></svg>
                </a>
              </div>
              {/* filtering options */}
              <div className="my-4 flex">
                <h6 className="text-sm flex-1">{i18n.t('n to n from n elements')}</h6>
                <select className="simple-button sm primary-bg-color">
                  <option hidden selected>{i18n.t('show')}</option>
                  <option value={1}>5</option>
                  <option value={2}>10</option>
                  <option value={2}>20</option>
                  <option value={2}>50</option>
                </select>
              </div>
              {/* pagination //////////// */}
              <BasePagination />
              
              {/* activities list /////////////// */}
              <div className="table-container notifications my-4">
                <table>
                    {/* activities list /// */}
                    <TableRowLikeNotification />
                    <TableRowLikeNotification />
                    <TableRowLikeNotification />
                    <TableRowLikeNotification />
                    <TableRowLikeNotification />
                    <TableRowLikeNotification />
                </table>
              </div>
              {/* end of activities list*/}
            </div>
           </MainLayout>
        )
    }
}

export default NotificationsPage;