import React from "react";
import { Helmet } from "react-helmet";
import BgColors from "../components/typography/BgColors";
import BorderColors from "../components/typography/BorderColors";
import Conatiners from "../components/typography/Containers";
import TextColors from "../components/typography/TextColors";
import TextStyles from "../components/typography/TextStyles";
import MainLayout from "../layouts/Main";
import i18n from 'i18next';

class TypographyPage extends React.Component {
    render(){
        return (
            <MainLayout>
                <Helmet>
                    <title>{i18n.t('typography')}</title>
                </Helmet>
                <div className="grid-of-3">
                   <BgColors />
                   <TextColors />
                   <BorderColors />
                   <Conatiners />
                   <TextStyles />
                </div>
            </MainLayout>
        )
    }
}

export default TypographyPage;