import React from 'react';
import { createBrowserRouter } from "react-router-dom";

/**
 * importing pages
*/
import ProfilePage from '../jsx/pages/ProfilePage';
import IndexPage from '../jsx/pages/IndexPage';
import TablesPage from '../jsx/pages/TablesPage';
import AlertsPage from '../jsx/pages/AlertsPage';
import ButtonsPage from '../jsx/pages/ButtonsPage';
import FormPage from '../jsx/pages/FormPage';
import ModalsPage from '../jsx/pages/ModalsPage';
import PreloaderPage from '../jsx/pages/PreloadersPage';
import TabsPage from '../jsx/pages/TabsPage';
import CardsPage from '../jsx/pages/CardsPage';
import TooltipsPage from '../jsx/pages/TooltipsPage';
import PaginationPage from '../jsx/pages/PaginationPage';
import GalleryPage from '../jsx/pages/GalleryPage';
import MessengerPage from '../jsx/pages/MessengerPage';
import MailInboxPage from '../jsx/pages/MailInboxPage';
import FileManagerPage from '../jsx/pages/FileManagerPage';
import CalendarPage from '../jsx/pages/CalendarPage';
import TasksListPage from '../jsx/pages/TasksListPage';
import NotificationsPage from '../jsx/pages/NotificationsPage';
import LoginPage from '../jsx/pages/LoginPage';
import RegisterPage from '../jsx/pages/RegisterPage';
import Error404Page from '../jsx/pages/Error404Page';
import Error500Page from '../jsx/pages/Error500Page';
import FlexboxPage from '../jsx/pages/FlexboxPage';
import GridPage from '../jsx/pages/GridPage';
import BoxModalPage from '../jsx/pages/BoxModalPage';
import TypographyPage from '../jsx/pages/TypographyPage';
import ChartsPage from '../jsx/pages/ChartsPage';

/**
 * creating a browser router instance
 * @see https://reactrouter.com/en/main/start/tutorial
 */
const router = createBrowserRouter([
    {
      path: "/",
      element: <IndexPage />,
    },
    {
      path: "/profile",
      element: <ProfilePage />,
    },
    {
      path: "/tables",
      element: <TablesPage />,
    },
    {
      path: "/alerts",
      element: <AlertsPage />,
    },
    {
      path: "/buttons",
      element: <ButtonsPage />,
    },
    {
      path: "/forms",
      element: <FormPage />,
    },
    {
      path: "/modals",
      element: <ModalsPage />,
    },
    {
      path: "/preloaders",
      element: <PreloaderPage />,
    },
    {
      path: "/tabs",
      element: <TabsPage />,
    },
    {
      path: "/cards",
      element: <CardsPage />,
    },
    {
      path: "/tooltips",
      element: <TooltipsPage />,
    },
    {
      path: "/pagination",
      element: <PaginationPage />,
    },
    {
      path: "/gallery",
      element: <GalleryPage />,
    },
    {
      path: "/messenger",
      element: <MessengerPage />,
    },
    {
      path: "/inbox",
      element: <MailInboxPage />,
    },
    {
      path: "/file-manager",
      element: <FileManagerPage />,
    },
    {
      path: "/calendar",
      element: <CalendarPage />,
    },
    {
      path: "/tasks-list",
      element: <TasksListPage />,
    },
    {
      path: "/notifications",
      element: <NotificationsPage />,
    },
    {
      path: "/login",
      element: <LoginPage />
    },
    {
      path: "/register",
      element: <RegisterPage />
    },
    {
      path: "/404",
      element: <Error404Page />
    },
    {
      path: "/500",
      element: <Error500Page />
    },
    {
      path: "/flexbox",
      element: <FlexboxPage /> ,
    },
    {
      path: "/grid",
      element: <GridPage />,
    },
    {
      path: "/box-modal",
      element: <BoxModalPage />,
    },
    {
      path: "/typography",
      element: <TypographyPage />,
    },
    {
      path: "/charts",
      element: <ChartsPage />,
    },
]);

export default router;