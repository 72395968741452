import React from 'react';
import i18n from 'i18next';

class ThemeController extends React.Component {
    render(){
        return (
            <div dir="rtl" className="flex fixed bottom-10 left-0 items-center">
              <div>
                {/* setting button //////////// */}
                <button onClick={() => window.Lawha.toggleItem("#settings-panel")} aria-label="setting" className="shadow-2xl rounded-r-xl p-2 bg-gray-300 dark:bg-gray-600">
                  <svg className="h-7 w-7 animate-spin" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                  </svg> 
                </button> 
              </div>
              {/* settings panel */}
              <div id="settings-panel" className="bg-gray-300 dark:bg-gray-600 px-4 py-4 rounded-md hidden shadow-2xl">
                {/* change colors ///////////////////////// */}
                <div>
                  <h2>{i18n.t('colors')}</h2>
                  <div className="grid grid-cols-3 p-2 gap-2">
                    <button aria-label="color" onClick={() => window.Lawha.initTheme({color: 'amber'})} className="simple-button text-sm amber">{i18n.t('amber')}</button>
                    <button aria-label="color" onClick={() => window.Lawha.initTheme({color: 'blue'})} className="simple-button text-sm blue">{i18n.t('blue')}</button>
                    <button aria-label="color" onClick={() => window.Lawha.initTheme({color: 'cyan'})} className="simple-button text-sm cyan">{i18n.t('cyan')}</button>
                    <button aria-label="color" onClick={() => window.Lawha.initTheme({color: 'emerald'})} className="simple-button text-sm emerald">{i18n.t('emerald')}</button>
                    <button aria-label="color" onClick={() => window.Lawha.initTheme({color: 'gray'})} className="simple-button text-sm gray">{i18n.t('gray')}</button>
                    <button aria-label="color" onClick={() => window.Lawha.initTheme({color: 'green'})} className="simple-button text-sm green">{i18n.t('green')}</button>
                    <button aria-label="color" onClick={() => window.Lawha.initTheme({color: 'lime'})} className="simple-button text-sm lime">{i18n.t('lime')}</button>
                    <button aria-label="color" onClick={() => window.Lawha.initTheme({color: 'orange'})} className="simple-button text-sm orange">{i18n.t('orange')}</button>
                    <button aria-label="color" onClick={() => window.Lawha.initTheme({color: 'pink'})} className="simple-button text-sm pink">{i18n.t('pink')}</button>
                    <button aria-label="color" onClick={() => window.Lawha.initTheme({color: 'purple'})} className="simple-button text-sm purple">{i18n.t('purple')}</button>
                    <button aria-label="color" onClick={() => window.Lawha.initTheme({color: 'red'})} className="simple-button text-sm red">{i18n.t('red')}</button>
                    <button aria-label="color" onClick={() => window.Lawha.initTheme({color: 'sky'})} className="simple-button text-sm sky">{i18n.t('sky')}</button>
                    <button aria-label="color" onClick={() => window.Lawha.initTheme({color: 'teal'})} className="simple-button text-sm teal">{i18n.t('teal')}</button>
                    <button aria-label="color" onClick={() => window.Lawha.initTheme({color: 'yellow'})} className="simple-button text-sm yellow">{i18n.t('yellow')}</button>
                  </div>
                </div>
                <hr />
                {/* change modes ///////////////////////// */}
                <div>
                  <h2>{i18n.t('modes')}</h2>
                  {/* theme switcher /////////////// */}
                  <div>
                    <button aria-label="theme-toggler" className="theme-switcher" onClick={() => window.Lawha.switchMode(true)}>
                      <svg className="svg-sm sun" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <circle cx={12} cy={12} r={4} />  <path d="M3 12h1M12 3v1M20 12h1M12 20v1M5.6 5.6l.7 .7M18.4 5.6l-.7 .7M17.7 17.7l.7 .7M6.3 17.7l-.7 .7" /></svg>
                      <svg className="svg-sm moon mr-auto" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <path d="M16.2 4a9.03 9.03 0 1 0 3.9 12a6.5 6.5 0 1 1 -3.9 -12" /></svg>
                    </button>
                  </div>
                </div>
                <hr />
                {/* change container ////////////////// */}
                <div>
                  <h2>{i18n.t('containers')}</h2>
                  <div className="form-element inlined switch mt-4">
                    <label>{i18n.t('sharp')}</label>
                    <div className="flex">
                      <label htmlFor="sharp">
                        <div className="toggle-container md">
                          <input defaultChecked onChange={() => document.body.classList.toggle('sharp')} type="checkbox" id="sharp" className="sr-only" />
                          <div className="switch-bg" />
                          <div className="dot" />
                        </div>
                      </label>
                    </div>
                  </div>
                  <div className="form-element inlined switch mt-4">
                    <label>{i18n.t('shadowless')}</label>
                    <div className="flex">
                      <label htmlFor="shadowLess">
                        <div className="toggle-container md">
                          <input onChange={() => document.body.classList.toggle('shadowless')} type="checkbox" id="shadowLess" className="sr-only" />
                          <div className="switch-bg" />
                          <div className="dot" />
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
                <hr />
                {/* sidebar toggler //////////// */}
                <div className="hidden lg:inline">
                  <h2 className="mb-2">{i18n.t('navigation modes')}</h2>
                  <div className="flex">
                    <label className="flex gap-2">
                      <span>{i18n.t('vertical')}</span>
                      <input onChange={() => document.body.classList.add('vertical')} type="radio" data-attr-t value-t="vertical" name="sidemode" />
                    </label>
                    <div className="flex-1" />
                    <label className="flex gap-2">
                      <span>{i18n.t('horizontal')}</span>
                      <input onChange={() => document.body.classList.remove('vertical')} type="radio" data-attr-t value-t="horizontal" name="sidemode" />
                    </label>
                  </div>
                </div>
              </div>
            </div>
        )
    }
}

export default ThemeController;