const ar = require('./ar.json')
const en = require('./en.json')

export default {
  en: {
    translation: en
  },
  ar: {
    translation: ar
  }
}