import React from "react";
import i18n from 'i18next';

class SparkThree extends React.Component {
    render(){
        return (
            <div className="lvl-1-container">
              <h1 className="m-4 chart-title">{i18n.t('chart title')}</h1>
              <div id="spark3" />
            </div>
        )
    }
}

export default SparkThree;