import React from "react";
import i18n from 'i18next';

class MessengerApp extends React.Component {
    render(){
        return (
            <div className="lvl-1-container mx-auto">
              <div className="messenger">
                {/* mobile nav tabs  /////////////////  */}
                <div className="block lg:hidden tab pt-5">
                  <button className="flex mx-auto" onClick={() => window.Lawha.toggleSiblings('#messages' ,'#contacts')}>
                    <svg className="w-6 h-6 mx-auto" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <path d="M21 14l-3 -3h-7a1 1 0 0 1 -1 -1v-6a1 1 0 0 1 1 -1h9a1 1 0 0 1 1 1v10" />  <path d="M14 15v2a1 1 0 0 1 -1 1h-7l-3 3v-10a1 1 0 0 1 1 -1h2" /></svg>
                  </button>
                  <button id="show-contacts" className="mx-auto" onClick={() => window.Lawha.toggleSiblings('#contacts' ,'#messages')}>
                    <svg className="w-6 h-6 mx-auto" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <circle cx={9} cy={7} r={4} />  <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />  <path d="M16 3.13a4 4 0 0 1 0 7.75" />  <path d="M21 21v-2a4 4 0 0 0 -3 -3.85" /></svg>
                  </button>
                </div>
                {/*contacts ////////////////////////// */}
                <div id="contacts" className="hidden lg:block contacts">
                  <div className="form-element iconed m-0 lg:m-2">
                    <div>
                      <i>
                        <svg className="svg-sm primary-text-color" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} viewBox="0 0 24 24">
                          <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                        </svg>
                      </i>
                      <input type="text" data-attr-t placeholder-t="search for contact" />
                    </div>
                  </div>
                  <div className="chats">
                    <h2 className="p-2">{i18n.t('contacts')}</h2>
                    {(() => {
                        let contacts = []
                        for (let index = 0; index < 10; index++) {
                            contacts.push(
                                <a key={index} className="contact">
                                  <img src="https://via.placeholder.com/40" alt="username" />
                                  <div className="w-full pb-2">
                                    <div className="flex justify-between">
                                      <span className="block ml-2 font-semibold">{i18n.t('jhon doe')}</span>
                                      <span className="block ml-2 text-sm">{i18n.t('seconds ago')}</span>
                                    </div>
                                    <span className="block ml-2 text-sm">{i18n.t('message example')}</span>
                                  </div>
                                </a>
                            )
                        }
                        return contacts;
                    })()}
                  </div>
                </div>
                {/* messages /////////////////////////// */}
                <div id="messages" className="lg:col-span-2">
                  <div className="w-full">
                    {/* contact details /////////////// */}
                    <div className="contact-details">
                      <img data-src="https://via.placeholder.com/40" className="lazy" alt="username" />
                      <span className="name">{i18n.t('jhon doe')}</span>
                      <span className="status on" />
                    </div>
                    {/* messages list /////////////// */}
                    <div className="messages">
                      <ul className="space-y-2">

                        {(() => {
                            let messages = []
                            for (let index = 0; index < 10; index++) {
                                // sent
                                messages.push(
                                    <li className="msg sent">
                                      <div className="msg-box">
                                        <span className="block">{i18n.t('message example')}</span>
                                      </div>
                                    </li>
                                )  

                                // received
                                messages.push(
                                    <li className="msg received">
                                      <div className="msg-box">
                                        <span className="block">{i18n.t('message example')}</span>
                                      </div>
                                    </li>
                                )
                            }
                            return messages;
                        })()}
                      </ul>
                    </div>
                    {/* typing box ////////////////// */}
                    <div className="type-box">
                      <button name="button">
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                      </button>
                      <button name="button">
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13" />
                        </svg>
                      </button>
                      <input type="text" data-attr-t placeholder-t="type your message here" className="input w-full" name="message" required />
                      <button name="button">
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z" />
                        </svg>
                      </button>
                      <button name="button" type="submit">
                        <svg className="w-5 h-5 text-gray-500 origin-center transform rotate-90" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                          <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        )
    }
}

export default MessengerApp;