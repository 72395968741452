import React from "react";
import i18n from 'i18next';

class RadialBarChart extends React.Component {
    render(){
        return (
            <div className="lvl-1-container">
              <div className="chart-head">
                <div>
                  <h1 className="chart-title">{i18n.t('chart title')}</h1>
                  <h2 className="chart-shiffre">4979$</h2>
                  <div className="chart-sub-title">{i18n.t('chart sub title')}</div>
                </div>
                <select className="chart-options">
                  <option value="#">{i18n.t('option')}</option>
                  <option value="#">{i18n.t('option')}</option>
                  <option value="#">{i18n.t('option')}</option>
                  <option value="#">{i18n.t('option')}</option>
                </select>
              </div>
              <hr />
              <div className="chart-container">
                <div id="radialBarBottom" />
              </div>
            </div>
        )
    }
}

export default RadialBarChart;