import React from "react";

class ColSpans extends React.Component {
    render(){
        return (
            <div className="grid-of-10 my-4">
              {/* first row /////////// */}
              <div className="grid-cell span-2"> <strong className="text-lg">1 x 2</strong> </div>
              <div className="grid-cell span-3"> <strong className="text-lg">1 x 3</strong> </div>
              <div className="grid-cell span-4"> <strong className="text-lg">1 x 4</strong> </div>
              <div className="grid-cell"> <strong className="text-lg">1</strong> </div>
              {/* second row ////////////// */}
              <div className="grid-cell span-5"> <strong className="text-lg">1 x 5</strong> </div>
              <div className="grid-cell span-5"> <strong className="text-lg">1 x 5</strong> </div>
              {/* third row //////////// */}
              <div className="grid-cell span-6"> <strong className="text-lg">1 x 6</strong> </div>
              <div className="grid-cell span-4"> <strong className="text-lg">1 x 4</strong> </div>
              {/* fourth row ////////// */}
              <div className="grid-cell span-7"> <strong className="text-lg">1 x 7</strong> </div>
              <div className="grid-cell span-3"> <strong className="text-lg">1 x 3</strong> </div>
              {/* fifth row //////////////// */}
              <div className="grid-cell span-8"> <strong className="text-lg">1 x 8</strong> </div>
              <div className="grid-cell span-2"> <strong className="text-lg">1 x 2</strong> </div>
              {/* ninth row //////////// */}
              <div className="grid-cell span-9"> <strong className="text-lg">1 x 9</strong> </div>
              <div className="grid-cell"> <strong className="text-lg">1</strong> </div>
              {/* tenth row ////////////// */}
              <div className="grid-cell span-10"> <strong className="text-lg">1 x 10</strong> </div>
            </div>
        )
    }
}

export default ColSpans;