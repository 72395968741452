import React from "react";
import i18n from 'i18next';

class BaseTooltip extends React.Component {
    render(){
        return (
            <div className="lvl-1-container p-10 my-5 grid-of-4">
              <div className="tooltip-container group default my-6">
                <strong className="primary-text-color">{i18n.t('hover me')}</strong>
                <div className="tooltip group-hover:flex">
                  <span className="content">{i18n.t('tooltip content')}</span>
                  <div className="caret" />
                </div>
              </div>
              <div className="tooltip-container group amber my-6">
                <strong className="base-amber-text">{i18n.t('hover me')}</strong>
                <div className="tooltip group-hover:flex">
                  <span className="content">{i18n.t('tooltip content')}</span>
                  <div className="caret" />
                </div>
              </div>
              <div className="tooltip-container group blue my-6">
                <strong className="base-blue-text">{i18n.t('hover me')}</strong>
                <div className="tooltip group-hover:flex">
                  <span className="content">{i18n.t('tooltip content')}</span>
                  <div className="caret" />
                </div>
              </div>
              <div className="tooltip-container group cyan my-6">
                <strong className="base-cyan-text">{i18n.t('hover me')}</strong>
                <div className="tooltip group-hover:flex">
                  <span className="content">{i18n.t('tooltip content')}</span>
                  <div className="caret" />
                </div>
              </div>
              <div className="tooltip-container group emerald my-6">
                <strong className="base-emerald-text">{i18n.t('hover me')}</strong>
                <div className="tooltip group-hover:flex">
                  <span className="content">{i18n.t('tooltip content')}</span>
                  <div className="caret" />
                </div>
              </div>
              <div className="tooltip-container group gray my-6">
                <strong className="base-gray-text">{i18n.t('hover me')}</strong>
                <div className="tooltip group-hover:flex">
                  <span className="content">{i18n.t('tooltip content')}</span>
                  <div className="caret" />
                </div>
              </div>
              <div className="tooltip-container group green my-6">
                <strong className="base-green-text">{i18n.t('hover me')}</strong>
                <div className="tooltip group-hover:flex">
                  <span className="content">{i18n.t('tooltip content')}</span>
                  <div className="caret" />
                </div>
              </div>
              <div className="tooltip-container group lime my-6">
                <strong className="base-lime-text">{i18n.t('hover me')}</strong>
                <div className="tooltip group-hover:flex">
                  <span className="content">{i18n.t('tooltip content')}</span>
                  <div className="caret" />
                </div>
              </div>
              <div className="tooltip-container group orange my-6">
                <strong className="base-orange-text">{i18n.t('hover me')}</strong>
                <div className="tooltip group-hover:flex">
                  <span className="content">{i18n.t('tooltip content')}</span>
                  <div className="caret" />
                </div>
              </div>
              <div className="tooltip-container group pink my-6">
                <strong className="base-pink-text">{i18n.t('hover me')}</strong>
                <div className="tooltip group-hover:flex">
                  <span className="content">{i18n.t('tooltip content')}</span>
                  <div className="caret" />
                </div>
              </div>
              <div className="tooltip-container group purple my-6">
                <strong className="base-purple-text">{i18n.t('hover me')}</strong>
                <div className="tooltip group-hover:flex">
                  <span className="content">{i18n.t('tooltip content')}</span>
                  <div className="caret" />
                </div>
              </div>
              <div className="tooltip-container group sky my-6">
                <strong className="base-sky-text">{i18n.t('hover me')}</strong>
                <div className="tooltip group-hover:flex">
                  <span className="content">{i18n.t('tooltip content')}</span>
                  <div className="caret" />
                </div>
              </div>
              <div className="tooltip-container group teal my-6">
                <strong className="base-teal-text">{i18n.t('hover me')}</strong>
                <div className="tooltip group-hover:flex">
                  <span className="content">{i18n.t('tooltip content')}</span>
                  <div className="caret" />
                </div>
              </div>
              <div className="tooltip-container group yellow my-6">
                <strong className="base-yellow-text">{i18n.t('hover me')}</strong>
                <div className="tooltip group-hover:flex">
                  <span className="content">{i18n.t('tooltip content')}</span>
                  <div className="caret" />
                </div>
              </div>
              <div className="tooltip-container group default my-6">
                <strong className="primary-text-color">{i18n.t('hover me')}</strong>
                <div className="tooltip group-hover:flex">
                  <span className="content">{i18n.t('tooltip content')}</span>
                  <div className="caret" />
                </div>
              </div>
              <div className="tooltip-container group amber my-6">
                <strong className="base-amber-text">{i18n.t('hover me')}</strong>
                <div className="tooltip group-hover:flex">
                  <span className="content">{i18n.t('tooltip content')}</span>
                  <div className="caret" />
                </div>
              </div>
            </div>
        )
    }
}

export default BaseTooltip;