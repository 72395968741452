import React, { useEffect } from 'react';
import lazyLoadImages from './assets/js/LazyLoadImages';

function App(props){
  
  useEffect(() => {
    setInterval(() => lazyLoadImages() ,500)
  }, []);

  return (
    <div>
      {props.children}
    </div>
  )
}

export default App;