import React from "react";

class GridOf9 extends React.Component {
    render(){
        return (
            <div className="grid-of-9 my-4">
                <div className="grid-cell"> <strong className="text-lg">1</strong> </div>
                <div className="grid-cell"> <strong className="text-lg">2</strong> </div>
                <div className="grid-cell"> <strong className="text-lg">3</strong> </div>
                <div className="grid-cell"> <strong className="text-lg">4</strong> </div>
                <div className="grid-cell"> <strong className="text-lg">5</strong> </div>
                <div className="grid-cell"> <strong className="text-lg">6</strong> </div>
                <div className="grid-cell"> <strong className="text-lg">7</strong> </div>
                <div className="grid-cell"> <strong className="text-lg">8</strong> </div>
                <div className="grid-cell"> <strong className="text-lg">9</strong> </div>
            </div>
        )
    }
}

export default GridOf9;