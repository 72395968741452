import React from "react";

class LanguageAlert extends React.Component {
  /**
   * switch language 
   * @param {string} lang 
   */
  changeLanguage(lang){
    localStorage.setItem('activeLocale' ,lang)
    window.location.reload()
  }
  render(){
    return (
      <div>
        <div className="bordered-alert default my-4 hidden-in-rtl" role="alert">
          <p className="font-bold flex items-center">
            <span className="flex-1 text-lg">A full arabic version is available!</span>
            <button className="button-3d default lg" onClick={() => this.changeLanguage('ar')}>See now !</button>
          </p>
        </div>
        <div className="bordered-alert default my-4 hidden-in-ltr" role="alert">
          <p className="font-bold flex items-center">
            <span className="flex-1 text-lg">تتوفر نسخة إنجليزية بالكامل من قالب لوحة !</span>
            <button className="button-3d default lg" onClick={() => this.changeLanguage('en')}>جرب الآن !</button>
          </p>
        </div>
      </div>
    )
  }
}

export default LanguageAlert;