import React from "react";
import UserProfileCard from "../components/cards/UserProfileCard";
import HorizontalCard from "../components/cards/HorizontalCard";
import i18n from "i18next";
import MainLayout from "../layouts/Main";
import { Helmet } from "react-helmet";

class ProfilePage extends React.Component {
  render() {
    return (
      <MainLayout>
        <Helmet>
          <title>{i18n.t('profile')}</title>
        </Helmet>
        <div className="profile">
          <div className="cover-container">
            <img src="https://via.placeholder.com/360" alt="cover image" />
          </div>
          <div className="profile-grid">
            {/* name, email & buttons ////////////////// */}
            <UserProfileCard />
  
            {/* bio //////////////////////////////// */}
            <div className="lvl-1-container lg:col-span-2 p-4">
              <h2 className="text-lg font-bold">{i18n.t('bio')}</h2>
              <hr />
              <p>{i18n.t('bio example')}</p>
            </div>
            {/* skills ////////////////////// */}
            <div className="lvl-1-container p-4">
              <h2 className="text-lg font-bold">{i18n.t('skills')}</h2>
              <hr />
              <div className="flex gap-2 flex-wrap">
                <span className="simple-button primary-bg-color">Project managment</span>
                <span className="simple-button primary-bg-color">Excel</span>
                <span className="simple-button primary-bg-color">SEO</span>
                <span className="simple-button primary-bg-color">Agile</span>
                <span className="simple-button primary-bg-color">user story mapping</span>
              </div>
            </div>
            {/* latest articles //////////////// */}
            <div className="lvl-container lg:col-span-4 p-4">
              <h2 className="text-lg font-bold">{i18n.t('my articles')}</h2>
              <hr />
              <div className="grid-of-2">
                <HorizontalCard />
                <HorizontalCard />
                <HorizontalCard />
                <HorizontalCard />                
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    );
  }
}

export default ProfilePage;