import React from "react";
import i18n from 'i18next';

class BaseCard extends React.Component {
  render(){
    return (
        <div className="card-1">
          <img className="w-full lazy" data-src="https://via.placeholder.com/300x120" alt="cover" />
          <div className="px-6 py-4">
            <div className="font-bold text-xl mb-2">{i18n.t('card title example')}</div>
            <p className="text-base">{i18n.t('card content example')}</p>
          </div>
          <div className="tags">
            <span>{i18n.t('tag')}</span>
            <span>{i18n.t('tag')}</span>
            <span>{i18n.t('tag')}</span>
          </div>
        </div>
    )
  }
}

export default BaseCard;