import React from "react";
import i18n from 'i18next';

class TitledAlerts extends React.Component {
  render(){
    return (
      <div className="grid-of-3">
        <div role="alert" className="titled-alert default">
          <div className="title">{i18n.t('default')}</div>
          <div className="content">
            <p>{i18n.t('alert example')}</p>
          </div>
        </div>
        <div role="alert" className="titled-alert amber">
          <div className="title">{i18n.t('amber')}</div>
          <div className="content">
            <p>{i18n.t('alert example')}</p>
          </div>
        </div>
        <div role="alert" className="titled-alert blue">
          <div className="title">{i18n.t('blue')}</div>
          <div className="content">
            <p>{i18n.t('alert example')}</p>
          </div>
        </div>
        <div role="alert" className="titled-alert cyan">
          <div className="title">{i18n.t('cyan')}</div>
          <div className="content">
            <p>{i18n.t('alert example')}</p>
          </div>
        </div>
        <div role="alert" className="titled-alert emerald">
          <div className="title">{i18n.t('emerald')}</div>
          <div className="content">
            <p>{i18n.t('alert example')}</p>
          </div>
        </div>
        <div role="alert" className="titled-alert gray">
          <div className="title">{i18n.t('gray')}</div>
          <div className="content">
            <p>{i18n.t('alert example')}</p>
          </div>
        </div>
        <div role="alert" className="titled-alert green">
          <div className="title">{i18n.t('green')}</div>
          <div className="content">
            <p>{i18n.t('alert example')}</p>
          </div>
        </div>
        <div role="alert" className="titled-alert lime">
          <div className="title">{i18n.t('lime')}</div>
          <div className="content">
            <p>{i18n.t('alert example')}</p>
          </div>
        </div>
        <div role="alert" className="titled-alert orange">
          <div className="title">{i18n.t('orange')}</div>
          <div className="content">
            <p>{i18n.t('alert example')}</p>
          </div>
        </div>
        <div role="alert" className="titled-alert pink">
          <div className="title">{i18n.t('pink')}</div>
          <div className="content">
            <p>{i18n.t('alert example')}</p>
          </div>
        </div>
        <div role="alert" className="titled-alert purple">
          <div className="title">{i18n.t('purple')}</div>
          <div className="content">
            <p>{i18n.t('alert example')}</p>
          </div>
        </div>
        <div role="alert" className="titled-alert red">
          <div className="title">{i18n.t('red')}</div>
          <div className="content">
            <p>{i18n.t('alert example')}</p>
          </div>
        </div>
        <div role="alert" className="titled-alert sky">
          <div className="title">{i18n.t('sky')}</div>
          <div className="content">
            <p>{i18n.t('alert example')}</p>
          </div>
        </div>
        <div role="alert" className="titled-alert teal">
          <div className="title">{i18n.t('teal')}</div>
          <div className="content">
            <p>{i18n.t('alert example')}</p>
          </div>
        </div>
        <div role="alert" className="titled-alert yellow">
          <div className="title">{i18n.t('yellow')}</div>
          <div className="content">
            <p>{i18n.t('alert example')}</p>
          </div>
        </div>
      </div>
    )
  }
}

export default TitledAlerts;