import React from "react";
import { Helmet } from "react-helmet";
import FileManagerApp from "../components/applications/FileManagerApp";
import MainLayout from "../layouts/Main";
import i18n from 'i18next';

class FileManagerPage extends React.Component {
    render () {
        return (
            <MainLayout>
                <Helmet>
                    <title>{i18n.t('file manager')}</title>
                </Helmet>
                <FileManagerApp />
            </MainLayout>
        )
    }
}

export default FileManagerPage;