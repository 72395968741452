import React from "react";
import i18n from 'i18next';

class SimpleAlerts extends React.Component {
  render(){
    return (
      <div className="grid-of-3">
        <div className="simple-alert default" role="alert">
          <strong className="font-bold">{i18n.t('default')}</strong>
          <span className="block sm:inline">{i18n.t('alert example')}</span>
          <button name="button" onclick="this.parentNode.remove()" className="dismiss">
            <span>
              <svg className="fill-current h-6 w-6" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" /></svg>
            </span>
          </button>
        </div>
        <div className="simple-alert amber" role="alert">
          <strong className="font-bold">{i18n.t('amber')}</strong>
          <span className="block sm:inline">{i18n.t('alert example')}</span>
          <button name="button" onclick="this.parentNode.remove()" className="dismiss">
            <span>
              <svg className="fill-current h-6 w-6" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" /></svg>
            </span>
          </button>
        </div>
        <div className="simple-alert blue" role="alert">
          <strong className="font-bold">{i18n.t('blue')}</strong>
          <span className="block sm:inline">{i18n.t('alert example')}</span>
          <button name="button" onclick="this.parentNode.remove()" className="dismiss">
            <span>
              <svg className="fill-current h-6 w-6" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" /></svg>
            </span>
          </button>
        </div>
        <div className="simple-alert cyan" role="alert">
          <strong className="font-bold">{i18n.t('cyan')}</strong>
          <span className="block sm:inline">{i18n.t('alert example')}</span>
          <button name="button" onclick="this.parentNode.remove()" className="dismiss">
            <span>
              <svg className="fill-current h-6 w-6" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" /></svg>
            </span>
          </button>
        </div>
        <div className="simple-alert emerald" role="alert">
          <strong className="font-bold">{i18n.t('emerald')}</strong>
          <span className="block sm:inline">{i18n.t('alert example')}</span>
          <button name="button" onclick="this.parentNode.remove()" className="dismiss">
            <span>
              <svg className="fill-current h-6 w-6" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" /></svg>
            </span>
          </button>
        </div>
        <div className="simple-alert gray" role="alert">
          <strong className="font-bold">{i18n.t('gray')}</strong>
          <span className="block sm:inline">{i18n.t('alert example')}</span>
          <button name="button" onclick="this.parentNode.remove()" className="dismiss">
            <span>
              <svg className="fill-current h-6 w-6" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" /></svg>
            </span>
          </button>
        </div>
        <div className="simple-alert green" role="alert">
          <strong className="font-bold">{i18n.t('green')}</strong>
          <span className="block sm:inline">{i18n.t('alert example')}</span>
          <button name="button" onclick="this.parentNode.remove()" className="dismiss">
            <span>
              <svg className="fill-current h-6 w-6" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" /></svg>
            </span>
          </button>
        </div>
        <div className="simple-alert lime" role="alert">
          <strong className="font-bold">{i18n.t('lime')}</strong>
          <span className="block sm:inline">{i18n.t('alert example')}</span>
          <button name="button" onclick="this.parentNode.remove()" className="dismiss">
            <span>
              <svg className="fill-current h-6 w-6" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" /></svg>
            </span>
          </button>
        </div>
        <div className="simple-alert orange" role="alert">
          <strong className="font-bold">{i18n.t('orange')}</strong>
          <span className="block sm:inline">{i18n.t('alert example')}</span>
          <button name="button" onclick="this.parentNode.remove()" className="dismiss">
            <span>
              <svg className="fill-current h-6 w-6" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" /></svg>
            </span>
          </button>
        </div>
        <div className="simple-alert pink" role="alert">
          <strong className="font-bold">{i18n.t('pink')}</strong>
          <span className="block sm:inline">{i18n.t('alert example')}</span>
          <button name="button" onclick="this.parentNode.remove()" className="dismiss">
            <span>
              <svg className="fill-current h-6 w-6" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" /></svg>
            </span>
          </button>
        </div>
        <div className="simple-alert purple" role="alert">
          <strong className="font-bold">{i18n.t('purple')}</strong>
          <span className="block sm:inline">{i18n.t('alert example')}</span>
          <button name="button" onclick="this.parentNode.remove()" className="dismiss">
            <span>
              <svg className="fill-current h-6 w-6" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" /></svg>
            </span>
          </button>
        </div>
        <div className="simple-alert red" role="alert">
          <strong className="font-bold">{i18n.t('red')}</strong>
          <span className="block sm:inline">{i18n.t('alert example')}</span>
          <button name="button" onclick="this.parentNode.remove()" className="dismiss">
            <span>
              <svg className="fill-current h-6 w-6" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" /></svg>
            </span>
          </button>
        </div>
        <div className="simple-alert sky" role="alert">
          <strong className="font-bold">{i18n.t('sky')}</strong>
          <span className="block sm:inline">{i18n.t('alert example')}</span>
          <button name="button" onclick="this.parentNode.remove()" className="dismiss">
            <span>
              <svg className="fill-current h-6 w-6" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" /></svg>
            </span>
          </button>
        </div>
        <div className="simple-alert teal" role="alert">
          <strong className="font-bold">{i18n.t('teal')}</strong>
          <span className="block sm:inline">{i18n.t('alert example')}</span>
          <button name="button" onclick="this.parentNode.remove()" className="dismiss">
            <span>
              <svg className="fill-current h-6 w-6" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" /></svg>
            </span>
          </button>
        </div>
        <div className="simple-alert yellow" role="alert">
          <strong className="font-bold">{i18n.t('yellow')}</strong>
          <span className="block sm:inline">{i18n.t('alert example')}</span>
          <button name="button" onclick="this.parentNode.remove()" className="dismiss">
            <span>
              <svg className="fill-current h-6 w-6" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" /></svg>
            </span>
          </button>
        </div>
      </div> 
    )
  }
}

export default SimpleAlerts;