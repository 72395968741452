import React from "react";
import i18n from 'i18next';

class BorderColors extends React.Component {
    render(){
        return (
            <div className="lvl-1-container">
              <div className="my-4 flex"><h6 className="font-lg">{i18n.t('border colors')}</h6></div>
              <hr />
              <div className="grid-of-2 gap-2">
                <div className="p-4 base-lime-border border-2">{i18n.t("that's a border example")}</div>
                <div className="p-4 base-amber-border border-2">{i18n.t("that's a border example")}</div>
                <div className="p-4 base-blue-border border-2">{i18n.t("that's a border example")}</div>
                <div className="p-4 base-cyan-border border-2">{i18n.t("that's a border example")}</div>
                <div className="p-4 base-emerald-border border-2">{i18n.t("that's a border example")}</div>
                <div className="p-4 base-gray-border border-2">{i18n.t("that's a border example")}</div>
                <div className="p-4 base-green-border border-2">{i18n.t("that's a border example")}</div>
                <div className="p-4 base-orange-border border-2">{i18n.t("that's a border example")}</div>
                <div className="p-4 base-pink-border border-2">{i18n.t("that's a border example")}</div>
                <div className="p-4 base-purple-border border-2">{i18n.t("that's a border example")}</div>
                <div className="p-4 base-red-border border-2">{i18n.t("that's a border example")}</div>
                <div className="p-4 base-sky-border border-2">{i18n.t("that's a border example")}</div>
                <div className="p-4 base-teal-border border-2">{i18n.t("that's a border example")}</div>
                <div className="p-4 base-yellow-border border-2">{i18n.t("that's a border example")}</div>
                <div className="p-4 primary-border-color border-2">{i18n.t("that's a border example")}</div>
              </div>
            </div>
        )
    }
}

export default BorderColors;