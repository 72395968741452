import React from "react";
import i18n from 'i18next';

class Conatiners extends React.Component {
    render(){
        return (
            <div className="lvl-1-container">
              <div className="my-4 flex"><h6 className="font-lg">{i18n.t("containers")}</h6></div>
              <hr />
              <h2 className="font-bold text-lg">{i18n.t("That's a level one container")}</h2>
              <div className="lvl-2-container mt-5">
                <h2 className="font-bold text-lg">{i18n.t("That's a level two container")}</h2>
              </div>
            </div>
        )
    }
}

export default Conatiners;