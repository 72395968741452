import ApexCharts from "apexcharts";
window.ApexCharts = ApexCharts

/**
  * global configuration object
*/
var global_config = require('./charts/global-config.json')

/**
 * any charts should be added to this array
 */
const charts = [
  { target: '#spark1', options: require('./charts/spark-1.json') /* replace with json object*/ },
  { target: '#spark2', options: require('./charts/spark-2.json') /* replace with json object*/ },
  { target: '#spark3', options: require('./charts/spark-3.json') /* replace with json object*/ },
  { target: '#spark4', options: require('./charts/spark-4.json') /* replace with json object*/ },
  { target: '#line-adwords', options: require('./charts/line.json') /* replace with json object*/ },
  { target: '#radialBarBottom', options: require('./charts/radialbar.json') /* replace with json object*/ },
  { target: '#barchart', options: require('./charts/bar.json') /* replace with json object*/ },
  { target: '#areachart', options: require('./charts/area.json') /* replace with json object*/ },
  { target: '#negative-area', options: require('./charts/negative-area.json') /* replace with json object*/ },
  { target: '#basic-bar', options: require('./charts/basic-bar.json') /* replace with json object*/ },
  { target: '#stacked-bar', options: require('./charts/stacked-bar.json')  /* replace with json object*/},
  { target: '#mixed1', options: require('./charts/mixed-1.json') /* replace with json object*/ },
  { target: '#mixed2', options: require('./charts/mixed-2.json') /* replace with json object*/ },
  { target: '#candlestick', options: require('./charts/candlestick.json') /* replace with json object*/ },
  { target: '#treemap', options: require('./charts/treemap.json') /* replace with json object*/ }
]


/**
 * handle charts rendering
 * 
 * install colors as global configuration
 * empty chart contains & render charts
 * 
 * @return void
*/
const renderCharts = function ()
{
  global_config.colors = window.main_colors;
  window.Apex = global_config;
  
  charts.map(chart => {
    let target = document.querySelector(chart.target)
    if(!! target){
      target.innerHTML = ''

      return new ApexCharts(target, chart.options).render()
    }
  })
}

export default renderCharts;