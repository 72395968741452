import React from "react";
import i18n from 'i18next';
import BorderedAlerts from "../components/alerts/BorderedAlerts";
import TitledAlerts from "../components/alerts/TitledAlerts";
import SimpleAlerts from "../components/alerts/SimpleAlerts";
import PopupAlerts from "../components/alerts/PopupAlerts";
import MainLayout from "../layouts/Main";
import { Helmet } from "react-helmet";

class AlertsPage extends React.Component {
  render(){
    return (
      <MainLayout>
        <Helmet>
          <title>{i18n.t('alerts')}</title>
        </Helmet>
        <div className="grid-of-1 my-4">
        {/* standard alerts ////////////////////////////*/}
        <div className="lvl-1-container p-4">
          <h2>{i18n.t('standard')}</h2>
          <BorderedAlerts className="mt-2" />
        </div>

        {/* tilted alerts ////////////////////////// */}
        <div className="lvl-1-container p-4">
          <h2>{i18n.t('titled')}</h2>
          <TitledAlerts className="mt-2" />
        </div>

        {/* simple alerts //////////////////////////*/}
        <div className="lvl-1-container p-4">
          <h2>{i18n.t('simple')}</h2>
          <SimpleAlerts className="mt-2" />
        </div>

        {/* popup alerts ///////////////////////////*/}
        <div className="lvl-1-container p-4">
          <h2>{i18n.t('popup')}</h2>
          <div className="flex flex-col gap-2 mt-2"> 
            <PopupAlerts />
          </div>
        </div>
      </div>
      </MainLayout>
    )
  }
}

export default AlertsPage;