import React from "react";

class DotterPreloadersGroup extends React.Component {
    render(){
        return (
            <div className="flex flex-wrap gap-7 my-2">
                <div className="preloader spinner sm default dotted"></div>
                <div className="preloader spinner sm amber dotted"></div>
                <div className="preloader spinner sm blue dotted"></div>
                <div className="preloader spinner sm cyan dotted"></div>
                <div className="preloader spinner sm emerald dotted"></div>
                <div className="preloader spinner sm gray dotted"></div>
                <div className="preloader spinner sm green dotted"></div>
                <div className="preloader spinner sm lime dotted"></div>
                <div className="preloader spinner sm orange dotted"></div>
                <div className="preloader spinner sm pink dotted"></div>
                <div className="preloader spinner sm purple dotted"></div>
                <div className="preloader spinner sm red dotted"></div>
                <div className="preloader spinner sm sky dotted"></div>
                <div className="preloader spinner sm teal dotted"></div>
                <div className="preloader spinner sm yellow dotted"></div>
            </div>
        )
    }
}

export default DotterPreloadersGroup;