import React from "react";
import logo from '../../assets/images/logo.png'
import logoLight from '../../assets/images/logo-light.png'
import frenchLogo from '../../assets/images/fr.jpg'
import englishLogo from '../../assets/images/en.jpg'
import arabicLogo from '../../assets/images/ar.jpg'
import i18n from 'i18next';
import { Link } from "react-router-dom";

class Navbar extends React.Component {
  render(){
    return (
      <div className="navbar-container">
        <div className="navbar c-navigation-bar">
          {/* logo */}
          <Link to={`/`} className="hidden lg:block logo-container">
            <img height="80px" className="dark:hidden" src={logo} alt="Lawha" />
            <img height="80px" className="hidden dark:block" src={logoLight} alt="Lawha" />
          </Link>
      
          {/* search */}
          <div className="hidden lg:block form-element iconed">
            <div>
                <i>
                  <svg className="svg-sm primary-text-color"  width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <circle cx="10" cy="10" r="7" />  <line x1="21" y1="21" x2="15" y2="15" /></svg>
                </i>
                <input type="text" placeholder={i18n.t('search')} />
            </div>
          </div>
      
          {/* sidebar toggler */}
          <div className="sidebar-toggler">
            <button aria-label="sidebar-toggler" onClick={() => window.Lawha.toggleItem('#sidebar')}>
                <svg className="svg-sm"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"/>
                </svg>
            </button>
          </div>
            
          <div className="flex-1"></div>
      
          {/* theme switcher */}
          <div>
            <button aria-label="theme-toggler" className="theme-switcher" onClick={() => window.Lawha.switchMode(true)}>
                <svg className="svg-sm sun"  width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <circle cx="12" cy="12" r="4" />  <path d="M3 12h1M12 3v1M20 12h1M12 20v1M5.6 5.6l.7 .7M18.4 5.6l-.7 .7M17.7 17.7l.7 .7M6.3 17.7l-.7 .7" /></svg>
                <svg className="svg-sm moon mr-auto"  width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M16.2 4a9.03 9.03 0 1 0 3.9 12a6.5 6.5 0 1 1 -3.9 -12" /></svg>
            </button>
          </div>
            
          {/* language dropdown menu */}
          <div className="dropdown">
            <button className="lvl-2-container" aria-label="language-toggler" onClick={() => window.Lawha.toggleItem('#language-list')}>
                <svg className="svg-sm"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 5h12M9 3v2m1.048 9.5A18.022 18.022 0 016.412 9m6.088 9h7M11 21l5-10 5 10M12.751 5C11.783 10.77 8.07 15.61 3 18.129"/>
                </svg>
            </button>
              
            {/* languages list */}
            <div id="language-list" className="dropdown-menu hidden" style={{ width:8+'rem' }}>
                <div className="languages-dropdown">
                    <a href="#">
                      <img className="flag" src={frenchLogo} alt="french" />
                      <span>{i18n.t('french')}</span>
                    </a>
      
                    <a href="#">
                      <img className="flag" src={englishLogo} alt="english" />
                      <span>{i18n.t('english')}</span>
                    </a>
      
                    <a href="#">
                      <img className="flag" src={arabicLogo} alt="arabic" />
                      <span>{i18n.t('arabic')}</span>
                    </a>
                </div>
            </div>
          </div>
      
          {/* messages */}
          <div className="dropdown">
            <button className="lvl-2-container" aria-label="messages" onClick={() => window.Lawha.toggleItem('#messages-list')}>
                <svg className="svg-sm"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="2"  strokeLinecap="round"  strokeLinejoin="round">  <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z" /></svg>
            </button>
            <span className="nav-badge"></span>  
      
            {/* messages list */}
            <div id="messages-list" className="dropdown-menu hidden contacts" style={{ width:18+'rem' }}>
                
                {/* contacts */}
                <div className="items-dropdown">
                    {(() => {
                      let contacts = []
                      for (let index = 0; index < 5; index++) {
                        contacts.push(
                          <a className="contact" key={index}>
                            <img className="lazy" data-src="https://via.placeholder.com/40" alt="username" />
                            <div className="w-full pb-2">
                              <div className="flex justify-between">
                                <span className="block ml-2 font-semibold">{i18n.t('jhon doe')}</span>
                                <span className="block ml-2 text-sm">{i18n.t('seconds ago')}</span>
                              </div>
                              <span className="block ml-2 text-sm">{i18n.t('message example')}</span>
                            </div>
                          </a>
                        )
                      }
                      return contacts;
                    })()}
                </div>
                
                <Link to={`/messenger`} className="all-notifs-link">{i18n.t('all messages')}</Link>
            </div>
          </div>
        
            {/* notification dropdown */}
            <div className="dropdown">
              <button className="lvl-2-container" aria-label="notifications" onClick={ () => window.Lawha.toggleItem('#notification-list') }>
                  <svg className="svg-sm"  width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M10 5a2 2 0 0 1 4 0a7 7 0 0 1 4 6v3a4 4 0 0 0 2 3h-16a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6" />  <path d="M9 17v1a3 3 0 0 0 6 0v-1" /></svg>
              </button>
              <span className="nav-badge"></span>
              
              {/* notifications list */}
              <div id="notification-list" className="dropdown-menu hidden" style={{ width:18+'rem' }}>
                  <div className="items-dropdown">
                    {(() => {
                      let notifications = []
                      for (let index = 0; index < 5; index++) {                        
                        notifications.push(
                          <a href="#" key={index} className="notification-item">
                            <img className="lazy" data-src="https://via.placeholder.com/40" alt="avatar" />
                            <p>
                              <span className="block ml-2 font-semibold">{i18n.t('jhon doe')}</span>
                              <span className="block ml-2 text-sm">{i18n.t('notification example')}</span>
                              <span className="ml-2 text-sm">{i18n.t('seconds ago')}</span>
                            </p>
                          </a>
                        )
                      }
                      return notifications;
                    })()}
                  </div>
                  <Link to={`/notifications`} className="all-notifs-link">{i18n.t('all notifications')}</Link>
              </div>
            </div>
        </div>
      </div>
    );
  }
}

export default Navbar;