import React from "react";
import i18n from 'i18next';

class ButtonColors extends React.Component {
    render(){
        return (
            <div>
               {/* 3d colors //////////////////////////// */}
               <div className="flex flex-wrap gap-2">
                 <button name="button" className="button-3d default">{i18n.t('default')}</button>
                 <button name="button" className="button-3d amber">{i18n.t('amber')}</button>
                 <button name="button" className="button-3d blue">{i18n.t('blue')}</button>
                 <button name="button" className="button-3d cyan">{i18n.t('cyan')}</button>
                 <button name="button" className="button-3d emerald">{i18n.t('emerald')}</button>
                 <button name="button" className="button-3d gray">{i18n.t('gray')}</button>
                 <button name="button" className="button-3d green flex-1">{i18n.t('green')}</button>
                 <button name="button" className="button-3d lime">{i18n.t('lime')}</button>
                 <button name="button" className="button-3d orange">{i18n.t('orange')}</button>
                 <button name="button" className="button-3d pink">{i18n.t('pink')}</button>
                 <button name="button" className="button-3d purple">{i18n.t('purple')}</button>
                 <button name="button" className="button-3d red">{i18n.t('red')}</button>
                 <button name="button" className="button-3d sky">{i18n.t('sky')}</button>
                 <button name="button" className="button-3d teal">{i18n.t('teal')}</button>
                 <button name="button" className="button-3d yellow">{i18n.t('yellow')}</button>
               </div>
               {/* simple buttons colors //////////////////////// */}
               <div className="flex flex-wrap gap-2 my-1">
                 <button name="button" className="simple-button default flex-1">{i18n.t('default')}</button>
                 <button name="button" className="simple-button amber">{i18n.t('amber')}</button>
                 <button name="button" className="simple-button blue">{i18n.t('blue')}</button>
                 <button name="button" className="simple-button cyan">{i18n.t('cyan')}</button>
                 <button name="button" className="simple-button emerald">{i18n.t('emerald')}</button>
                 <button name="button" className="simple-button gray">{i18n.t('gray')}</button>
                 <button name="button" className="simple-button green">{i18n.t('green')}</button>
                 <button name="button" className="simple-button lime">{i18n.t('lime')}</button>
                 <button name="button" className="simple-button orange">{i18n.t('orange')}</button>
                 <button name="button" className="simple-button pink">{i18n.t('pink')}</button>
                 <button name="button" className="simple-button purple">{i18n.t('purple')}</button>
                 <button name="button" className="simple-button red">{i18n.t('red')}</button>
                 <button name="button" className="simple-button sky">{i18n.t('sky')}</button>
                 <button name="button" className="simple-button teal">{i18n.t('teal')}</button>
                 <button name="button" className="simple-button yellow">{i18n.t('yellow')}</button>
               </div>
               {/* bordered colors ////////////////// */}
               <div className="flex flex-wrap gap-2 my-1">
                 <button name="button" className="outlined-button default">{i18n.t('default')}</button>
                 <button name="button" className="outlined-button amber">{i18n.t('amber')}</button>
                 <button name="button" className="outlined-button blue">{i18n.t('blue')}</button>
                 <button name="button" className="outlined-button cyan">{i18n.t('cyan')}</button>
                 <button name="button" className="outlined-button emerald">{i18n.t('emerald')}</button>
                 <button name="button" className="outlined-button gray">{i18n.t('gray')}</button>
                 <button name="button" className="outlined-button green">{i18n.t('green')}</button>
                 <button name="button" className="outlined-button lime">{i18n.t('lime')}</button>
                 <button name="button" className="outlined-button orange">{i18n.t('orange')}</button>
                 <button name="button" className="outlined-button pink">{i18n.t('pink')}</button>
                 <button name="button" className="outlined-button purple">{i18n.t('purple')}</button>
                 <button name="button" className="outlined-button red">{i18n.t('red')}</button>
                 <button name="button" className="outlined-button sky">{i18n.t('sky')}</button>
                 <button name="button" className="outlined-button teal">{i18n.t('teal')}</button>
                 <button name="button" className="outlined-button yellow flex-1">{i18n.t('yellow')}</button>
               </div>
            </div>
        )
    }
}

export default ButtonColors;