import React from "react";

class DashedPreloadersGroup extends React.Component {
    render(){
        return (
            <div className="flex flex-wrap gap-7 my-2">
                <div className="preloader spinner sm default dashed"></div>
                <div className="preloader spinner sm amber dashed"></div>
                <div className="preloader spinner sm blue dashed"></div>
                <div className="preloader spinner sm cyan dashed"></div>
                <div className="preloader spinner sm emerald dashed"></div>
                <div className="preloader spinner sm gray dashed"></div>
                <div className="preloader spinner sm green dashed"></div>
                <div className="preloader spinner sm lime dashed"></div>
                <div className="preloader spinner sm orange dashed"></div>
                <div className="preloader spinner sm pink dashed"></div>
                <div className="preloader spinner sm purple dashed"></div>
                <div className="preloader spinner sm red dashed"></div>
                <div className="preloader spinner sm sky dashed"></div>
                <div className="preloader spinner sm teal dashed"></div>
                <div className="preloader spinner sm yellow dashed"></div>
            </div>
        )
    }
}

export default DashedPreloadersGroup;