import React from "react";
import i18n from 'i18next';

class BgColors extends React.Component {
    render(){
        return (
            <div className="lvl-1-container">
              <div className="my-4 flex"><h6 className="font-lg">{i18n.t('background colors')}</h6></div>
              <hr />
              <div className="flex flex-col gap-2">
                <div className="p-4 base-lime-bg" />
                <div className="p-4 base-amber-bg" />
                <div className="p-4 base-blue-bg" />
                <div className="p-4 base-cyan-bg" />
                <div className="p-4 base-emerald-bg" />
                <div className="p-4 base-gray-bg" />
                <div className="p-4 base-green-bg" />
                <div className="p-4 base-orange-bg" />
                <div className="p-4 base-pink-bg" />
                <div className="p-4 base-purple-bg" />
                <div className="p-4 base-red-bg" />
                <div className="p-4 base-sky-bg" />
                <div className="p-4 base-teal-bg" />
                <div className="p-4 base-yellow-bg" />
                <div className="p-4 primary-bg-color" />
              </div>
            </div>

        )
    }
}

export default BgColors;