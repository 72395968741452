import React from "react";
import i18n from 'i18next';
import BasePagination from "../components/pagination/BasePagination";
import PaginationColors from "../components/pagination/PaginationColors";
import BorderRadiusPagination from "../components/pagination/BorderRadiusPagination";
import MainLayout from "../layouts/Main";
import { Helmet } from "react-helmet";

class PaginationPage extends React.Component {
    render(){
        return (
            <MainLayout>
              <Helmet>
                <title>{i18n.t('pagination')}</title>
              </Helmet>
              <div className="grid-of-3">
              {/* pagination base //////////// */}
              <div className="lvl-1-container">
                <div className="my-4 flex">
                  <h6 className="font-lg">{i18n.t('base')}</h6>
                </div>
                <hr />
                <BasePagination />
              </div>
              {/* pagination colors //////////// */}
              <div className="lvl-1-container">
                <div className="my-4 flex">
                  <h6 className="font-lg">{i18n.t('colors')}</h6>
                </div>
                <hr />
                <PaginationColors />
              </div>
              {/* border radius //////////// */}
              <div className="lvl-1-container">
                <div className="my-4 flex">
                  <h6 className="font-lg">{i18n.t('border radius')}</h6>
                </div>
                <hr />
                <BorderRadiusPagination />
              </div>            
            </div>
            </MainLayout>
        )
    }
}

export default PaginationPage;