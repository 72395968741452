import React from "react";
import i18n from 'i18next';

class PopupAlerts extends React.Component {
    render(){
        return (
            <div className="flex flex-wrap gap-2">
              {/* fail alert ///////////////////// */}
              <button onClick={() => window.Lawha.fireAlert('fail' ,i18n.t('alert title example'),i18n.t('alert example'))} name="button" className="button-3d red">{i18n.t('fail alert')}</button>
              {/* success alert /////////////////////// */}
              <button onClick={() => window.Lawha.fireAlert('success' ,i18n.t('alert title example'),i18n.t('alert example'))} name="button" className="button-3d green">{i18n.t('success alert')}</button>
              {/* info alert /////////////////////// */}
              <button onClick={() => window.Lawha.fireAlert('info' ,i18n.t('alert title example'),i18n.t('alert example'))} name="button" className="button-3d gray">{i18n.t('info alert')}</button>
            </div>
        )
    }
}

export default PopupAlerts;