import React from "react";
import i18n from 'i18next';
import SimpleButtons from "../components/buttons/SimpleButtons";
import OutlinedButtons from "../components/buttons/OutlinedButtons";
import ThreeDButtons from "../components/buttons/3dButtons";
import RoundedButtons from "../components/buttons/RoundedButtons";
import ButtonSizes from "../components/buttons/ButtonSizes";
import IconedButtons from "../components/buttons/IconedButtons";
import ButtonColors from "../components/buttons/ButtonColors";
import MainLayout from "../layouts/Main";
import { Helmet } from "react-helmet";

class ButtonsPage extends React.Component {
    render(){
        return (
          <MainLayout>
            <Helmet>
              <title>{i18n.t('buttons')}</title>
            </Helmet>
            <div>
            <div className="grid-of-3 mt-4 py-4">
              {/* simple /////////////////////// */}
              <div className="lvl-1-container p-4">
                <div className="my-4 flex">
                  <h6 className="font-lg">{i18n.t('default')}</h6>
                </div>
                <SimpleButtons />
              </div>
              {/* outlined ////////////////////// */}
              <div className="lvl-1-container p-4">
                <div className="my-4 flex">
                  <h6 className="font-lg">{i18n.t('outlined')}</h6>
                </div>
                <OutlinedButtons />
              </div>
              {/* 3d buttons ////////////////////////// */}
              <div className="lvl-1-container p-4">
                <div className="my-4 flex">
                  <h6 className="font-lg">3D</h6>
                </div>
                <ThreeDButtons />
              </div>
              {/* rounded buttons ///////////////////// */}
              <div className="lvl-1-container p-4">
                <div className="my-4 flex">
                  <h6 className="font-lg">{i18n.t('rounded')}</h6>
                </div>
                <RoundedButtons />
              </div>
              {/* sizes ///////////////////////////////// */}
              <div className="lvl-1-container p-4">
                <div className="my-4 flex">
                  <h6 className="font-lg">{i18n.t('sizes')}</h6>
                </div>
                <ButtonSizes />
              </div>
              {/* iconed ////////////////////////////////// */}
              <div className="lvl-1-container p-4">
                <div className="my-4 flex">
                  <h6 className="font-lg">{i18n.t('iconed')}</h6>
                </div>
                <IconedButtons />
              </div>
            </div>
            <div className="grid-of-1 pt-2">
              {/* colors ///////////////////////////////// */}
              <div className="lvl-1-container p-4">
                <div className="my-4 flex">
                  <h6 className="font-lg">{i18n.t('colors')}</h6>
                </div>
                <ButtonColors />
              </div>
            </div>
          </div>
          </MainLayout>
        )
    }
}

export default ButtonsPage;