import React from "react";
import { Helmet } from "react-helmet";
import InboxApp from "../components/applications/InboxApp";
import MainLayout from "../layouts/Main";
import i18n from 'i18next';

class MailInboxPage extends React.Component {
  render(){
    return (
      <MainLayout>
        <Helmet>
          <title>{i18n.t('inbox')}</title>
        </Helmet>
        <InboxApp />
      </MainLayout>
    )
  }
}

export default MailInboxPage;