import React from "react";
import { Helmet } from "react-helmet";
import BaseTab from "../components/tabs/BaseTab";
import MainLayout from "../layouts/Main";
import i18n from 'i18next';

class TabsPage extends React.Component {
    render(){
        return (
            <MainLayout>
                <Helmet>
                    <title>{i18n.t('tabs')}</title>
                </Helmet>
                <BaseTab />
            </MainLayout>
        )
    }
}

export default TabsPage;