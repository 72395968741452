import React from "react";
import { Link } from "react-router-dom";
import i18n from 'i18next';

class SideBar extends React.Component {
    render() {
      return (
        <div id="sidebar" className="hidden lg:inline sidebar">
          {/* avatar ////////// */}
          <div className="avatar-container">
            <Link to={`/profile`}>
              <img className="primary-border-color" width="40px" height="40px" src="https://via.placeholder.com/40" data-attr-t alt-t="lawha admin" />
              <div className="name">
                <p>{i18n.t('jhon doe')}</p>
                <p>{i18n.t('lawha admin')}</p>
              </div>
            </Link>
          </div>
          <hr />
          {/* sidebar lists ////////// */}
          <ul>
            <li>
              <Link to={`/`}>
                <svg className="svg-sm" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <circle cx={6} cy={10} r={2} />  <line x1={6} y1={4} x2={6} y2={8} />  <line x1={6} y1={12} x2={6} y2={20} />  <circle cx={12} cy={16} r={2} />  <line x1={12} y1={4} x2={12} y2={14} />  <line x1={12} y1={18} x2={12} y2={20} />  <circle cx={18} cy={7} r={2} />  <line x1={18} y1={4} x2={18} y2={5} />  <line x1={18} y1={9} x2={18} y2={20} /></svg>
                <span>{i18n.t('dashboard')}</span>
              </Link>
            </li>
            <hr />
            <li onClick={() => window.Lawha.toggleItem("#ui-elements-dropdown")}>
              <a href="#">
                <svg className="svg-sm" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <rect x={3} y={15} width={6} height={6} rx={2} />  <rect x={15} y={15} width={6} height={6} rx={2} />  <rect x={9} y={3} width={6} height={6} rx={2} />  <path d="M6 15v-1a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v1" />  <line x1={12} y1={9} x2={12} y2={12} /></svg>
                <span>{i18n.t('ux elements')}</span>
              </a>
              <button aria-label="caret" className="caret">
                <svg className="svg-sm" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <polyline points="6 9 12 15 18 9" /></svg>
              </button>
              <ul className="hidden" id="ui-elements-dropdown">
                <li>
                  <Link to={`/tables`}>
                    <svg className="svg-sm" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <rect x={4} y={4} width={16} height={16} rx={2} />  <line x1={4} y1={12} x2={20} y2={12} />  <line x1={12} y1={4} x2={12} y2={20} /></svg>
                    <span>{i18n.t('tables')}</span>
                  </Link>
                </li>
                <li>
                  <Link to={`/alerts`}>
                    <svg className="svg-sm" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <circle cx={12} cy={12} r={9} />  <line x1={12} y1={8} x2={12} y2={12} />  <line x1={12} y1={16} x2="12.01" y2={16} /></svg>
                    <span>{i18n.t('alerts')}</span>
                  </Link>
                </li>
                <li>
                  <Link to={`/buttons`}>
                    <svg className="svg-sm" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <line x1={3} y1={12} x2={6} y2={12} />  <line x1={12} y1={3} x2={12} y2={6} />  <line x1="7.8" y1="7.8" x2="5.6" y2="5.6" />  <line x1="16.2" y1="7.8" x2="18.4" y2="5.6" />  <line x1="7.8" y1="16.2" x2="5.6" y2="18.4" />  <path d="M12 12l9 3l-4 2l-2 4l-3 -9" /></svg>
                    <span>{i18n.t('buttons')}</span>
                  </Link>
                </li>
                <li>
                  <Link to={`/forms`}>
                    <svg className="svg-sm" width={24} height={24} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">  <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />  <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" /></svg>
                    <span>{i18n.t('form')}</span>
                  </Link>
                </li>
                <li>
                  <Link to={`/modals`}>
                    <svg className="svg-sm" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <rect x={8} y={4} width={12} height={12} rx={2} />  <path d="M16 16v2a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2v-8a2 2 0 0 1 2 -2h2" /></svg>
                    <span>{i18n.t('popup modals')}</span>
                  </Link>
                </li>
                <li>
                  <Link to={`/preloaders`}>
                    <svg className="svg-sm" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">  <line x1={12} y1={2} x2={12} y2={6} />  <line x1={12} y1={18} x2={12} y2={22} />  <line x1="4.93" y1="4.93" x2="7.76" y2="7.76" />  <line x1="16.24" y1="16.24" x2="19.07" y2="19.07" />  <line x1={2} y1={12} x2={6} y2={12} />  <line x1={18} y1={12} x2={22} y2={12} />  <line x1="4.93" y1="19.07" x2="7.76" y2="16.24" />  <line x1="16.24" y1="7.76" x2="19.07" y2="4.93" /></svg>
                    <span>{i18n.t('preloaders')}</span>
                  </Link>
                </li>
                <li>
                  <Link to={`/tabs`}>
                    <svg className="svg-sm" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">  <rect x={3} y={3} width={7} height={7} />  <rect x={14} y={3} width={7} height={7} />  <rect x={14} y={14} width={7} height={7} />  <rect x={3} y={14} width={7} height={7} /></svg>
                    <span>{i18n.t('tabs')}</span>
                  </Link>
                </li>
                <li>
                  <Link to={`/cards`}>
                    <svg className="svg-sm" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <rect x={4} y={4} width={16} height={16} rx={2} />  <line x1={4} y1={9} x2={20} y2={9} /></svg>
                    <span>{i18n.t('cards')}</span>
                  </Link>
                </li>
                <li>
                  <Link to={`/tooltips`}>
                    <svg className="svg-sm" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    <span>{i18n.t('tooltips')}</span>
                  </Link>
                </li>
                <li>
                  <Link to={`/pagination`}>
                    <svg className="svg-sm" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <circle cx={5} cy={12} r={1} />  <circle cx={12} cy={12} r={1} />  <circle cx={19} cy={12} r={1} /></svg>
                    <span>{i18n.t('pagination')}</span>
                  </Link>
                </li>
              </ul>
            </li>
            <hr />
            <li onClick={() => window.Lawha.toggleItem("#apps-dropdown")}>
              <a href="#">
                <svg className="svg-sm" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <rect x={4} y={4} width={6} height={6} rx={1} />  <rect x={4} y={14} width={6} height={6} rx={1} />  <rect x={14} y={14} width={6} height={6} rx={1} />  <line x1={14} y1={7} x2={20} y2={7} />  <line x1={17} y1={4} x2={17} y2={10} /></svg>
                <span>{i18n.t('applications')}</span>
              </a>
              <button aria-label="caret" className="caret">
                <svg className="svg-sm" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <polyline points="6 9 12 15 18 9" /></svg>
              </button>
              <ul className="hidden" id="apps-dropdown">
                <li>
                  <Link to={`/gallery`} className="flex-1">
                    <svg className="svg-sm" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">  <rect x={3} y={3} width={18} height={18} rx={2} ry={2} />  <circle cx="8.5" cy="8.5" r="1.5" />  <polyline points="21 15 16 10 5 21" /></svg>
                    <span>{i18n.t('gallery')}</span> 
                  </Link>
                </li>
                <li>
                  <Link to={`/messenger`}>
                    <svg className="svg-sm" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">  <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z" /></svg>
                    <span>{i18n.t('messenger')}</span>
                  </Link>
                </li>
                <li>
                  <Link to={`/inbox`}>
                    <svg className="svg-sm" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">  <circle cx={12} cy={12} r={4} />  <path d="M16 8v5a3 3 0 0 0 6 0v-1a10 10 0 1 0-3.92 7.94" /></svg>
                    <span>{i18n.t('inbox')}</span>
                  </Link>
                </li>
                <li>
                  <Link to={`/file-manager`}>
                    <svg className="svg-sm" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">  <line x1={22} y1={12} x2={2} y2={12} />  <path d="M5.45 5.11L2 12v6a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-6l-3.45-6.89A2 2 0 0 0 16.76 4H7.24a2 2 0 0 0-1.79 1.11z" />  <line x1={6} y1={16} x2="6.01" y2={16} />  <line x1={10} y1={16} x2="10.01" y2={16} /></svg>
                    <span>{i18n.t('file manager')}</span>
                  </Link>
                </li>
                <li>
                  <Link to={`/calendar`}>
                    <svg className="svg-sm" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">  <rect x={3} y={4} width={18} height={18} rx={2} ry={2} />  <line x1={16} y1={2} x2={16} y2={6} />  <line x1={8} y1={2} x2={8} y2={6} />  <line x1={3} y1={10} x2={21} y2={10} /></svg>
                    <span>{i18n.t('calendar')}</span>
                  </Link>
                </li>
                <li>
                  <Link to={`/tasks-list`}>
                    <svg className="svg-sm" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <path d="M3.5 5.5l1.5 1.5l2.5 -2.5" />  <path d="M3.5 11.5l1.5 1.5l2.5 -2.5" />  <path d="M3.5 17.5l1.5 1.5l2.5 -2.5" />  <line x1={11} y1={6} x2={20} y2={6} />  <line x1={11} y1={12} x2={20} y2={12} />  <line x1={11} y1={18} x2={20} y2={18} /></svg>
                    <span>{i18n.t('tasks list')}</span>
                  </Link>
                </li>
              </ul>
            </li>
            <hr />
            <li onClick={() => window.Lawha.toggleItem('#pages-dropdown')}>
              <a href="#">
                <svg className="svg-sm" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <rect x={3} y={15} width={6} height={6} rx={2} />  <rect x={15} y={15} width={6} height={6} rx={2} />  <rect x={9} y={3} width={6} height={6} rx={2} />  <path d="M6 15v-1a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v1" />  <line x1={12} y1={9} x2={12} y2={12} /></svg>
                <span>{i18n.t('pages')}</span>
              </a>
              <button aria-label="caret" className="caret">
                <svg className="svg-sm" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <polyline points="6 9 12 15 18 9" /></svg>
              </button>
              <ul className="hidden" id="pages-dropdown">
                <li>
                  <Link to={`/profile`}>
                    <svg className="svg-sm" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                    </svg>
                    <span>{i18n.t('profile')}</span>
                  </Link>
                </li>
                <li>
                  <Link to={`/notifications`}>
                    <svg className="svg-sm" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">  <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9" />  <path d="M13.73 21a2 2 0 0 1-3.46 0" /></svg>
                    <span>{i18n.t('notifications')}</span>
                  </Link>
                </li>
                <li>
                  <Link to={`/login`}>
                    <svg className="svg-sm" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z" />
                    </svg>
                    <span>{i18n.t('login')}</span>
                  </Link>
                </li>
                <li>
                  <Link to={`/register`}>
                    <svg className="svg-sm" width={24} height={24} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">  <path d="M12 20h9" />  <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z" /></svg>
                    <span>{i18n.t('register')}</span>
                  </Link>
                </li>
                <li>
                  <Link to={`/404`}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="svg-sm">
                      <path fill="currentColor" d="M256,16C123.452,16,16,123.452,16,256S123.452,496,256,496,496,388.548,496,256,388.548,16,256,16ZM403.078,403.078a207.253,207.253,0,1,1,44.589-66.125A207.332,207.332,0,0,1,403.078,403.078Z" />
                      <rect width={176} height={32} x={168} y={320} fill="currentColor" />
                      <polygon fill="currentColor" points="210.63 228.042 186.588 206.671 207.958 182.63 184.042 161.37 162.671 185.412 138.63 164.042 117.37 187.958 141.412 209.329 120.042 233.37 143.958 254.63 165.329 230.588 189.37 251.958 210.63 228.042" />
                      <polygon fill="currentColor" points="383.958 182.63 360.042 161.37 338.671 185.412 314.63 164.042 293.37 187.958 317.412 209.329 296.042 233.37 319.958 254.63 341.329 230.588 365.37 251.958 386.63 228.042 362.588 206.671 383.958 182.63" />
                    </svg>
                    <span>404</span>
                  </Link>
                </li>
                <li>
                  <Link to={`/500`}>
                    <svg className="svg-sm" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">  <rect x={2} y={2} width={20} height={8} rx={2} ry={2} />  <rect x={2} y={14} width={20} height={8} rx={2} ry={2} />  <line x1={6} y1={6} x2="6.01" y2={6} />  <line x1={6} y1={18} x2="6.01" y2={18} /></svg>
                    <span>500</span>
                  </Link>
                </li>
              </ul>
            </li>
            <hr />
            <li onClick={() => window.Lawha.toggleItem('#css-dropdown')}>
              <a href="#">
                <svg className="svg-sm" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <rect x={5} y={3} width={14} height={6} rx={2} />  <path d="M19 6h1a2 2 0 0 1 2 2a5 5 0 0 1 -5 5l-5 0v2" />  <rect x={10} y={15} width={4} height={6} rx={1} /></svg>
                <span>{i18n.t('CSS utility')}</span>
              </a>
              <button aria-label="caret" className="caret">
                <svg className="svg-sm" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <polyline points="6 9 12 15 18 9" /></svg>
              </button>
              <ul className="hidden" id="css-dropdown">
                <li>
                  <Link to={`/flexbox`}>
                    <svg className="svg-sm" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 5a1 1 0 011-1h14a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1V5zM4 13a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H5a1 1 0 01-1-1v-6zM16 13a1 1 0 011-1h2a1 1 0 011 1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-6z" />
                    </svg>  
                    <span>{i18n.t('flexbox')}</span>
                  </Link>
                </li>
                <li>
                  <Link to={`/grid`}>
                    <svg className="svg-sm" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 17V7m0 10a2 2 0 01-2 2H5a2 2 0 01-2-2V7a2 2 0 012-2h2a2 2 0 012 2m0 10a2 2 0 002 2h2a2 2 0 002-2M9 7a2 2 0 012-2h2a2 2 0 012 2m0 10V7m0 10a2 2 0 002 2h2a2 2 0 002-2V7a2 2 0 00-2-2h-2a2 2 0 00-2 2" />
                    </svg>
                    <span>{i18n.t('grid system')}</span>
                  </Link>
                </li>
                <li>
                  <Link to={`/box-modal`}>
                    <svg className="svg-sm" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <path d="M4 8v-2a2 2 0 0 1 2 -2h2" />  <path d="M4 16v2a2 2 0 0 0 2 2h2" />  <path d="M16 4h2a2 2 0 0 1 2 2v2" />  <path d="M16 20h2a2 2 0 0 0 2 -2v-2" /></svg>
                    <span>{i18n.t('box modal')}</span>
                  </Link>
                </li>
                <li>
                  <Link to={`/typography`}>
                    <svg className="svg-sm" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <line x1={4} y1={20} x2={7} y2={20} />  <line x1={14} y1={20} x2={21} y2={20} />  <line x1="6.9" y1={15} x2="13.8" y2={15} />  <line x1="10.2" y1="6.3" x2={16} y2={20} />  <polyline points="5 20 11 4 13 4 20 20" /></svg>
                    <span>{i18n.t('typography')}</span>
                  </Link>
                </li>
              </ul>
            </li>
            <hr />
            <li>
              <Link to={`/charts`}>
                <svg className="svg-sm" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z" />
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M20.488 9H15V3.512A9.025 9.025 0 0120.488 9z" />
                </svg>
                <span className="flex-1">{i18n.t('charts')}</span>
                <span className="badge primary-bg-color text-xs">+12</span>
              </Link>
            </li>
          </ul>
        </div>
      );
    }
};

export default SideBar;