import React from "react";

class BorderRadiusPagination extends React.Component {
    render(){
        return (
            <div className="grid-of-2">
              {/* rounded /////////// */}
              <div className="pagination base default b-rounded">
                <a href="#">‹</a>
                <a href="#">1</a>
                <a href="#">2</a>
                <a href="#">3</a>
                <a href="#">4</a>
                <a href="#" className="disabled">›</a>
              </div>
              {/* rounded-md /////////// */}
              <div className="pagination base cyan b-rounded-md">
                <a href="#">‹</a>
                <a href="#">1</a>
                <a href="#">2</a>
                <a href="#">3</a>
                <a href="#">4</a>
                <a href="#" className="disabled">›</a>
              </div>
              {/* rounded-lg /////////// */}
              <div className="pagination base green b-rounded-lg">
                <a href="#">‹</a>
                <a href="#">1</a>
                <a href="#">2</a>
                <a href="#">3</a>
                <a href="#">4</a>
                <a href="#" className="disabled">›</a>
              </div>
              {/* rounded-full /////////// */}
              <div className="pagination base red b-rounded-full">
                <a href="#">‹</a>
                <a href="#">1</a>
                <a href="#">2</a>
                <a href="#">3</a>
                <a href="#">4</a>
                <a href="#" className="disabled">›</a>
              </div>
            </div>
        )
    }
}

export default BorderRadiusPagination;