import React from "react";
import { Helmet } from "react-helmet";
import GalleryGrid from "../components/gallery/GalleryGrid";
import MainLayout from "../layouts/Main";
import i18n from 'i18next';

class GalleryPage extends React.Component {
    render(){
        return (
            <MainLayout>
                <Helmet>
                    <title>{i18n.t('gallery')}</title>
                </Helmet>
                <GalleryGrid />
            </MainLayout>
        )
    }
}

export default GalleryPage;