/**
 * @see https://github.com/zenoamaro/react-quill
*/
import React, { useState } from 'react';
import ReactQuill from 'react-quill';

export default function TextEditor() {
  const [value, setValue] = useState('');

  return <ReactQuill theme="snow" value={value} onChange={setValue} />;
}