import React from "react";

class PaginationColors extends React.Component {
    render(){
        return (
            <div className="grid-of-2">
               {/* red /////////// */}
               <div className="pagination base red">
                 <a href="#">‹</a>
                 <a href="#">1</a>
                 <a href="#">2</a>
                 <a href="#">3</a>
                 <a href="#">4</a>
                 <a href="#" className="disabled">›</a>
               </div>
               {/* purple /////////// */}
               <div className="pagination base purple">
                 <a href="#">‹</a>
                 <a href="#">1</a>
                 <a href="#">2</a>
                 <a href="#">3</a>
                 <a href="#">4</a>
                 <a href="#" className="disabled">›</a>
               </div>
               {/* amber /////////// */}
               <div className="pagination base amber">
                 <a href="#">‹</a>
                 <a href="#">1</a>
                 <a href="#">2</a>
                 <a href="#">3</a>
                 <a href="#">4</a>
                 <a href="#" className="disabled">›</a>
               </div>
               {/* blue /////////// */}
               <div className="pagination base blue">
                 <a href="#">‹</a>
                 <a href="#">1</a>
                 <a href="#">2</a>
                 <a href="#">3</a>
                 <a href="#">4</a>
                 <a href="#" className="disabled">›</a>
               </div>
               {/* cyan /////////// */}
               <div className="pagination base cyan">
                 <a href="#">‹</a>
                 <a href="#">1</a>
                 <a href="#">2</a>
                 <a href="#">3</a>
                 <a href="#">4</a>
                 <a href="#" className="disabled">›</a>
               </div>
               {/* emerald /////////// */}
               <div className="pagination base emerald">
                 <a href="#">‹</a>
                 <a href="#">1</a>
                 <a href="#">2</a>
                 <a href="#">3</a>
                 <a href="#">4</a>
                 <a href="#" className="disabled">›</a>
               </div>
               {/* gray /////////// */}
               <div className="pagination base gray">
                 <a href="#">‹</a>
                 <a href="#">1</a>
                 <a href="#">2</a>
                 <a href="#">3</a>
                 <a href="#">4</a>
                 <a href="#" className="disabled">›</a>
               </div>
               {/* green /////////// */}
               <div className="pagination base green">
                 <a href="#">‹</a>
                 <a href="#">1</a>
                 <a href="#">2</a>
                 <a href="#">3</a>
                 <a href="#">4</a>
                 <a href="#" className="disabled">›</a>
               </div>
               {/* lime /////////// */}
               <div className="pagination base lime">
                 <a href="#">‹</a>
                 <a href="#">1</a>
                 <a href="#">2</a>
                 <a href="#">3</a>
                 <a href="#">4</a>
                 <a href="#" className="disabled">›</a>
               </div>
               {/* orange /////////// */}
               <div className="pagination base orange">
                 <a href="#">‹</a>
                 <a href="#">1</a>
                 <a href="#">2</a>
                 <a href="#">3</a>
                 <a href="#">4</a>
                 <a href="#" className="disabled">›</a>
               </div>
               {/* pink /////////// */}
               <div className="pagination base pink">
                 <a href="#">‹</a>
                 <a href="#">1</a>
                 <a href="#">2</a>
                 <a href="#">3</a>
                 <a href="#">4</a>
                 <a href="#" className="disabled">›</a>
               </div>
               {/* teal /////////// */}
               <div className="pagination base teal">
                 <a href="#">‹</a>
                 <a href="#">1</a>
                 <a href="#">2</a>
                 <a href="#">3</a>
                 <a href="#">4</a>
                 <a href="#" className="disabled">›</a>
               </div>
               {/* yellow /////////// */}
               <div className="pagination base yellow">
                 <a href="#">‹</a>
                 <a href="#">1</a>
                 <a href="#">2</a>
                 <a href="#">3</a>
                 <a href="#">4</a>
                 <a href="#" className="disabled">›</a>
               </div>
            </div>
        )
    }
}

export default PaginationColors;