import React from "react";
import { Helmet } from "react-helmet";
import ColSpans from "../components/grid/ColSpans";
import GridOf1 from "../components/grid/GridOf1";
import GridOf10 from "../components/grid/GridOf10";
import GridOf2 from "../components/grid/GridOf2";
import GridOf3 from "../components/grid/GridOf3";
import GridOf4 from "../components/grid/GridOf4";
import GridOf5 from "../components/grid/GridOf5";
import GridOf6 from "../components/grid/GridOf6";
import GridOf7 from "../components/grid/GridOf7";
import GridOf9 from "../components/grid/GridOf9";
import MainLayout from "../layouts/Main";
import i18n from 'i18next';

class GridPage extends React.Component {
    render(){
        return (
            <MainLayout>
                <Helmet>
                    <title>{i18n.t('grid system')}</title>
                </Helmet>
                <div className="lvl-1-container my-4">
                    <GridOf1 />
                    <GridOf2 />
                    <GridOf3 />
                    <GridOf4 />
                    <GridOf5 />
                    <GridOf6 />
                    <GridOf7 />
                    <GridOf9 />
                    <GridOf10 />
                    <ColSpans />
                </div>
            </MainLayout>
        )
    }
}

export default GridPage;