import React from "react";
import i18n from "i18next";

class BorderedAlerts extends React.Component{
    render(){
        return (
            <div className="grid-of-3">
                <div className="bordered-alert default" role="alert">
                  <p className="font-bold">{i18n.t('default')}</p>
                  <p>{i18n.t('alert example')}</p>
                </div>
                <div className="bordered-alert amber" role="alert">
                  <p className="font-bold">{i18n.t('amber')}</p>
                  <p>{i18n.t('alert example')}</p>
                </div>
                <div className="bordered-alert blue" role="alert">
                  <p className="font-bold">{i18n.t('blue')}</p>
                  <p>{i18n.t('alert example')}</p>
                </div>
                <div className="bordered-alert cyan" role="alert">
                  <p className="font-bold">{i18n.t('cyan')}</p>
                  <p>{i18n.t('alert example')}</p>
                </div>
                <div className="bordered-alert emerald" role="alert">
                  <p className="font-bold">{i18n.t('emerald')}</p>
                  <p>{i18n.t('alert example')}</p>
                </div>
                <div className="bordered-alert gray" role="alert">
                  <p className="font-bold">{i18n.t('gray')}</p>
                  <p>{i18n.t('alert example')}</p>
                </div>
                <div className="bordered-alert green" role="alert">
                  <p className="font-bold">{i18n.t('green')}</p>
                  <p>{i18n.t('alert example')}</p>
                </div>
                <div className="bordered-alert lime" role="alert">
                  <p className="font-bold">{i18n.t('lime')}</p>
                  <p>{i18n.t('alert example')}</p>
                </div>
                <div className="bordered-alert orange" role="alert">
                  <p className="font-bold">{i18n.t('orange')}</p>
                  <p>{i18n.t('alert example')}</p>
                </div>
                <div className="bordered-alert pink" role="alert">
                  <p className="font-bold">{i18n.t('pink')}</p>
                  <p>{i18n.t('alert example')}</p>
                </div>
                <div className="bordered-alert purple" role="alert">
                  <p className="font-bold">{i18n.t('purple')}</p>
                  <p>{i18n.t('alert example')}</p>
                </div>
                <div className="bordered-alert red" role="alert">
                  <p className="font-bold">{i18n.t('red')}</p>
                  <p>{i18n.t('alert example')}</p>
                </div>
                <div className="bordered-alert sky" role="alert">
                  <p className="font-bold">{i18n.t('sky')}</p>
                  <p>{i18n.t('alert example')}</p>
                </div>
                <div className="bordered-alert teal" role="alert">
                  <p className="font-bold">{i18n.t('teal')}</p>
                  <p>{i18n.t('alert example')}</p>
                </div>
                <div className="bordered-alert yellow" role="alert">
                  <p className="font-bold">{i18n.t('yellow')}</p>
                  <p>{i18n.t('alert example')}</p>
                </div>
            </div>
        )
    }
}

export default BorderedAlerts;