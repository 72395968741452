import React from "react";
import { Helmet } from "react-helmet";
import BaseCard from "../components/cards/BaseCard";
import HorizontalCard from "../components/cards/HorizontalCard";
import UserProfileCard from "../components/cards/UserProfileCard";
import MainLayout from "../layouts/Main";
import i18n from 'i18next';

class CardsPage extends React.Component {
  render(){
    return (
      <MainLayout>
        <Helmet>
          <title>{i18n.t('cards')}</title>
        </Helmet>
        <div>
        <div className="my-4">
          <div className="grid-of-2">
            <HorizontalCard />
            <HorizontalCard />
          </div>
        </div>
        <div className="my-4">
          <div className="grid-of-4">
            <UserProfileCard />
            <UserProfileCard />
            <UserProfileCard />
            <UserProfileCard />
          </div>
        </div>
        <div className="my-4">
          <div className="grid-of-4">
            <BaseCard />
            <BaseCard />
            <BaseCard />
            <BaseCard />
          </div>
        </div>
      </div>
      </MainLayout>
    )
  }
}

export default CardsPage;