import React from "react";
import i18n from 'i18next';

class RoundedButtons extends React.Component {
    render(){
        return (
            <div className="flex flex-wrap gap-2">
               <button name="button" className="button-3d rounded-full red">{i18n.t('click me')}</button>
               <button name="button" className="button-3d rounded-full green">{i18n.t('click me')}</button>
               <button name="button" className="button-3d rounded-full gray">{i18n.t('click me')}</button>
               <button name="button" className="button-3d rounded-full purple">{i18n.t('click me')}</button>
               <button name="button" className="button-3d rounded-full orange">{i18n.t('click me')}</button>
               <button name="button" className="outlined-button rounded-full red">{i18n.t('click me')}</button>
               <button name="button" className="outlined-button rounded-full green">{i18n.t('click me')}</button>
               <button name="button" className="outlined-button rounded-full gray">{i18n.t('click me')}</button>
               <button name="button" className="outlined-button rounded-full purple">{i18n.t('click me')}</button>
               <button name="button" className="outlined-button rounded-full orange">{i18n.t('click me')}</button>
               <button name="button" className="simple-button rounded-full red">{i18n.t('click me')}</button>
               <button name="button" className="simple-button rounded-full green">{i18n.t('click me')}</button>
               <button name="button" className="simple-button rounded-full gray">{i18n.t('click me')}</button>
               <button name="button" className="simple-button rounded-full purple">{i18n.t('click me')}</button>
               <button name="button" className="simple-button rounded-full orange">{i18n.t('click me')}</button>
               <button name="button" className="outlined-button rounded-full disabled" disabled>{i18n.t('disabled')}</button>
            </div>
        )
    }
}

export default RoundedButtons;