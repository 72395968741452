import React from "react";
import i18n from "i18next";
import DefaultTable from "../components/tables/DefaultTable";
import ColumnsOnly from "../components/tables/ColumnsOnly";
import RowsOnly from "../components/tables/RowsOnly";
import Traditional from "../components/tables/Traditional";
import MainLayout from "../layouts/Main";
import { Helmet } from "react-helmet";

class TablesPage extends React.Component {
    render(){
        return (
            <MainLayout>
              <Helmet>
                <title>{i18n.t('tables')}</title>
              </Helmet>
              <div>
                {/* default ////////////////////// */}
                <div className="lvl-1-container p-4 my-5">
                    <div className="my-4 flex">
                      <h6 className="font-lg">{i18n.t('default')}</h6>
                    </div>
                    <DefaultTable />
                </div>

                 {/* traditional /////////////// */}
                 <div className="lvl-1-container p-4 my-5">
                   <div className="my-4 flex">
                     <h6 className="font-lg">{i18n.t('traditional')}</h6>
                   </div>
                   <Traditional />
                 </div>
  
                 {/* rows only //////////////// */}
                 <div className="p-4 my-5">
                   <div className="my-4 flex">
                     <h6 className="font-lg">{i18n.t('rows only')}</h6>
                   </div>
                   <RowsOnly />
                 </div>
  
                 {/* columns only ////////////////// */}
                 <div className="p-4 my-5">
                   <div className="my-4 flex">
                     <h6 className="font-lg">{i18n.t('columns only')}</h6>
                   </div>
                   <ColumnsOnly />
                 </div>
              </div>
            </MainLayout>
        )
    }
}

export default TablesPage;