import React from "react";
import i18n from 'i18next';

class ThreeDButtons extends React.Component{
    render(){
        return (
            <div className="flex flex-wrap gap-2">
              <button name="button" className="button-3d red">{i18n.t('click me')}</button>
              <button name="button" className="button-3d green">{i18n.t('click me')}</button>
              <button name="button" className="button-3d gray">{i18n.t('click me')}</button>
              <button name="button" className="button-3d purple">{i18n.t('click me')}</button>
              <button name="button" className="button-3d orange">{i18n.t('click me')}</button>
              <button name="button" className="button-3d pink">{i18n.t('click me')}</button>
              <button name="button" className="button-3d disabled" disabled>{i18n.t('disabled')}</button>
            </div>
        )
    }
}

export default ThreeDButtons;