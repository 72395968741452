import React from 'react';
import i18n from 'i18next';
import SwitchAndCheckboxes from '../components/forms/SwitchAndCheckboxes';
import FileUploader from '../components/forms/FileUploader';
import MarkdownEditor from '../components/forms/MarkdownEditor';
import MainLayout from '../layouts/Main';
import SimpleInputs from '../components/forms/SimpleInputs';
import InlinedInputs from '../components/forms/InlinedInputs';
import IconedInputs from '../components/forms/IconedInputs';
import TextEditor from '../components/forms/TextEditor';
import { Helmet } from 'react-helmet';

function FormPage() {
    return (
       <MainLayout>
         <Helmet>
          <title>{i18n.t('form')}</title>
         </Helmet>
         <div className="grid-of-2 my-5">
          {/*  switch & checkboxes /////////// */}
          <div className="lvl-1-container p-4">
            <div className="my-4 flex">
              <h6 className="font-lg">{i18n.t('switch and checkboxes')}</h6>
            </div>
            <hr />
            <SwitchAndCheckboxes />
          </div>
          {/* file uploader /////////// */}
          <div className="lvl-1-container p-4">
            <div className="my-4 flex">
              <h6 className="font-lg">{i18n.t('file uploader')}</h6>
            </div>
            <hr />
            <FileUploader />
          </div>
          {/* text editor /////////// */}
          <div className="lvl-1-container p-4">
            <div className="my-4 flex">
              <h6 className="font-lg">{i18n.t('text editor')}</h6>
            </div>
            <hr />
            <TextEditor />
          </div>
          {/* markdown editor /////////// */}
          <div className="lvl-1-container p-4">
            <div className="my-4 flex">
              <h6 className="font-lg">{i18n.t('markdown editor')}</h6>
            </div>
            <hr />
            <MarkdownEditor />
          </div>
          {/*  simple inputs /////////// */}
          <div className="lvl-1-container p-4">
            <div className="my-4 flex">
              <h6 className="font-lg">{i18n.t('simple')}</h6>
            </div>
            <hr />
            <SimpleInputs />
          </div>
          {/*  inline form elements /////////// */}
          <div className="lvl-1-container p-4">
            <div className="my-4 flex">
              <h6 className="font-lg">{i18n.t('inline')}</h6>
            </div>
            <hr />
            <InlinedInputs />
          </div>
          {/*  icones form elements /////////// */}
          <div className="lvl-1-container p-4">
            <div className="my-4 flex">
              <h6 className="font-lg">{i18n.t('icons')}</h6>
            </div>
            <hr />
            <IconedInputs />
          </div>
        </div>
       </MainLayout>
    )
}

export default FormPage;