import React from "react";
import i18n from "i18next";

class TasksList extends React.Component {
    render(){
        return (
            <div className="lvl-1-container">
              <div className="tl-card-head">
                <p>{i18n.t('full date example')}</p>
                <p>{i18n.t('sub date example')}</p>
              </div>
              <p className="tl-card-title">{i18n.t('tasks list')}</p>
              <div className="tl-card-nav">
                <div className="flex justify-between">
                  <p> <span>S</span> (214) </p>
                  <p> <span>SU</span> (258)</p>
                  <p> <span>M</span> (246)</p>
                  <p> <span>T</span> (205)</p>
                  <p> <span>W</span> (145)</p>
                  <p> <span>T</span> (15) </p>
                  <p> <span>F</span> (78)</p>
                </div>
              </div>
              <ul className="my-4">
                {(() => {
                    let tasks = []
                    for (let index = 0; index < 8; index++) {
                        tasks.push(
                            <li key={index} className="task-item">
                              <div className="task">
                                <div className="task-name">
                                  <span className="task-checkbox">
                                    {(() => {
                                        if(index == 0){
                                            return (
                                                <svg className="svg-sm primary-text-color" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">  <polyline points="20 6 9 17 4 12" /></svg>
                                            )
                                        }
                                    })()}
                                  </span>
                                  <span className="task-title">{i18n.t('task title example')}</span>
                                </div>
                                <div className="assigned-users">
                                  <img data-src="https://via.placeholder.com/25" className="lazy rounded-full" alt="me" />
                                  <img data-src="https://via.placeholder.com/25" className="lazy rounded-full" alt="me" />
                                  <img data-src="https://via.placeholder.com/25" className="lazy rounded-full" alt="me" />
                                  <span className="assign-button">+</span>
                                  <div className="flex-1" />
                                  <span>9:00 AM</span>
                                </div>
                              </div>
                            </li>
                        )
                    }
                    return tasks;
                })()}
                <li className="add-task-item"> + </li>
              </ul>
            </div>
        )
    }
}

export default TasksList;