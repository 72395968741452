import React from "react";
import logo from '../../../assets/images/logo.png'
import logoLight from '../../../assets/images/logo-light.png'
import { Link } from "react-router-dom";
import i18n from 'i18next';

class LoginCard extends React.Component {
    render (){
        return (
            <form className="shadow-xl m-auto flex-1 lg:flex-initial lvl-1-container p-4 w-full lg:w-3/6">
              <Link to={`/`} className="text-center">
                <img className="dark:hidden my-4 w-20 mx-auto" src={logo} alt="logo" />
                <img className="hidden dark:block my-4 w-20 mx-auto" src={logoLight} alt="logo" />
              </Link>
              <div className="form-element iconed mt-4">
                <label>{i18n.t('username')}</label>
                <div>
                  <i>
                    <svg className="svg-sm primary-text-color" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">  <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />  <circle cx={12} cy={7} r={4} /></svg>
                  </i>
                  <input type="text" data-attr-t placeholder={i18n.t('username')} />
                </div>
              </div>
              <div className="form-element iconed mt-4">
                <label>{i18n.t('password')}</label>
                <div>
                  <i>
                    <svg className="svg-sm primary-text-color" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z" />
                    </svg>
                  </i>
                  <input type="text" data-attr-t placeholder={i18n.t('password')} />
                </div>
              </div>
              <div className="form-element inlined switch my-4">
                <div className="flex">
                  <label htmlFor="toggleSM">
                    <div className="toggle-container sm">
                      <input type="checkbox" id="toggleSM" className="sr-only" />
                      <div className="switch-bg" />
                      <div className="dot primary-bg-color" />
                    </div>
                  </label>
                </div>
                <label className="flex-1 text-xs">{i18n.t('i accept conditions')}</label>        
              </div>
              <div className="mt-5">
                <p className="text-xs">
                  <span>{i18n.t('don\'t have an account yet?')}</span>
                  <Link to={`/register`} className="font-bold primary-text-color">{i18n.t('register')}</Link>
                </p>
                <p className="text-xs mt-2">
                  <span>{i18n.t('forgot password?')}</span>
                  <Link to={`/register`} className="font-bold primary-text-color">{i18n.t('reset')}</Link>
                </p>
              </div>
              <div className="mt-10 flex gap-2">
                <button type="button" className="md simple-button mr-auto primary-bg-color">{i18n.t('register')}</button>
              </div>
            </form>
        )
    }
}

export default LoginCard;