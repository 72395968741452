import React from "react";
import adminPanelImage from '../../../assets/images/admin-panel.png';

class AdminImg extends React.Component {
    render(){
        return (
            <div className="rounded-xl w-full h-full overflow-hidden relative primary-bg-color">
              <img src={adminPanelImage} className="absolute top-0 right-0 h-full" alt="cover image" />
            </div>
        )
    }
}

export default AdminImg;