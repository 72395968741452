import React from "react";
import i18n from 'i18next';
import FileUploader from "../forms/FileUploader";

class FileManagerApp extends React.Component {
    render (){
        return (
            <div className="file-manager-container grid-of-10">
              {/* file manager sidebar /////////////////// */}
              <div className="span-2 lvl-1-container">
                {/* file manager navigation menus /////////////////// */}
                <ul>
                  <li className="nav-item files-preview ">
                    <svg className="svg-xs inline" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">  <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z" /></svg>
                    <a href="#">
                      <small>{i18n.t('my files')}</small>
                    </a>
                    <ul className="nested-lvl-1">
                      <li className="nav-item closed-caret">
                        <svg className="svg-xs inline" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">  <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z" /></svg>
                        <a href="#">
                          <small>{i18n.t('images')}</small>
                        </a>
                      </li>
                      <li className="nav-item closed-caret">
                        <svg className="svg-xs inline" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">  <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z" /></svg>
                        <a href="#">
                          <small>{i18n.t('videos')}</small>
                        </a>
                      </li>
                      <li className="nav-item opened-caret">
                        <svg className="svg-xs inline" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">  <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z" /></svg>
                        <a href="#">
                          <small>{i18n.t('other')}</small>
                        </a>
                        <ul className="nested-lvl-2">
                          <li className="nav-item closed-caret">
                            <svg className="svg-xs inline" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <rect x={4} y={13} rx={2} width={4} height={6} />  <rect x={16} y={13} rx={2} width={4} height={6} />  <path d="M4 15v-3a8 8 0 0 1 16 0v3" />  <path d="M18 19a6 3 0 0 1 -6 3" /></svg>
                            <a href="#">
                              <small>{i18n.t('audio')}</small>
                            </a>
                          </li>
                          <li className="nav-item opened-caret">
                            <svg className="svg-xs inline" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">  <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z" /></svg>
                            <a href="#">
                              <small>{i18n.t('documents')}</small>
                            </a>
                          </li>
                          <ul className="nested-lvl-3">
                            <li className="nav-item closed-caret">
                              <svg className="svg-xs inline" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">  <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z" /></svg>
                              <a href="#">
                                <small className="active">2014 - 2018</small>
                              </a>
                            </li>
                            <li className="nav-item closed-caret">
                              <svg className="svg-xs inline" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">  <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z" /></svg>
                              <a href="#">
                                <small>2018 - 2022</small>
                              </a>
                            </li>
                          </ul>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
                <hr />
                <div>
                  <ul>
                    <li className="nav-item">
                      <svg className="svg-xs inline" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <path d="M14 3v4a1 1 0 0 0 1 1h4" />  <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />  <line x1={12} y1={11} x2={12} y2={17} />  <line x1={9} y1={14} x2={15} y2={14} /></svg>
                      <a href="#">
                        <span>{i18n.t('new file')}</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <svg className="svg-xs inline" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <path d="M5 4h4l3 3h7a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-11a2 2 0 0 1 2 -2" />  <line x1={12} y1={10} x2={12} y2={16} />  <line x1={9} y1={13} x2={15} y2={13} /></svg>
                      <a href="#">
                        <span>{i18n.t('new folder')}</span>
                      </a>
                    </li>
                  </ul>
                </div>
                <hr />
                <div>
                  <ul>
                    <li className="nav-item">
                      <svg className="svg-xs inline" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">  <polyline points="3 6 5 6 21 6" />  <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />  <line x1={10} y1={11} x2={10} y2={17} />  <line x1={14} y1={11} x2={14} y2={17} /></svg>
                      <a href="#">
                        <span>{i18n.t('trash')}</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <svg className="svg-xs inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                      </svg>
                      <a href="#">
                        <span>{i18n.t('settings')}</span>
                      </a>
                    </li>
                  </ul>
                </div>
                <hr />
                {/* storage overview /////////// */}
                <div>
                  <h2 className="text-lg my-4">{i18n.t('storage details')}</h2>
                  <p>
                    <svg className="svg-xs inline" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">  <line x1={22} y1={12} x2={2} y2={12} />  <path d="M5.45 5.11L2 12v6a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-6l-3.45-6.89A2 2 0 0 0 16.76 4H7.24a2 2 0 0 0-1.79 1.11z" />  <line x1={6} y1={16} x2="6.01" y2={16} />  <line x1={10} y1={16} x2="10.01" y2={16} /></svg>
                    <small>{i18n.t('total storage')}</small>
                  </p><div className="progress-bar-container">
                    <div style={{width: '50%'}} className="primary-bg-color progress-bar" />
                  </div>
                  <small dir="ltr">170MB / 10GB</small>
                  <p />
                  <br />
                  <p>
                    <svg className="svg-xs inline" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">  <line x1={22} y1={12} x2={2} y2={12} />  <path d="M5.45 5.11L2 12v6a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-6l-3.45-6.89A2 2 0 0 0 16.76 4H7.24a2 2 0 0 0-1.79 1.11z" />  <line x1={6} y1={16} x2="6.01" y2={16} />  <line x1={10} y1={16} x2="10.01" y2={16} /></svg>
                    <small>{i18n.t('inodes')}</small>
                  </p><div className="progress-bar-container">
                    <div style={{width: '20%'}} className="primary-bg-color progress-bar" />
                  </div>
                  <small dir="ltr">10 / 10,0000</small>
                  <p />
                </div>
              </div>
              {/* file manager main panel //////////////// */}
              <div className="span-8 lvl-1-container flex flex-col">
                <div className="flex mb-5">
                  {/* search ///////// */}
                  <div className="form-element iconed">
                    <div>
                      <i>
                        <svg className="svg-xs primary-text-color" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <circle cx={10} cy={10} r={7} />  <line x1={21} y1={21} x2={15} y2={15} /></svg>
                      </i>
                      <input type="text" data-attr-t placeholder-t="search" />
                    </div>
                  </div>
                  <div className="flex-1" />
                  {/* action buttons */}
                  <div className="flex gap-3">
                    <button>
                      <svg className="svg-xs" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">  <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />  <polyline points="7 10 12 15 17 10" />  <line x1={12} y1={15} x2={12} y2={3} /></svg>
                    </button>
                    <button>
                      <svg className="svg-xs" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <line x1={4} y1={7} x2={20} y2={7} />  <line x1={10} y1={11} x2={10} y2={17} />  <line x1={14} y1={11} x2={14} y2={17} />  <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />  <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" /></svg>
                    </button>
                    <button>
                      <svg className="svg-xs" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">  <path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z" /></svg>
                    </button>
                  </div>
                </div>
                {/* files preview ///////// */}
                <div className="grid-of-5 mb-5">
                  {/* file preview ///////// */}
                  <label className="file-preview">
                    <input type="checkbox" className="check-file" />
                    <button className="file-options">
                      <svg onClick={() => window.Lawha.toggleItem('#opt-menu-1')} className="svg-xs" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <circle cx={5} cy={12} r={1} />  <circle cx={12} cy={12} r={1} />  <circle cx={19} cy={12} r={1} /></svg>
                      <div id="opt-menu-1" className="options-menu hidden">
                        <ul>
                          <li> <a href="#">{i18n.t('option')}</a> </li>
                          <li> <a href="#">{i18n.t('option')}</a> </li>
                          <li> <a href="#">{i18n.t('option')}</a> </li>
                        </ul>
                      </div>
                    </button>
                    <i>
                      <svg className="file-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">  <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z" /></svg>
                    </i>
                    <p>Documentation</p>
                    <small dir="ltr">4 MB</small>
                  </label>
                  {/* file preview ///////// */}
                  <label className="file-preview">
                    <input type="checkbox" className="check-file" defaultChecked />
                    <button className="file-options">
                      <svg onClick={() => window.Lawha.toggleItem('#opt-menu-2')} className="svg-xs" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <circle cx={5} cy={12} r={1} />  <circle cx={12} cy={12} r={1} />  <circle cx={19} cy={12} r={1} /></svg>
                      <div id="opt-menu-2" className="options-menu hidden">
                        <ul>
                          <li> <a href="#">{i18n.t('option')}</a> </li>
                          <li> <a href="#">{i18n.t('option')}</a> </li>
                          <li> <a href="#">{i18n.t('option')}</a> </li>
                        </ul>
                      </div>
                    </button>
                    <i>
                      <svg className="file-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">  <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" />  <polyline points="14 2 14 8 20 8" />  <line x1={16} y1={13} x2={8} y2={13} />  <line x1={16} y1={17} x2={8} y2={17} />  <polyline points="10 9 9 9 8 9" /></svg>
                    </i>
                    <p>PRD.pdf</p>
                    <small dir="ltr">2.8 MB</small>
                  </label>
                  {/* file preview ///////// */}
                  <label className="file-preview">
                    <input type="checkbox" className="check-file" defaultChecked />
                    <button className="file-options">
                      <svg onClick={() => window.Lawha.toggleItem('#opt-menu-3')} className="svg-xs" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <circle cx={5} cy={12} r={1} />  <circle cx={12} cy={12} r={1} />  <circle cx={19} cy={12} r={1} /></svg>
                      <div id="opt-menu-3" className="options-menu hidden">
                        <ul>
                          <li> <a href="#">{i18n.t('option')}</a> </li>
                          <li> <a href="#">{i18n.t('option')}</a> </li>
                          <li> <a href="#">{i18n.t('option')}</a> </li>
                        </ul>
                      </div>
                    </button>
                    <i>
                      <svg className="file-icon" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <path d="M14 3v4a1 1 0 0 0 1 1h4" />  <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />  <circle cx={11} cy={16} r={1} />  <polyline points="12 16 12 11 14 12" /></svg>
                    </i>
                    <p>tune.mp3</p>
                    <small dir="ltr">1.44 MB</small>
                  </label>
                  {/* file preview ///////// */}
                  <label className="file-preview">
                    <input type="checkbox" className="check-file" />
                    <button className="file-options">
                      <svg onClick={() => window.Lawha.toggleItem('#opt-menu-4')} className="svg-xs" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <circle cx={5} cy={12} r={1} />  <circle cx={12} cy={12} r={1} />  <circle cx={19} cy={12} r={1} /></svg>
                      <div id="opt-menu-4" className="options-menu hidden">
                        <ul>
                          <li> <a href="#">{i18n.t('option')}</a> </li>
                          <li> <a href="#">{i18n.t('option')}</a> </li>
                          <li> <a href="#">{i18n.t('option')}</a> </li>
                        </ul>
                      </div>
                    </button>
                    <i>
                      <svg className="file-icon" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <path d="M14 3v4a1 1 0 0 0 1 1h4" />  <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />  <path d="M10 13l-1 2l1 2" />  <path d="M14 13l1 2l-1 2" /></svg>
                    </i>
                    <p>script.js</p>
                    <small dir="ltr">1.44 MB</small>
                  </label>
                  {/* file preview ///////// */}
                  <label className="file-preview">
                    <input type="checkbox" className="check-file" />
                    <button className="file-options">
                      <svg onClick={() => window.Lawha.toggleItem('#opt-menu-5')} className="svg-xs" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <circle cx={5} cy={12} r={1} />  <circle cx={12} cy={12} r={1} />  <circle cx={19} cy={12} r={1} /></svg>
                      <div id="opt-menu-5" className="options-menu hidden">
                        <ul>
                          <li> <a href="#">{i18n.t('option')}</a> </li>
                          <li> <a href="#">{i18n.t('option')}</a> </li>
                          <li> <a href="#">{i18n.t('option')}</a> </li>
                        </ul>
                      </div>
                    </button>
                    <i>
                      <svg className="file-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">  <rect x={3} y={3} width={18} height={18} rx={2} ry={2} />  <circle cx="8.5" cy="8.5" r="1.5" />  <polyline points="21 15 16 10 5 21" /></svg>
                    </i>
                    <p>profile.jfif</p>
                    <small dir="ltr">1.2 MB</small>
                  </label>
                  {/* file preview ///////// */}
                  <label className="file-preview">
                    <input type="checkbox" className="check-file" />
                    <button className="file-options">
                      <svg onClick={() => window.Lawha.toggleItem('#opt-menu-3')} className="svg-xs" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <circle cx={5} cy={12} r={1} />  <circle cx={12} cy={12} r={1} />  <circle cx={19} cy={12} r={1} /></svg>
                      <div id="opt-menu-3" className="options-menu hidden">
                        <ul>
                          <li> <a href="#">{i18n.t('option')}</a> </li>
                          <li> <a href="#">{i18n.t('option')}</a> </li>
                          <li> <a href="#">{i18n.t('option')}</a> </li>
                        </ul>
                      </div>
                    </button>
                    <i>
                      <svg className="file-icon" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <path d="M14 3v4a1 1 0 0 0 1 1h4" />  <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />  <circle cx={11} cy={16} r={1} />  <polyline points="12 16 12 11 14 12" /></svg>
                    </i>
                    <p>tune.mp3</p>
                    <small dir="ltr">1.44 MB</small>
                  </label>
                  {/* file preview ///////// */}
                  <label className="file-preview">
                    <input type="checkbox" className="check-file" />
                    <button className="file-options">
                      <svg onClick={() => window.Lawha.toggleItem('#opt-menu-4')} className="svg-xs" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <circle cx={5} cy={12} r={1} />  <circle cx={12} cy={12} r={1} />  <circle cx={19} cy={12} r={1} /></svg>
                      <div id="opt-menu-4" className="options-menu hidden">
                        <ul>
                          <li> <a href="#">{i18n.t('option')}</a> </li>
                          <li> <a href="#">{i18n.t('option')}</a> </li>
                          <li> <a href="#">{i18n.t('option')}</a> </li>
                        </ul>
                      </div>
                    </button>
                    <i>
                      <svg className="file-icon" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <path d="M14 3v4a1 1 0 0 0 1 1h4" />  <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />  <path d="M10 13l-1 2l1 2" />  <path d="M14 13l1 2l-1 2" /></svg>
                    </i>
                    <p>script.js</p>
                    <small dir="ltr">1.44 MB</small>
                  </label>
                  {/* file preview ///////// */}
                  <label className="file-preview">
                    <input type="checkbox" className="check-file" defaultChecked />
                    <button className="file-options">
                      <svg onClick={() => window.Lawha.toggleItem('#opt-menu-5')} className="svg-xs" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <circle cx={5} cy={12} r={1} />  <circle cx={12} cy={12} r={1} />  <circle cx={19} cy={12} r={1} /></svg>
                      <div id="opt-menu-5" className="options-menu hidden">
                        <ul>
                          <li> <a href="#">{i18n.t('option')}</a> </li>
                          <li> <a href="#">{i18n.t('option')}</a> </li>
                          <li> <a href="#">{i18n.t('option')}</a> </li>
                        </ul>
                      </div>
                    </button>
                    <i>
                      <svg className="file-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">  <rect x={3} y={3} width={18} height={18} rx={2} ry={2} />  <circle cx="8.5" cy="8.5" r="1.5" />  <polyline points="21 15 16 10 5 21" /></svg>
                    </i>
                    <p>profile.jfif</p>
                    <small dir="ltr">1.2 MB</small>
                  </label>
                </div>
                {/* drag and drop files //// */}
                <div className="mt-auto text-center">
                   <FileUploader />
                </div>
              </div>
            </div>
        )
    }
}

export default FileManagerApp;