import React from "react";
import i18n from 'i18next';

class FileUploader extends React.Component {
    handleFileChange(e){
        window.Lawha.handleFileUploader('#img-upload', e.target.files)
    }
    render(){
        return (
            <div id="img-upload" className="file-uploader">
              <input type="file" className="fileElem" id="fileElem" multiple onChange={this.handleFileChange} />
              <label className="simple-button default" htmlFor="fileElem">
                <span>{i18n.t('choose files')}</span>
                <svg className="svg-sm inline" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">  <rect x={3} y={3} width={18} height={18} rx={2} ry={2} />  <circle cx="8.5" cy="8.5" r="1.5" />  <polyline points="21 15 16 10 5 21" /></svg>
              </label>
              <div className="preview-container" />
            </div>
        )
    }
}

export default FileUploader;