import React from "react";

class GridOf2 extends React.Component {
    render(){
        return (
            <div className="grid-of-2 my-4">
                <div className="grid-cell"> <strong className="text-lg">1</strong> </div>
                <div className="grid-cell"> <strong className="text-lg">2</strong> </div>
            </div>
        )
    }
}

export default GridOf2;