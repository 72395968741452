import React from "react";

class AuthLayout extends React.Component{
    render(){
        return (
            <>
                <div class="flex flex-col items-center min-h-screen">
                    {this.props.children}
                </div> 
            </>
        )
    }
}
export default AuthLayout;