import React from "react";
import i18n from "i18next";

class UserProfileCard extends React.Component{
    render(){
        return (
            <div className="lvl-1-container shadow-lg p-2 text-center">
              <div>
                <img data-src="https://via.placeholder.com/75" className="lazy my-2 border rounded-full w-20 mx-auto" alt="me" />
                <h2 className="text-lg font-bold">
                  <span>{i18n.t('jhon doe')}</span>
                </h2>
                (<span className="text-sm font-base">{i18n.t('lawha admin')}</span>)
                <p className="text-sm">jhondoe@gmail.co</p>
              </div>
              <hr />
              <div className="grid-of-2 gap-2">
                <a href="#" className="simple-button default">{i18n.t('contact')}</a>
                <a href="#" className="simple-button gray">{i18n.t('edit')}</a>
              </div>
            </div>
        )
    }
}

export default UserProfileCard;