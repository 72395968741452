import React from "react";
import i18n from 'i18next';

class InboxApp extends React.Component {
  render (){
    return (
      <div className="mail-inbox-container">
        {/* navigation buttons ///////////// */}
        <div className="nav-buttons">
          <nav className="buttons">
            <a href="#" className="nav-button"> 
              <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">  <line x1={12} y1={5} x2={12} y2={19} />  <line x1={5} y1={12} x2={19} y2={12} /></svg>
            </a>
            <a href="#" className="nav-button active">
              <svg className="svg-lg" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M8 4H6a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-2m-4-1v8m0 0l3-3m-3 3L9 8m-5 5h2.586a1 1 0 01.707.293l2.414 2.414a1 1 0 00.707.293h3.172a1 1 0 00.707-.293l2.414-2.414a1 1 0 01.707-.293H20" />
              </svg>
              <span className="nav-badge">3</span>
            </a>
            <a href="#" className="nav-button">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z" />
              </svg>
            </a>
            <a href="#" className="nav-button">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
              </svg>
            </a>
            <a href="#" className="nav-button">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z" />
              </svg>
            </a>
            <a href="#" className="nav-button">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
              </svg>
            </a>
            <a href="#" className="nav-button">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
              </svg>
            </a>
          </nav>
        </div>
        {/* mails list //////////////// */}
        <div id="mails-list" className="mails-list">
          {/* search for contact /////// */}
          <div className="form-element iconed my-4">
            <div>
              <i>
                <svg className="svg-sm primary-text-color" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">  <circle cx={11} cy={11} r={8} />  <line x1={21} y1={21} x2="16.65" y2="16.65" /></svg>
              </i>
              <input type="text" data-attr-t placeholder-t="search" />
            </div>
          </div>
          <ul>
            
            {(() => {
              let mailsItems = []

              for (let index = 0; index < 10; index++) {
                mailsItems.push(
                  index == 4 // render checked email on index 4 to preview checked email
                  ?
                  <li className="mail-item checked">
                    <a onClick={() => window.Lawha.toggleSiblings('#email-preview' ,'#mails-list')} href="#" className="mail-item-head">
                      <input type="checkbox" name="contact" className="checkmail" checked={true} />
                      <label>
                        <svg className="svg-sm star-icon starred" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <path d="M12 17.75l-6.172 3.245 1.179-6.873-4.993-4.867 6.9-1.002L12 2l3.086 6.253 6.9 1.002-4.993 4.867 1.179 6.873z" /></svg>
                        <input type="checkbox" className="hidden" />
                      </label>
                      <label>
                        <svg className="svg-sm bookmark-icon bookmarked" viewBox="0 0 24 24" fill="currentColor" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">  <path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z" /></svg>
                        <input type="checkbox" className="hidden" />
                      </label>
                      <h3 className="contact-name">{i18n.t('jhon doe')}</h3>
                      <div className="mail-caption"><span>{i18n.t('message example')}</span>.....</div>
                      <p className="time">{i18n.t('seconds ago')}</p>
                    </a>
                  </li>
                  : 
                  <li className="mail-item">
                    <a onClick={() => window.Lawha.toggleSiblings('#email-preview' ,'#mails-list')} href="#" className="mail-item-head">
                      <input type="checkbox" name="contact" className="checkmail" />
                      <label>
                        <svg className="svg-sm star-icon" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <path d="M12 17.75l-6.172 3.245 1.179-6.873-4.993-4.867 6.9-1.002L12 2l3.086 6.253 6.9 1.002-4.993 4.867 1.179 6.873z" /></svg>
                        <input type="checkbox" className="hidden" />
                      </label>
                      <label>
                        <svg className="svg-sm bookmark-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">  <path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z" /></svg>
                        <input type="checkbox" className="hidden" />
                      </label>
                      <h3 className="contact-name">{i18n.t('jhon doe')}</h3>
                      <div className="mail-caption"><span>{i18n.t('message example')}</span>.....</div>
                      <p className="time">{i18n.t('seconds ago')}</p>
                    </a>
                  </li>
                )
              }

              return mailsItems;
            })()}
          </ul>
        </div>
        {/* email preview //////////////// */}
        <div id="email-preview" className="email-preview hidden">
          {/* action buttons */}
          <div className="action-buttons">
            <button onClick={() => window.Lawha.toggleSiblings('#mails-list','#email-preview')} className="action-button-item primary-text-color">
              <svg width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <path d="M9 11l-4 4l4 4m-4 -4h11a4 4 0 0 0 0 -8h-1" /></svg>
            </button>
            <button className="action-button-item">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z" />
              </svg>
            </button>
            <button className="action-button-item">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
              </svg>
            </button>
            <button className="action-button-item">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z" />
              </svg>
            </button>
          </div>
          {/* email header */}
          <div className="email-header">
            <div className="email-contact-details">
              <div className="contact-avatar">
                <img data-src="https://via.placeholder.com/40" className="img lazy" />
              </div>
              <div className="contact-name">
                <h3 className="font-semibold text-lg">{i18n.t('jhon doe')}</h3>
                <p className="text-light text-gray-400">{i18n.t('email example')}</p>
              </div>
            </div>
          </div>
          <div>
            {/* example mail letter starts here /////// */}
            <h1 className="font-bold text-2xl">{i18n.t('email header title example')}</h1>
            <article className="mt-8 text-gray-500 leading-7 tracking-wider" dangerouslySetInnerHTML={{ __html: i18n.t('composed email example') }}></article>
            {/* example mail letter ends here /////// */}
            {/* attachment buttons /// */}
            <ul className="attachments">
              <li className="outlined-button iconed red">
                <span>{i18n.t('download')}</span>
                <svg className="svg-sm" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z" />
                </svg>
              </li>
              <li className="outlined-button iconed gray">
                <span>{i18n.t('preview')}</span>
                <svg className="svg-sm" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M7 4v16M17 4v16M3 8h4m10 0h4M3 12h18M3 16h4m10 0h4M4 20h16a1 1 0 001-1V5a1 1 0 00-1-1H4a1 1 0 00-1 1v14a1 1 0 001 1z" />
                </svg>
              </li>
              <div className="flex-1" />
              <li className="outlined-button sky">
                <svg className="svg-sm" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M7 4v16M17 4v16M3 8h4m10 0h4M3 12h18M3 16h4m10 0h4M4 20h16a1 1 0 001-1V5a1 1 0 00-1-1H4a1 1 0 00-1 1v14a1 1 0 001 1z" />
                </svg>
              </li>
              <li className="outlined-button purple">
                <svg className="svg-sm" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M17.657 18.657A8 8 0 016.343 7.343S7 9 9 10c0-2 .5-5 2.986-7C14 5 16.09 5.777 17.656 7.343A7.975 7.975 0 0120 13a7.975 7.975 0 01-2.343 5.657z" />
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9.879 16.121A3 3 0 1012.015 11L11 14H9c0 .768.293 1.536.879 2.121z" />
                </svg>
              </li>
            </ul>
          </div>
          <div className="reply-area">
            <textarea className="typing-box" placeholder={i18n.t('type your message here')} rows={3} defaultValue={""} />
            <div className="buttons">
              <button className="icon primary-text-color">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13" />
                </svg>
              </button>
              <button className="simple-button default">{i18n.t('Reply')}</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default InboxApp;