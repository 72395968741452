import React from "react";
import i18n from "i18next";

class DashboardCards extends React.Component {
    render(){
        return (
            <div className="grid-of-2 lg:col-span-3">
              <div className="dashboard-card group">     
                <div className="card-head">
                  <div className="card-icon">
                    <svg className="svg-sm" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" />
                    </svg>        
                  </div>
                  <div className="card-sub-details">
                    <svg xmlns="http://www.w3.org/2000/svg" className="svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                      <path strokeLinecap="round" strokeLinejoin="round" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    12%
                  </div>
                </div>
                <h1 className="card-shiffre">42.34%</h1>
                <div className="card-footer">
                  <p>{ i18n.t('key performance indicator') }</p>
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" className="svg" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
                    </svg>
                  </span>
                </div>
              </div>
              <div className="dashboard-card group">     
                <div className="card-head">
                  <div className="card-icon">
                    <svg className="svg-sm" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <circle cx={12} cy={5} r={2} />  <circle cx={5} cy={19} r={2} />  <circle cx={19} cy={19} r={2} />  <circle cx={12} cy={14} r={3} />  <line x1={12} y1={7} x2={12} y2={11} />  <path d="M6.7 17.8l2.8 -2" />  <path d="M17.3 17.8l-2.8 -2" /></svg>
                  </div>
                  <div className="card-sub-details">
                    <svg xmlns="http://www.w3.org/2000/svg" className="svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                      <path strokeLinecap="round" strokeLinejoin="round" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    12%
                  </div>
                </div>
                <h1 className="card-shiffre">12.01$</h1>
                <div className="card-footer">
                  <p>{ i18n.t('key performance indicator') }</p>
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" className="svg" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
                    </svg>
                  </span>
                </div>
              </div>
              <div className="dashboard-card group">     
                <div className="card-head">
                  <div className="card-icon">
                    <svg className="svg" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <circle cx={12} cy={12} r={2} />  <path d="M2 12l1.5 2a11 11 0 0 0 17 0l1.5 -2" />  <path d="M2 12l1.5 -2a11 11 0 0 1 17 0l1.5 2" /></svg>
                  </div>
                  <div className="card-sub-details">
                    <svg xmlns="http://www.w3.org/2000/svg" className="svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                      <path strokeLinecap="round" strokeLinejoin="round" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    12%
                  </div>
                </div>
                <h1 className="card-shiffre">19.8K</h1>
                <div className="card-footer">
                  <p>{ i18n.t('key performance indicator') }</p>
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" className="svg" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
                    </svg>
                  </span>
                </div>
              </div>
              <div className="dashboard-card group">     
                <div className="card-head">
                  <div className="card-icon">
                    <svg className="svg-sm" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">  <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" />  <circle cx={9} cy={7} r={4} />  <path d="M23 21v-2a4 4 0 0 0-3-3.87" />  <path d="M16 3.13a4 4 0 0 1 0 7.75" /></svg>
                  </div>
                  <div className="card-sub-details">
                    <svg xmlns="http://www.w3.org/2000/svg" className="svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                      <path strokeLinecap="round" strokeLinejoin="round" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    12%
                  </div>
                </div>
                <h1 className="card-shiffre">201</h1>
                <div className="card-footer">
                  <p>{ i18n.t('key performance indicator') }</p>
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" className="svg" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
                    </svg>
                  </span>
                </div>
              </div>
            </div>
        )
    }
}

export default DashboardCards;