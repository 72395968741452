import React from "react";
import i18n from 'i18next';

class GalleryGrid extends React.Component {
    render(){
        return (
            <div className="gallery-grid">
              {/*first column*/}
              <div className="gallery-column">
                <a href="#">
                  <img className="lazy" data-src="https://via.placeholder.com/250x150" alt="product" />
                  <div className="img-title">{i18n.t('product name example')}</div>
                </a>
                <a href="#">
                  <img className="lazy" data-src="https://via.placeholder.com/150x350" alt="product" />
                  <div className="img-title">{i18n.t('product name example')}</div>
                </a>
                <a href="#">
                  <img className="lazy" data-src="https://via.placeholder.com/250" alt="product" />
                  <div className="img-title">{i18n.t('product name example')}</div>
                </a>
                <a href="#">
                  <img className="lazy" data-src="https://via.placeholder.com/100x400" alt="product" />
                  <div className="img-title">{i18n.t('product name example')}</div>
                </a>
                <a href="#">
                  <img className="lazy" data-src="https://via.placeholder.com/200" alt="product" />
                  <div className="img-title">{i18n.t('product name example')}</div>
                </a>
                <a href="#">
                  <img className="lazy" data-src="https://via.placeholder.com/150" alt="product" />
                  <div className="img-title">{i18n.t('product name example')}</div>
                </a>
                <a href="#">
                  <img className="lazy" data-src="https://via.placeholder.com/150x100" alt="product" />
                  <div className="img-title">{i18n.t('product name example')}</div>
                </a>
              </div>
              {/*second column*/}
              <div className="gallery-column">
                <a href="#">
                  <img className="lazy" data-src="https://via.placeholder.com/200" alt="product" />
                  <div className="img-title">{i18n.t('product name example')}</div>
                </a>
                <a href="#">
                  <img className="lazy" data-src="https://via.placeholder.com/150" alt="product" />
                  <div className="img-title">{i18n.t('product name example')}</div>
                </a>
                <a href="#">
                  <img className="lazy" data-src="https://via.placeholder.com/150x100" alt="product" />
                  <div className="img-title">{i18n.t('product name example')}</div>
                </a>
                <a href="#">
                  <img className="lazy" data-src="https://via.placeholder.com/250x150" alt="product" />
                  <div className="img-title">{i18n.t('product name example')}</div>
                </a>
                <a href="#">
                  <img className="lazy" data-src="https://via.placeholder.com/100x400" alt="product" />
                  <div className="img-title">{i18n.t('product name example')}</div>
                </a>
                <a href="#">
                  <img className="lazy" data-src="https://via.placeholder.com/250" alt="product" />
                  <div className="img-title">{i18n.t('product name example')}</div>
                </a>
                <a href="#">
                  <img className="lazy" data-src="https://via.placeholder.com/150x350" alt="product" />
                  <div className="img-title">{i18n.t('product name example')}</div>
                </a>
              </div>
              {/*third column*/}
              <div className="gallery-column">
                <a href="#">
                  <img className="lazy" data-src="https://via.placeholder.com/250x150" alt="product" />
                  <div className="img-title">{i18n.t('product name example')}</div>
                </a>
                <a href="#">
                  <img className="lazy" data-src="https://via.placeholder.com/150x350" alt="product" />
                  <div className="img-title">{i18n.t('product name example')}</div>
                </a>
                <a href="#">
                  <img className="lazy" data-src="https://via.placeholder.com/250" alt="product" />
                  <div className="img-title">{i18n.t('product name example')}</div>
                </a>
                <a href="#">
                  <img className="lazy" data-src="https://via.placeholder.com/100x400" alt="product" />
                  <div className="img-title">{i18n.t('product name example')}</div>
                </a>
                <a href="#">
                  <img className="lazy" data-src="https://via.placeholder.com/200" alt="product" />
                  <div className="img-title">{i18n.t('product name example')}</div>
                </a>
                <a href="#">
                  <img className="lazy" data-src="https://via.placeholder.com/150" alt="product" />
                  <div className="img-title">{i18n.t('product name example')}</div>
                </a>
                <a href="#">
                  <img className="lazy" data-src="https://via.placeholder.com/150x100" alt="product" />
                  <div className="img-title">{i18n.t('product name example')}</div>
                </a>
              </div>
            </div>
        )
    }
}

export default GalleryGrid;