import React from "react";
import { Helmet } from "react-helmet";
import renderCharts from "../../assets/js/Apexcharts.init";
import AreaChart from "../components/charts/AreaChart";
import BarChart from "../components/charts/BarChart";
import BasicBarChart from "../components/charts/BasicBarChart";
import CandleStickChart from "../components/charts/CandlestickChart";
import LineAdwordsChart from "../components/charts/LineAdwordsChart";
import MixedOneChart from "../components/charts/MixedOneChart";
import MixedTwoChart from "../components/charts/MixedTwoChart";
import NegativeAreaChart from "../components/charts/NegativeAreaChart";
import RadialBarChart from "../components/charts/RadialBarChart";
import SparkFour from "../components/charts/SparkFour";
import SparkOne from "../components/charts/SparkOne";
import SparkThree from "../components/charts/SparkThree";
import SparkTwo from "../components/charts/SparkTwo";
import StackedBarChart from "../components/charts/StackedBarChart";
import TreeMapChart from "../components/charts/TreemapChart";
import MainLayout from "../layouts/Main";
import i18n from 'i18next';

class ChartsPage extends React.Component {
    componentDidMount(){ renderCharts() }
    render(){
        return (
            <MainLayout>
              <Helmet>
                <title>{i18n.t('charts')}</title>
              </Helmet>
              <div className="grid-of-4">
              {/* bar chart ///////// */}
              <BarChart />

              {/* radial bar chart /////////////// */}
              <RadialBarChart />

              {/* mixed 2 chart //////////// */}
              <div className="lg:col-span-2">
                <MixedTwoChart />
              </div>
              {/* spark 1 ///// */}
              <SparkOne />

              {/* spark 2 ///// */}
              <SparkTwo />
              
              {/* spark 3 ///// */}
              <SparkThree />

              {/* spark 4 ///// */}
              <SparkFour />
              
              {/* treemap //////////// */}
              <div className="lg:col-span-2">
                <TreeMapChart />
              </div>

              {/* area chart /////////////////// */}
              <AreaChart />

              {/* line adwords chart //////////// */}
              <LineAdwordsChart />

              {/* negative area char //////////// */}
              <NegativeAreaChart />

              {/* basic bar chart //////////// */}
              <BasicBarChart />
              
              {/* stacked bar chart //////////// */}
              <div className="lg:col-span-2">
                <StackedBarChart />
              </div>
              {/* mixed chart //////////// */}
              <div className="lg:col-span-2">
                <MixedOneChart />
              </div>
              {/* candlestick //////////// */}
              <div className="lg:col-span-2">
                <CandleStickChart />
              </div>
            </div>
            </MainLayout>
        )
    }
}

export default ChartsPage;