import React from "react";
import Navbar from "../partials/Navbar";
import SideBar from "../partials/SideBar";
import ThemeController from "../partials/ThemeController";
import HistoryBar from "../partials/HistoryBar";

class MainLayout extends React.Component{
    render(){
        return (
            <>
                <div className="fixed-layout">
                  <Navbar />
                  <SideBar />
                  <ThemeController />
                </div>

                <div className="page-content">
                    <HistoryBar />
                    {this.props.children}
                </div>
            </>
        )
    }
}

export default MainLayout;