import React from "react";
import i18n from 'i18next';

class MediumBlurModal extends React.Component {
    render(){
        return (
            <div id="blur-md-modal" className="hidden modal blurred-md">
              <div className="modal-box">
                <div className="modal-head">
                  <h2>{i18n.t('popup window title')}</h2>
                </div>
                <hr />
                <div className="modal-body">
                  <p>{i18n.t('popup window content')}</p>
                </div>
                <hr />
                <div className="modal-footer">
                  <button name="button" onClick={() => window.Lawha.toggleItem('#blur-md-modal')} className="outlined-button gray">{i18n.t('close')}</button>
                  <button name="button" className="simple-button default">OK</button>
                </div>
              </div>
            </div>
        )
    }
}

export default MediumBlurModal;