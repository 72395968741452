import React from "react";
import i18n from 'i18next';

class IconedInputs extends React.Component {
    render(){
        return (
            <div>
              <div className="form-element iconed mt-4">
                <label>{i18n.t('username')}</label>
                <div>
                  <i>
                    <svg className="svg-sm" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">  <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />  <circle cx={12} cy={7} r={4} /></svg>
                  </i>
                  <input type="text" data-attr-t placeholder={i18n.t('username')} />
                </div>
              </div>
              <div className="form-element iconed mt-4">
                <label>{i18n.t('password')}</label>
                <div>
                  <i>
                    <svg className="svg-sm text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z" />
                    </svg>
                  </i>
                  <input type="text" data-attr-t placeholder={i18n.t('password')} />
                </div>
              </div>
              <div className="form-element iconed mt-4">
                <label>{i18n.t('your paypal account')}</label>
                <div>
                  <i>
                    <svg className="svg-sm text-blue-500" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <path d="M10 13l2.538-.003c2.46 0 4.962-2.497 4.962-4.997 0-3-1.89-5-4.962-5H7c-.5 0-1 .5-1 1L4 18c0 .5.5 1 1 1h2.846L9 14c.089-.564.43-1 1-1zm7.467-5.837C19.204 8.153 20 10 20 12c0 2.467-2.54 4.505-5 4.505h.021-2.629l-.576 3.65a.998.998 0 01-.988.844l-2.712-.002a.5.5 0 01-.494-.578L7.846 19" /></svg>
                  </i>
                  <input type="text" data-attr-t placeholder={i18n.t('your paypal account')} />
                </div>
              </div>
            </div>

        )
    }
}

export default IconedInputs;