import React from "react";
import i18n from 'i18next';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

class Error500Page extends React.Component {
    render(){
        return (
            <>
              <Helmet>
                <title>{i18n.t('500')}</title>
              </Helmet>
              <section className="error-section">
                <div className="error-container max-w-md text-center">
                  <h2 className="error-code"> 
                    500
                    <svg className="error-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">  <rect x={2} y={2} width={20} height={8} rx={2} ry={2} />  <rect x={2} y={14} width={20} height={8} rx={2} ry={2} />  <line x1={6} y1={6} x2="6.01" y2={6} />  <line x1={6} y1={18} x2="6.01" y2={18} /></svg>
                  </h2>
                  <p className="error-title">{i18n.t('internal server error')}</p>
                  <p className="error-description">{i18n.t('internal server error description')}</p>
                  <Link to={`/`} className="simple-button default">{i18n.t('back')}</Link>
                </div>
              </section>   
            </>
        )
    }
}

export default Error500Page;