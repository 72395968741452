import React from "react";
import i18n from 'i18next';

class InlinedInputs extends React.Component {
  render(){
    return (
      <div>
        <div className="form-element inlined">
          <label>{i18n.t('username')}</label>
          <input type="text" data-attr-t placeholder={i18n.t('username')} />
        </div>
        <div className="form-element inlined mt-4">
          <label>{i18n.t('password')}</label>
          <input type="text" data-attr-t placeholder={i18n.t('password')} />
        </div>
        <div className="form-element inlined mt-4">
          <label>{i18n.t('your paypal account')}</label>
          <input type="text" data-attr-t placeholder={i18n.t('your paypal account')} />
        </div>
      </div>
    )
  }
}

export default InlinedInputs;