import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider} from "react-router-dom";
import i18n from "i18next";
import App from './App';

/**
 * importing locales object
*/
import resources from './locales'

/**
 * importing router
*/
import router from './router';

/**
 * importing the main lawha's css file
*/
import './assets/css/law7a.v1.7.css';
import './assets/css/custom.css';

/**
 * @see https://create-react-app.dev/docs/measuring-performance/
*/
// import reportWebVitals from './reportWebVitals';

/**
 * @see https://reactjs.org/docs/react-dom-client.html
 */
const root = ReactDOM.createRoot(document.getElementById('root'));

/**
 * init i18next 
 * @see https://react.i18next.com/guides/the-drawbacks-of-other-i18n-solutions
*/
i18n.init({
  resources,
  lng: localStorage.getItem('activeLocale') || "ar"
})
document.documentElement.lang = i18n.language
document.documentElement.dir = i18n.language == 'ar' ? 'rtl' : 'ltr'

/**
 * @see https://reactjs.org/docs/rendering-elements.html
*/
root.render(
  <React.StrictMode>
    <App>
      <RouterProvider router={router}></RouterProvider>
    </App>
  </React.StrictMode>
);

/**
 * If you want to start measuring performance in your app, pass a function
 * to log results (for example: reportWebVitals(console.log))
 * or send to an analytics endpoint. 
 * @see https://bit.ly/CRA-vitals
*/
// reportWebVitals();