import React from "react";
import i18n from 'i18next';

class TextStyles extends React.Component {
    render(){
        return (
            <div className="lvl-1-container span-2">
              <div className="my-4 flex"><h6 className="font-lg">{i18n.t('text styles')}</h6></div>
              <hr />
              <p className="text-xs">{i18n.t("that's an example text")}</p>
              <p className="text-sm">{i18n.t("that's an example text")}</p>
              <p className="text-lg">{i18n.t("that's an example text")}</p>
              <p className="text-xl">{i18n.t("that's an example text")}</p>
              <p className="text-2xl">{i18n.t("that's an example text")}</p>
              <p className="text-4xl">{i18n.t("that's an example text")}</p>
              <p className="font-bold">{i18n.t("that's an example text")}</p>
              <p className="font-semibold">{i18n.t("that's an example text")}</p>
              <p className="font-extrabold">{i18n.t("that's an example text")}</p>
              <p className="overline">{i18n.t("that's an example text")}</p>
              <p className="line-through">{i18n.t("that's an example text")}</p>
              <p className="underline">{i18n.t("that's an example text")}</p>
            </div>
        )
    }
}

export default TextStyles;