import React from "react";
import { Helmet } from "react-helmet";
import MainLayout from "../layouts/Main";
import i18n from 'i18next';

class FlexboxPage extends React.Component {
    render(){
        return (
            <MainLayout>
              <Helmet>
                <title>{i18n.t('flexbox')}</title>
              </Helmet>
              <div className="lvl-1-container">
              {/* flexed container */}
              <div className="flex my-2">
                <div className="flexbox-cell">1</div>
                <div className="flexbox-cell">2</div>
                <div className="flexbox-cell">3</div>
                <div className="flexbox-cell">4</div>
              </div>
              {/* flexed container / grow */}
              <div className="flex my-2">
                <div className="flexbox-cell">1</div>
                <div className="flexbox-cell">2</div>
                <div className="flexbox-cell">3</div>
                <div className="flexbox-cell flex-1">4</div>
              </div>
              {/* flexed container / grow */}
              <div className="flex my-2">
                <div className="flexbox-cell flex-1">1</div>
                <div className="flexbox-cell flex-1">2</div>
                <div className="flexbox-cell flex-1">3</div>
                <div className="flexbox-cell flex-1">4</div>
              </div>
              {/* flexed container / margin */}
              <div className="flex my-2">
                <div className="flexbox-cell">1</div>
                <div className="flexbox-cell">2</div>
                <div className="flexbox-cell">3</div>
                <div className="flexbox-cell mr-auto">4</div>
              </div>
              {/* flexed container / margin */}
              <div className="flex my-2">
                <div className="flexbox-cell">1</div>
                <div className="flexbox-cell">2</div>
                <div className="flexbox-cell">3</div>
                <div className="flexbox-cell ml-auto">4</div>
              </div>
              {/* flexed wrap */}
              <div className="flex flex-wrap my-2">
                <div className="flexbox-cell">1</div>
                <div className="flexbox-cell">2</div>
                <div className="flexbox-cell w-1/2">3</div>
                <div className="flexbox-cell w-1/2">4</div>
              </div>
              {/* flex-col */}
              <div className="flex flex-col my-2">
                <div className="flexbox-cell">1</div>
                <div className="flexbox-cell">2</div>
                <div className="flexbox-cell">3</div>
                <div className="flexbox-cell">4</div>
              </div>
              {/* flexed gap-1 */}
              <div>
                <div className="flex gap-1">
                  <div className="flexbox-cell">1</div>
                  <div className="flexbox-cell">2</div>
                  <div className="flexbox-cell">3</div>
                  <div className="flexbox-cell">4</div>
                </div>
              </div>
              {/* flexed gap-2 */}
              <div>
                <div className="flex gap-2">
                  <div className="flexbox-cell">1</div>
                  <div className="flexbox-cell">2</div>
                  <div className="flexbox-cell">3</div>
                  <div className="flexbox-cell">4</div>
                </div>
              </div>
              {/* flexed gap-3 */}
              <div>
                <div className="flex gap-3">
                  <div className="flexbox-cell">1</div>
                  <div className="flexbox-cell">2</div>
                  <div className="flexbox-cell">3</div>
                  <div className="flexbox-cell">4</div>
                </div>
              </div>
              {/* flexed gap-4 */}
              <div>
                <div className="flex gap-4">
                  <div className="flexbox-cell">1</div>
                  <div className="flexbox-cell">2</div>
                  <div className="flexbox-cell">3</div>
                  <div className="flexbox-cell">4</div>
                </div>
              </div>
              {/* flexed gap-5 */}
              <div>
                <div className="flex gap-5">
                  <div className="flexbox-cell">1</div>
                  <div className="flexbox-cell">2</div>
                  <div className="flexbox-cell">3</div>
                  <div className="flexbox-cell">4</div>
                </div>
              </div>
              {/* flexed gap-6 */}
              <div>
                <div className="flex gap-6">
                  <div className="flexbox-cell">1</div>
                  <div className="flexbox-cell">2</div>
                  <div className="flexbox-cell">3</div>
                  <div className="flexbox-cell">4</div>
                </div>
              </div>
              {/* flexed gap-7 */}
              <div>
                <div className="flex gap-7">
                  <div className="flexbox-cell">1</div>
                  <div className="flexbox-cell">2</div>
                  <div className="flexbox-cell">3</div>
                  <div className="flexbox-cell">4</div>
                </div>
              </div>
              {/* flexed gap-8 */}
              <div>
                <div className="flex gap-8">
                  <div className="flexbox-cell">1</div>
                  <div className="flexbox-cell">2</div>
                  <div className="flexbox-cell">3</div>
                  <div className="flexbox-cell">4</div>
                </div>
              </div>
              {/* flexed gap-9 */}
              <div>
                <div className="flex gap-9">
                  <div className="flexbox-cell">1</div>
                  <div className="flexbox-cell">2</div>
                  <div className="flexbox-cell">3</div>
                  <div className="flexbox-cell">4</div>
                </div>
              </div>
              {/* flexed gap-10 */}
              <div>
                <div className="flex gap-10">
                  <div className="flexbox-cell">1</div>
                  <div className="flexbox-cell">2</div>
                  <div className="flexbox-cell">3</div>
                  <div className="flexbox-cell">4</div>
                </div>
              </div>
            </div>
            </MainLayout>
        )
    }
}

export default FlexboxPage;