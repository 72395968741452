import React from "react";
import { Helmet } from "react-helmet";
import LoginCard from "../components/auth/LoginCard";
import AuthLayout from "../layouts/Auth";
import i18n from 'i18next';

class LoginPage extends React.Component {
    render (){
        return (
            <AuthLayout>
                <Helmet>
                    <title>{i18n.t('login')}</title>
                </Helmet>
                <LoginCard />
            </AuthLayout>
        )
    }
}

export default LoginPage;