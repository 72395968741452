import React from "react";
import i18n from "i18next";

class BaseTab extends React.Component {
    /**
     * add events listeners for tab buttons
     * @return void
     */
    activateTabs(){
        document.querySelectorAll('.nav-tabs li').forEach(i => {
            i.addEventListener('click' ,function(){
                document.querySelectorAll('.tab-window').forEach(w => w.classList.add('hidden'))
                document.querySelectorAll('.nav-tabs li').forEach(li => li.classList.remove('active'))
                document.querySelector(this.dataset.target).classList.remove('hidden')
                this.classList.add('active')
            })
        })
    }
    componentDidMount(){
        this.activateTabs()
    }
    render(){
        return (
            <div className="my-4">
              <div className="lvl-1-container mt-4 mb-1 p-0">
                <ul className="nav-tabs">
                  <li data-target="#tab-1" className="active"><span>{i18n.t('tab')}</span>1</li>
                  <li data-target="#tab-2"><span>{i18n.t('tab')}</span>2</li>
                  <li data-target="#tab-3"><span>{i18n.t('tab')}</span>3</li>
                </ul>
              </div>
              <div className="tabs-container">
                <div id="tab-1" className="tab-window lvl-1-container">
                  <div className="bordered-alert default no-animate" role="alert">
                    <p className="font-bold"><span>{i18n.t('tab')}</span>1</p>
                  </div>
                  <p className="my-4">{i18n.t('tab window content')}</p>
                </div>
                <div id="tab-2" className="tab-window lvl-1-container hidden">
                  <div className="bordered-alert default no-animate" role="alert">
                    <p className="font-bold"><span>{i18n.t('tab')}</span>2</p>
                  </div>
                  <p className="my-4">{i18n.t('tab window content')}</p>
                </div>
                <div id="tab-3" className="tab-window lvl-1-container hidden">
                  <div className="bordered-alert default no-animate" role="alert">
                    <p className="font-bold"><span>{i18n.t('tab')}</span>3</p>
                  </div>
                  <p className="my-4">{i18n.t('tab window content')}</p>
                </div>
              </div>
            </div>
        )
    }
}

export default BaseTab;