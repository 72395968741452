import React from "react";
import i18n from 'i18next';

class TextColors extends React.Component {
    render(){
        return (
            <div className="lvl-1-container">
              <div className="my-4 flex"><h6 className="font-lg">{i18n.t('text colors')}</h6></div>
              <hr />
              <div className="grid-of-2 gap-2">
                <div className="p-4 base-lime-text">{i18n.t("that's an example text")}</div>
                <div className="p-4 base-amber-text">{i18n.t("that's an example text")}</div>
                <div className="p-4 base-blue-text">{i18n.t("that's an example text")}</div>
                <div className="p-4 base-cyan-text">{i18n.t("that's an example text")}</div>
                <div className="p-4 base-emerald-text">{i18n.t("that's an example text")}</div>
                <div className="p-4 base-gray-text">{i18n.t("that's an example text")}</div>
                <div className="p-4 base-green-text">{i18n.t("that's an example text")}</div>
                <div className="p-4 base-orange-text">{i18n.t("that's an example text")}</div>
                <div className="p-4 base-pink-text">{i18n.t("that's an example text")}</div>
                <div className="p-4 base-purple-text">{i18n.t("that's an example text")}</div>
                <div className="p-4 base-red-text">{i18n.t("that's an example text")}</div>
                <div className="p-4 base-sky-text">{i18n.t("that's an example text")}</div>
                <div className="p-4 base-teal-text">{i18n.t("that's an example text")}</div>
                <div className="p-4 base-yellow-text">{i18n.t("that's an example text")}</div>
                <div className="p-4 primary-text-color">{i18n.t("that's an example text")}</div>
              </div>
            </div>
        )
    }
}

export default TextColors;