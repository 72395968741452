import React from "react";
import i18n from 'i18next';

class TableRowLikeNotification extends React.Component {
    render(){
        return (
            <tr>
                <td>
                   <div className="flex gap-2 items-center">
                       <div className="timeline-container">
                         <div className="timeline timeline-top" />
                         <span className="text-gray-50">{i18n.t('seconds ago')}</span>
                         <div className="timeline timeline-bottom" />
                       </div> 
                       <img className="inline w-12 rounded-full lazy" data-src="https://via.placeholder.com/40" alt="man-1" />
                       <p>{i18n.t('notification example')}</p>
                   </div>
                </td>
            </tr>
        )
    }
}

export default TableRowLikeNotification;