import React from "react";
import { Helmet } from "react-helmet";
import TasksList from "../components/applications/TasksList";
import MainLayout from "../layouts/Main";
import i18n from 'i18next';

class TasksListPage extends React.Component {
    render(){
        return (
            <MainLayout>
                <Helmet>
                    <title>{i18n.t('tasks list')}</title>
                </Helmet>
                <TasksList />
            </MainLayout>
        )
    }
}

export default TasksListPage;