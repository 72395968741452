import React from "react";
import i18n from 'i18next';

class SimpleButtons extends React.Component {
    render(){
        return (
            <div className="flex flex-wrap gap-2">
               <button name="button" className="simple-button red">{i18n.t('click me')}</button>
               <button name="button" className="simple-button green">{i18n.t('click me')}</button>
               <button name="button" className="simple-button gray">{i18n.t('click me')}</button>
               <button name="button" className="simple-button purple">{i18n.t('click me')}</button>
               <button name="button" className="simple-button orange">{i18n.t('click me')}</button>
               <button name="button" className="simple-button pink">{i18n.t('click me')}</button>
               <button name="button" className="simple-button disabled" disabled>{i18n.t('disabled')}</button>
            </div>
        )
    }
}

export default SimpleButtons;