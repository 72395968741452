import React from "react";
import i18n from 'i18next';

class OutlinedButtons extends React.Component {
    render(){
        return (
            <div className="flex flex-wrap gap-2">
              <button name="button" className="outlined-button red">{i18n.t('click me')}</button>
              <button name="button" className="outlined-button green">{i18n.t('click me')}</button>
              <button name="button" className="outlined-button gray">{i18n.t('click me')}</button>
              <button name="button" className="outlined-button purple">{i18n.t('click me')}</button>
              <button name="button" className="outlined-button orange">{i18n.t('click me')}</button>
              <button name="button" className="outlined-button pink">{i18n.t('click me')}</button>
              <button name="button" className="outlined-button disabled" disabled>{i18n.t('disabled')}</button>
            </div>
        )
    }
}

export default OutlinedButtons;