import React from "react";
import i18n from 'i18next';

class ButtonSizes extends React.Component {
    render(){
        return (
            <div className="flex flex-wrap gap-2">
              <button name="button" className="button-3d sm red">{i18n.t('small')}</button>
              <button name="button" className="button-3d md green">{i18n.t('medium')}</button>
              <button name="button" className="button-3d lg gray">{i18n.t('large')}</button>
              <button name="button" className="button-3d sm orange">{i18n.t('small')}</button>
              <button name="button" className="button-3d md disabled" disabled>{i18n.t('disabled')}</button>
            </div>
        )
    }
}

export default ButtonSizes;