import React from "react";
import { Helmet } from "react-helmet";
import BaseTooltip from "../components/tooltips/BaseTooltip";
import MainLayout from "../layouts/Main";
import i18n from 'i18next';

class TooltipsPage extends React.Component {
    render(){
        return (
            <MainLayout>
                <Helmet>
                    <title>{i18n.t('tooltips')}</title>
                </Helmet>
                <BaseTooltip />
            </MainLayout>
        )
    }
}

export default TooltipsPage;