import React from "react";
import { Helmet } from "react-helmet";
import CalendarApp from "../components/applications/CalendarApp";
import MainLayout from "../layouts/Main";
import i18n from 'i18next';

class CalendarPage extends React.Component {
    render(){
        return (
            <MainLayout>
                <Helmet>
                    <title>{i18n.t('calendar')}</title>
                </Helmet>
                <CalendarApp />
            </MainLayout>
        )
    }
}

export default CalendarPage;